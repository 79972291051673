// use styles from portfolio-template
// + template-z
.portfolio-template.theme2 {
  position: relative;
  overflow: none;

  .portfolio-menu-slant-container {
    position: absolute;
    left: -362px;
    min-width: 360px;
    height: 100%;
    //slant
    // -webkit-clip-path: polygon(0 0, 100% 0, 10% 100%, 0 100%);
    // clip-path: polygon(0 0, 100% 0, 10% 100%, 0 100%);
    transition: all 0.4s ease;
    z-index: 100;
    opacity: 0.6;

    .menu-3-logo {
      display: flex;
        justify-content: center;
        align-items: center;
      width: 100%;
      padding: 20px;

      max-height: 120px;
      .img {
        object-fit: contain;
      }
    }

    .portfolio-menu-container {
      position: relative;
      min-width: 360px;
      max-width: 360px;

      .logo {
        position: unset;
        //display: none;
      }

      .portfolio-menu {
        display: block;
        margin-top: 10px;
        overflow-x: clip;
        text-overflow: ellipsis;

        button {
          // width: 250px;
          // height: 50px;
          margin: 0px 0 24px 10px;
          background-color: transparent;
          font-family: 'Oswald', sans-serif;
          text-overflow: ellipsis;
          // align text in p to left
          p {
            text-align: left;
            transition: all 0.5s ease;
          }

          &.hidden {
            display: none !important; // need !important prevent override from flex
          }

          &.active {
            display: inherit;
          }

          p:hover {
            letter-spacing: 0.1em;
          }
        }


        // as list grows, make the size smaller
        // $elements: 10;
        // @for $i from 0 to $elements {
        //   ul:nth-child(#{$i + 1}) {
        //     button {
        //       height: calc(45px - #{$i}* 3px);
        //       white-space: nowrap;
        //       // overflow: hidden;
        //       text-overflow: ellipsis;
        //       text-shadow: 2px 2px 2px black;
        //       p {
        //         font-size: calc(36px - #{$i} * 5px);
        //       }
        //     }
        //   }
        // }
        ul {
          button {
            // height: 45px;
            // white-space: nowrap;
            text-overflow: ellipsis;
            text-shadow: 2px 2px 2px black;
            p {
              font-size: 28px;
            }
          }
        }

        .group {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          // hide all ul under, aka level 1 and up will be hidden
          ul:nth-child(n + 2) {
            display: none !important;
          }
        }
      }
    }

    .right-arrow-container {
      position: absolute;
      right: -70px;
    }

    // show on hover
    &:hover {
      left: 0px;
    }
  }

  .portfolio-menu-slant-container:hover {
    opacity: 1;
  }

  .portfolio-content-parent {
    margin: 0;
    .portfolio-big-nav-container,
    .portfolio-big-nav-prev-container {
      // grid
      display: grid;
      gap: 15px;
      grid-template-rows: minmax(0, 100%) auto;
      grid-template-columns: minmax(0, 100%) auto;

      // overwrite grid-templates based on content amount
      &.content-count-1 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      &.content-count-2 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
      }

      &.content-count-3 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      &.content-count-4 {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }

      &.content-count-5 {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
      }

      &.content-count-6 {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
      }

      &.content-count-7 {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      &.content-count-8 {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      &.content-count-9 {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
      }

      overflow: hidden;
      width: 100%;
      height: 100%;

      .big-nav {
        overflow: hidden;
        position: relative;
      }

      .big-nav-extra {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;

        .logo-extra {

          img {
            // padding: 5px;
            // background-color: #FFFFFF80;
            // border-radius: 2px;
          }
        }

        .logo-extra-thumb {

        }
      }

      .big-nav-thumb {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;

        .logo-thumb {
          
        }
      }

      .portfolio-content {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 100%;
        cursor: pointer;
        position: relative;
        object-fit: contain;

        .portfolio-single-image {
          flex-shrink: 0;
          width: 100%;
          height: 100%;
        }

        .iframe-container {
          position: relative;
          width: 100%;
          height: 100%;

          .website-link {
            height: 20px;
          }

          iframe {
            display: block;
            background: white;
            border: none;
            width: 100%;
            height: 100%;
            scrollbar-width: none;
            -webkit-scrollbar {
              width: 0px;
            }
            
          }
          embed{
            display: block;
            background: white;
            border: none;
            width: 100%;
            height: 100%;
            scrollbar-width: none;
            -webkit-scrollbar {
              width: 0px;
            }
          }
        }

        .website-content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-family: 'Oswald', sans-serif;
            font-size: 4em;
            letter-spacing: 0.1em;
            font-weight: 600;
            color: white;
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
          }
        }

        // hide video control in nav
        video::-webkit-media-controls-enclosure {
          display: none !important;
        }
      }
      button {
        margin: 0;
      }

      ::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    }

    .portfolio-big-nav-prev-container {
      position: absolute;
    }

    .portfolio-big-nav-prev-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1;
    }

    .portfolio-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .portfolio-content-wrapper {
        width: 90%;
        height: 90%;
      }
    }

    .portfolio-content-menu-container {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .portfolio-content-menu {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 800px;
        height: 800px;
        overflow-x: visible;
        padding: 50px 0px 50px 24px;
        background: transparent;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }

        // remove button background
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: transparent;
          transition: all 0.15s ease;
          overflow: hidden;

          .terminus-button-div {
            overflow: hidden;

            .term-first {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: horizontal;
              -webkit-line-clamp: 2;
            }

            .term-second {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: horizontal;
              -webkit-line-clamp: 1;
            }
          }

          // increase font size
          p {
            font-size: 14px;
            text-overflow: ellipsis;
          }
        }

        button:hover {
          transform: scale(1.00, 1.15);
        }
      }
    }
  }

  .portfolio-action-menu {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 2;
    height: 94px;

    .action-menu-popup {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px !important;
        width: 90px !important;
        position: unset !important;
        img{
          height: 100% !important;
          width: 100% !important;
        }
      }
    }

    .action-menu-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 700px;

      .button-popup-left {
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in;
        div {
          transition: left 0.3s ease-in 0s;
          //transition: all 0.3s ease-in;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
          background-color: #00000080;
          opacity: inherit;
          //visibility: inherit;
          .containDiv{
            position: unset;
            transform: unset;
            //visibility: inherit;
            //opacity: inherit;
          }
        }
      }

      .button-popup-right {
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in;
        
        div {
          transition: left 0.3s ease-in 0s;
          //transition: transform 0.3s ease-in 0s;
          // transition: opacity 0s step-end 0s;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
          background-color: #00000080;
          opacity: inherit;
          .containDiv{
            position: unset;
            transform: unset;
            //opacity: inherit;
          }
        }
      }
    }

    &:hover {
      .action-menu-container {
        .button-popup-left {
          visibility: visible;
          opacity: 1;
          div:first-child {
            left: 33%;
          }
          div:nth-child(2) {
            left: 22%;
          }
          div:nth-child(3) {
            left: 11%;
          }
          div:nth-child(4) {
            left: 0;
          }
        }

        .button-popup-right {
          visibility: visible;
          opacity: 1;
          div:first-child {
            left: 68%;
          }
          div:nth-child(2) {
            left: 79%;
          }
          div:nth-child(3) {
            left: 90%;
          }
        }
      }
    }
  }

  .portfolio-briefcase-container {
    position: fixed;
    grid-template-rows: 1fr 9fr 50px;
    display: grid;
    right: 0;
    width: 19.8vw;
    top: 5%;
    height: 90%;
    // border: 2px solid black;
    transition: all 0.3s;
    // overflow: hidden;
    transform: translateX(100%);
    background-color: transparent;
    gap: 10px;

    &.active {
      transform: translateX(-8%);
    }

    &.hidden {
      transform: translateX(100%);
    }

    .portfolio-briefcase-label{
      grid-row: 1 / span 1;
      display: grid;
      grid-template-rows: 0.6fr 1.2fr;
      z-index: 1;
      background-color: #000000BF;
      border-radius: 5px;
      padding-top: 5px;

      .close-container {
        grid-row: 1 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .expansion {
          transition: all 0.2s ease;
        }

        .expansion:hover {
          transform: scale(1.15, 1.15);
        }
      }

      .intro-box {
        grid-row: 2 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 0.12em;
        font-weight: 700;
      }
    }

    .portfolio-briefcase {
      margin-bottom: 48px;
      z-index: 2;
      margin-left: 12px;
      margin-right: 12px;
      width: 94%;
      grid-row: 2 / span 1;            
    }

    .briefcase-action-button-container {
      grid-row: 3 / span 1;
      // background-color: #000000BF;

      .briefcase-send-button {
        border-radius: 20px;
        padding: 5%;
        width: 30%;
        font-family: 'Oswald', sans-serif;
        transition: all 0.3s ease;
        border-width: 2px;
        border-style: solid;
        font-size: 12px;
      }

      .briefcase-send-button:hover {
        transform: scaleY(1.25);
      }
    }

      .briefcase-content-list {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        scrollbar-width: none;            
        width: 94%;
        padding: 0;
        background-color: #00000080;
        border-radius: 5px;
        
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 70px;
          margin: 20px 0;
          background-color: rgb(163, 163, 163);
          color: white;
        }
        
      .briefcase-content-list-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        margin-top: 2px;
        margin-bottom: 13px;
        background-color: white;
        border-radius: 5px;
      }
      
    }
    .briefcase-content-list::-webkit-scrollbar{
      width: 0px;
    }

    .briefcase-send-button {
      grid-row: 4/5;
    }
  }

  .portfolio-notepad-container {
    position: fixed;
    display: grid;
    grid-template-rows: 1fr 9fr 50px;
    right: 0;
    width: 19.8vw;
    top: 5%;
    height: 90%;
    border-radius: 10px;
    transition: all 0.3s;
    transform: translateX(100%);
    background-color: transparent;
    // gap: 10px;

    &.active {
      transform: translateX(-8%);
    }

    &.hidden {
      transform: translateX(100%);
    }

    // contains md close icon
    .notepad-close {
      grid-row: 1 / span 1;
      display: grid;
      grid-template-rows: 0.6fr 1.2fr;
      z-index: 1;
      // background-color: #000000BF;
      width: 100%;
      border-radius: 5px;
      padding-top: 30px;

      .close-container {
        grid-row: 1 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .expansion {
          transition: all 0.2s ease;
        }

        .expansion:hover {
          transform: scale(1.15, 1.15);
        }
      }

      .intro-box {
        grid-row: 2 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 0.12em;
        font-weight: 700;
      }
    }

    .textarea-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-row: 2 / span 1;

      h1 {
        width: 90%;
        background-color: white;
        color: black;
        margin: 10px 0 0 0;
      }

      textarea {
        width: 90%;
        height: 100%;
        resize: none;
        padding: 0;
      }
      
    }

    .notepad-button-container {
      display: flex;
      justify-content: space-evenly;
      grid-row: 3 / span 1;
      // position: absolute;
      // width: 100%;

      button {
        border-radius: 25px;
        padding: 5%;
        width: 30%;
        font-family: 'Oswald', sans-serif;
        transition: all 0.3s ease;
        border-width: 2px;
        border-style: solid;
        font-size: 12px;
      }

      button:hover {
        transform: scaleY(1.25);
      }
    }
  }

  .back-container {
    display: none;
  }

  /* Safari 10.1+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      // Seems like safari does not work well with ::before to grow the grid contents
      // Override button in nav for safari to positon button text to center
      .portfolio-big-nav-container,
      .portfolio-big-nav-prev-container {
        button {
          position: relative;
          :first-child {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
