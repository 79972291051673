// use styles from portfolio-template
// + template-z
.portfolio-template.theme8 {
  position: relative;

  .ba-slider-container {
    width: 100%;

    @media (min-width: 1367px) and (orientation: landscape) {
      width: 80% !important;
    }
  }

  .MuiSlider-markLabel {
    left: 50px !important;
    font-size: 11px !important;

    @media (max-width: 1366px) and (orientation: landscape) {
      font-size: 8px !important;
    }

    @media (max-width: 1024px) and (orientation: portrait) {
      font-size: 8px !important;
    }
  }

  .submenu-top-banner {
    position: absolute;
    top: 0;
    left: 85px;
    height: 80px;
    width: calc(100% - 170px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3b3b3b80;
    border-bottom-right-radius: 12px;
    visibility: visible;
    transition: 0.3s ease all;

    @media (min-height: 1025px) and (orientation: portrait) {
      width: calc(100% - 100px);
      border-bottom-right-radius: 0px;
      left: 100px;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      z-index: 2;
      width: calc(100vw - 110px);
      left: 60px;
      height: 50px;
      border-bottom-right-radius: 0px;

      &.guest {
        width: calc(100vw - 60px);
      }
    }

    @media (max-width: 450px) and (orientation: portrait) {
      z-index: 2;
      background-color: transparent !important;
      width: 200px;
      left: calc(50% - 100px);
    }

    &.hidden {
      @media (max-width: 450px) and (orientation: portrait) {
        visibility: hidden;
      }
    }

    .top-banner-text {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      font-family: 'Roboto Slab', serif;

      @media (min-width: 451px) and (orientation: landscape) {
        top: calc(50% - 20px);
        left: 25%;
        width: 60%;
        height: 45px;
        position: absolute;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        top: calc(50% - 20px);
        left: 35%;
        width: 55%;
        height: 45px;
        position: absolute;
        padding-left: 5px;
        padding-right: 5px;
      }

      @media (max-width: 1367px) and (orientation: landscape) {
      }

      @media (max-width: 700px) and (orientation: portrait) {
        left: 24%;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        height: 100%;
        width: 70%;
        overflow: hidden;
      }

      p {
        margin-block-end: 0px;
        margin-block-start: 0px;
        font-size: 20px;
        font-weight: 600;
        color: white;
        width: 100%;
        text-align: center;

        @media (min-width: 451px) and (orientation: landscape) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          text-align: center;
          line-height: 23px;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 23px;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          text-align: center;
        }
      }
    }

    .sub-level-thumb {
      display: flex;
      position: absolute;
      top: 5%;
      left: 9%;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 60px;
      overflow: hidden;
      opacity: 70%;

      @media (max-width: 1300px) and (orientation: landscape) {
        width: 100px;
      }

      @media (max-width: 1024px) and (min-width: 950px) and (orientation: portrait) {
        left: 15%;
      }

      @media (max-width: 949px) and (min-width: 451px) and (orientation: portrait) {
        width: 120px;
        left: 15%;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        display: none;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        display: none;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        top: 77vh;
      }
    }

    .back-arrow {
      display: flex;
      position: absolute;
      top: 25%;
      left: 5%;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media (max-width: 1300px) and (orientation: landscape) {
        left: 5.5%;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        left: 7%;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        left: 8%;
        top: 7%;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        left: 11%;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        top: 12vh;
        left: -40%;
        background-color: #3b3b3b40;
        border-radius: 8px;
      }

      @media (max-width: 375px) and (max-height: 800px) and (orientation: portrait) {
        top: 13vh;
        left: -38%;
      }
    }

    .home-icon {
      display: flex;
      position: absolute;
      top: 25%;
      left: 1%;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        top: 7%;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        top: 12vh;
        left: -15%;
        background-color: #3b3b3b40;
        border-radius: 8px;
      }

      @media (max-width: 375px) and (max-height: 800px) and (orientation: portrait) {
        top: 13vh;
      }
    }

    .logo-confirm {
      visibility: hidden;
      position: absolute;
      bottom: -5%;
      left: calc(50% - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;

      @media (max-width: 450px) and (orientation: portrait) {
        visibility: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: auto;
        top: 70vh;
        display: none;
      }

      @media (max-width: 380px) and (orientation: portrait) {
        top: 70vh;
      }
    }
  }

  .portfolio-topright-toolbar {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    height: 600px;
    width: 80px;
    background-color: #3b3b3b80;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-bottom-left-radius: 12px;

    @media (min-height: 1025px) and (orientation: portrait) {
      border-top-left-radius: 8px;
      height: 100vh;
      width: 100vw;
      top: auto;
      bottom: 0;
      right: 0;
      align-items: center;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      border-bottom-left-radius: 0px;
      height: 100vh;
      width: 100vw;
      top: 0;
      right: 0;
      align-items: center;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      border-bottom-left-radius: 0px;
      height: 100vh;
      width: 100vw;
      top: 0;
      right: 0;
      align-items: center;
      background-color: #3b3b3bbf;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      border-bottom-left-radius: 0px;
      height: 100vh;
      width: 100vw;
      top: 0;
      right: 0;
      align-items: center;
      background-color: #3b3b3bbf;
    }

    &.active {
      transform: translateY(0px);
      z-index: 3;

      @media (min-height: 1025px) and (orientation: portrait) {
        transform: translateY(0px);
        width: 100vw;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        transform: translateY(0px);
        width: 100vw;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        transform: translateY(0px);
        width: 100vw;
      }
    }

    &.hidden {
      transform: translateY(-520px);

      @media (min-height: 1025px) and (orientation: portrait) {
        transform: translateY(calc(100% - 220px));
        width: 100px;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        transform: translateY(calc(-100% + 50px));
        width: 50px;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        transform: translateY(calc(-100% + 80px));
        width: 80px;
        border-bottom-left-radius: 8px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        transform: translateY(calc(-100% + 80px));
        width: 80px;
        border-bottom-left-radius: 0px;
      }

      &.guest {
        transform: translateY(-380px);

        @media (min-height: 1025px) and (orientation: portrait) {
          transform: translateY(calc(100% - 370px));
          width: 100px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          transform: translateY(calc(-100% + 50px));
          width: 50px;
          display: none;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          transform: translateY(calc(-100% + 80px));
          width: 80px;
        }
      }
    }

    .topright-toolbar-icon {
      font-size: 2.5em;
      color: white;
      cursor: pointer;
      position: absolute;

      @media (min-height: 1025px) and (orientation: portrait) {
        font-size: 3.8em;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        font-size: 1.9em;
      }

      @media (max-width: 450px) and (orientation: portrait) {
      }

      &.active {
        top: 3%;

        @media (min-height: 1025px) and (orientation: portrait) {
          bottom: 10%;
          top: auto;
          right: 2%;
          left: auto;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          right: 3%;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          right: 3%;
        }
      }

      &.hidden {
        bottom: 3%;

        @media (min-height: 1025px) and (orientation: portrait) {
          bottom: auto;
          top: 1.5%;
          right: auto;
          left: 20%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          bottom: 2%;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          bottom: 2.5%;
        }

        &.guest {
          display: none;
        }
      }
    }

    .icon-flex-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        margin-bottom: 10px;
      }

      &.guest {
        height: 220px;
        position: absolute;
        justify-content: space-around;

        @media (min-height: 1025px) and (orientation: portrait) {
          top: 10px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        }

        @media (max-width: 450px) and (orientation: portrait) {
          bottom: 20px;
          padding-left: 30px;
          // justify-content: flex-end;
          margin-bottom: 0px;
          flex-direction: row-reverse;
          justify-content: flex-start;
          align-items: flex-end;
        }
      }

      .icon-and-text-parent {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 250px;

        @media (min-height: 1025px) and (orientation: portrait) {
          margin-right: 0px;
          // margin-bottom: 20px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          margin-right: 0px;
          height: min-content;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          margin-right: 0px;
        }

        &.annotator {
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            display: none !important;
          }
        }

        &.guest {
          margin-right: 0px;

          &.note {
            @media (max-width: 450px) and (orientation: portrait) {
              display: none;
            }
          }
        }

        .test-absolute-text {
          width: 250px;
          height: 30px;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (min-height: 1025px) and (orientation: portrait) {
            width: 400px;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 20px;
          }

          p {
            color: white;
            font-size: 20px;
            font-weight: 600;
            text-shadow: 1px 1px 1px #3b3b3b;
            cursor: pointer;
            font-family: 'Roboto Slab', serif;

            @media (min-height: 1025px) and (orientation: portrait) {
              font-size: 36px;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              margin-block: 0px;
            }
          }

          // p:hover {
          //   color: #3b3b3b !important;
          // }
        }
      }

      svg {
        height: 45px !important;
        width: 45px !important;
        cursor: pointer;

        @media (min-height: 1025px) and (orientation: portrait) {
          height: 55px !important;
          width: 55px !important;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 30px !important;
          width: 30px !important;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 35px !important;
          width: 35px !important;
          margin-bottom: 2px;
        }
      }

      .menu-icon-custom {
        .containDiv {
          height: 60px !important;
          width: 60px !important;

          @media (min-height: 1025px) and (orientation: portrait) {
            height: 70px !important;
            width: 70px !important;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 40px !important;
            width: 40px !important;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            height: 40px !important;
            width: 40px !important;
          }
        }
      }
    }
  }

  // Main sidemenu wrapper
  .portfolio-sidemenu-container {
    position: absolute;
    z-index: 3;
    left: 0;
    height: 100%;
    width: 400px;
    background-color: #3b3b3b80;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-height: 1025px) and (orientation: portrait) {
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background-color: #3b3b3bbf;
    }

    &.hidden {
      transform: translateX(-320px);

      @media (min-height: 1025px) and (orientation: portrait) {
        transform: translateX(calc(-100% + 100px));
        width: calc(100% - 100px);
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        transform: translateX(calc(-100% + 60px));
        width: calc(100% - 60px);
      }

      @media (max-width: 700px) and (orientation: portrait) {
        transform: translateX(calc(-100% + 80px));
        width: calc(100% - 80px);
      }

      @media (max-width: 450px) and (orientation: portrait) {
        transform: translateY(calc(-100% + 80px));
        width: calc(100% - 80px);
      }
      // Target the sidebar buttons to be hidden when menu is close
      .portfolio-menu-container .portfolio-menu {
        display: none !important;
      }
    }

    &.active {
      transform: translateX(0px);

      @media (min-height: 1025px) and (orientation: portrait) {
        transform: translateX(0%);
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        transform: translateX(0%);
      }

      @media (max-width: 450px) and (orientation: portrait) {
        transform: translateY(0%);
      }
    }

    .like-box {
      display: none;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: min-content;
        position: absolute;
        bottom: 80px;
        right: 5px;
        height: auto !important;
        visibility: visible !important;
        width: min-content;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 20px;
        right: 0px;
        width: min-content;
        height: auto !important;
        visibility: visible !important;
      }

      &.active {
        display: none;
      }

      .note-box {
        display: none;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display: flex;
        }
      }

      .extra {
        display: none;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display: flex;
        }
      }

      svg {
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 35px !important;
          width: 35px !important;
        }
      }

      .containDiv {
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 50px !important;
          width: 50px !important;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 60px !important;
          width: 60px !important;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }
      }
    }

    .intra-sidebar-menu {
      height: 67%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        display: none;
        background: transparent;
      }

      &.active {
        @media (min-height: 1025px) and (orientation: portrait) {
          align-items: center;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          align-items: center;
        }
      }

      &.hidden {
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display: none;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          display: none;
        }
      }

      .portfolio-menu-container {
        position: relative;
        min-width: 360px;
        max-width: 360px;
        padding: 10px 0 0 10px;

        height: 100%;
        max-height: 100%;
        mask-image: url('../../assets/images/MenuMask.png');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        -webkit-mask-image: url('../../assets/images/MenuMask.png');
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          display: none;
          background: transparent;
        }

        .debug {
          border: 1px solid red;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
        }

        @media (min-height: 1025px) and (orientation: portrait) {
          margin-top: 100px;
        }

        @media (min-width: 1024px) and (orientation: portrait) {
          max-width: none;
          width: 100%;
        }

        .logo {
          position: unset;
          //display: none;
        }

        // Portfolio menu system
        .portfolio-menu {
          display: block;
          overflow-x: clip;
          text-overflow: ellipsis;
          max-height: none;
          padding: 0;
          margin: 5px 0;
          width: 100%;

          @media (min-height: 1025px) and (orientation: portrait) {
            padding-top: 50px;
          }

          button {
            width: 100%;
            margin: 0 0 10px 0;
            padding: 0 0 0 10px;

            // background-color: transparent; // Removed to pull color from portfolio

            font-family: 'Oswald', sans-serif;
            overflow: hidden;
            // align text in p to left

            @media (min-height: 1025px) and (orientation: portrait) {
              margin: 0px 0px 10px 0px;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              height: min-content !important;
              margin: 0px 0px 10px 0px;
            }

            p {
              text-align: left;
              transition: all 0.5s ease;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              text-shadow: none !important;
            }

            &.hidden {
              display: none !important; // need !important prevent override from flex
            }

            &.active {
              display: inherit;
            }
          }

          // as list grows, make the size smaller
          // $elements: 10;
          // @for $i from 0 to $elements {
          //   ul:nth-child(#{$i + 1}) {
          //     button {
          //       height: calc(45px - #{$i}* 3px);
          //       white-space: nowrap;
          //       // overflow: hidden;
          //       text-overflow: ellipsis;
          //       text-shadow: 2px 2px 2px black;
          //       p {
          //         font-size: calc(36px - #{$i} * 5px);
          //       }
          //     }
          //   }
          // }
          ul {
            li {
              position: relative;
              width: 100%;
            }
            // Used for list, but also affects sidebar
            button {
              border-radius: 10px;
              width: 100%;
              text-overflow: ellipsis;
              text-shadow: 2px 2px 2px black;

              @media (min-width: 1024px) and (orientation: portrait) {
                width: 100%;
              }

              p {
                font-size: 28px;
                font-family: 'Oswald', serif;
                line-height: 35px;

                @media (min-height: 1025px) and (orientation: portrait) {
                  font-size: 48px;
                }

                @media (min-width: 1024px) and (orientation: portrait) {
                  line-height: normal;
                }
              }
            }
          }

          .group {
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;

            @media (min-height: 1025px) and (orientation: portrait) {
              align-items: center !important;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              align-items: center !important;
            }

            // hide all ul under, aka level 1 and up will be hidden
            ul:nth-child(n + 2) {
              display: none !important;
            }
          }
        }
      }
    }

    .headline-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      @media (min-height: 1025px) and (orientation: portrait) {
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
      }

      @media (max-width: 450px) and (orientation: portrait) {
      }

      &.active {
        top: 2%;
        right: 5%;

        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 5em !important;
          top: auto;
          bottom: 10%;
          right: 3%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 2.6em !important;
          top: 1.5%;
          right: 1.5%;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          top: 2%;
          left: 5%;
          display: flex;
        }
      }

      &.hidden {
        top: 2%;
        right: 5%;

        @media (max-width: 1367px) and (min-width: 451px) {
          top: 5.5%;
          right: 4%;
        }

        @media (min-width: 1024px) and (max-width: 1367px) and (orientation: landscape) {
          top: 2%;
        }

        @media (max-width: 1367px) and (min-width: 451px) and (orientation: portrait) {
          top: 2%;
        }

        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 5em !important;
          top: auto;
          bottom: 10%;
          right: 1%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 2.6em !important;
          top: 0.5%;
          right: 1.5%;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          // bottom: 2%;
          bottom: 2.5%;
          top: auto;
          left: 5%;
          // left: 5%;
          right: auto;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          // bottom: 2%;
          bottom: 2.5%;
          top: auto;
          left: 12%;
          // left: 5%;
          right: auto;
        }
      }
    }

    .headline-logo-container {
      position: absolute;
      bottom: 2%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        left: 5%;

        @media (max-width: 1367px) and (min-width: 451px) {
          bottom: 5%;
          display: none;
        }

        @media (min-height: 1025px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display: none;
        }
      }

      &.hidden {
        right: 3%;

        @media (max-width: 1367px) and (min-width: 451px) {
          right: 3%;
          bottom: 9%;
        }

        @media (min-height: 1025px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 1367px) and (min-width: 451px) {
          display: none;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display: none;
        }
      }

      @media (max-width: 450px) and (orientation: portrait) {
        &.active {
          right: 5%;
          left: auto;
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  .terminus-icon-menu {
    position: absolute;
    z-index: 1;
    width: 80px;
    height: 80px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent !important;

    @media (min-width: 1025px) and (max-width: 1367px) and (orientation: landscape) {
      top: 0vh;
      right: 0vw;
      width: 80px;
      height: 80px;
      justify-content: center;
    }

    @media (max-width: 1024px) and (min-width: 451px) and (orientation: landscape) {
      right: 0vw;
      top: 0;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
      width: 500px;
      bottom: 6vh;
      left: calc(50% - 225px);
    }

    @media (max-width: 1024px) and (orientation: portrait) {
      justify-content: center;
      height: 80px;
      flex-direction: row;
      left: auto;
      right: 0;
      bottom: auto;
      top: 0;
      width: 60px;
    }

    @media (min-height: 1025px) and (orientation: portrait) {
      justify-content: center;
      height: 80px;
      width: 80px;
      flex-direction: row;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      width: 50px;
      height: 50px;
      right: 0;
      top: 0;
      bottom: auto;
      padding: 0px;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      width: auto;
      height: 40px;
      right: 0;
      left: auto;
      bottom: auto;
      top: 11.5vh;
      padding: 5px;
      position: fixed;
    }

    @media (max-width: 380px) and (orientation: portrait) {
      top: 12.5vh;
    }

    .containDiv {
      @media (min-width: 1025px) and (max-width: 1367px) and (orientation: landscape) {
        height: 70px !important;
        width: 70px !important;
      }

      @media (max-width: 1024px) and (orientation: portrait) {
        height: 60px !important;
        width: 60px !important;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (min-height: 551px) and (orientation: landscape) {
        height: 60px !important;
        width: 60px !important;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 45px !important;
        width: 45px !important;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        height: 55px !important;
        width: 55px !important;
      }
    }
  }

  .landing-page-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    top: 20px;
    left: 150px;
    font-family: 'Roboto Slab', serif;
    text-shadow: 1px 1px 1px #3b3b3b80;

    @media (min-height: 1025px) and (orientation: portrait) {
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      left: 90px;
      top: 10px;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      top: auto;
      bottom: 11vh;
      left: auto;
      width: 80%;
      height: 110px;
      left: 90px;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      top: auto;
      bottom: 11vh;
      left: auto;
      width: 94%;
      height: 110px;
      padding-bottom: 7%;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      overflow: hidden;

      @media (max-width: 450px) and (orientation: portrait) {
      }

      p {
        margin-block-end: 0px;
        margin-block-start: 0px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: white;
        text-align: left;

        @media (max-width: 450px) and (orientation: portrait) {
          -webkit-line-clamp: 1;
        }
      }

      .title {
        font-size: 48px;
        -webkit-line-clamp: 2;
        letter-spacing: 1px;
        font-family: 'Oswald', sans-serif;

        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 60px;
        }

        @media (max-width: 1024px) and (min-width: 900px) and (orientation: portrait) {
          font-size: 48px;
        }

        @media (max-width: 1367px) and (orientation: landscape) {
          font-size: 32px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 26px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          font-size: 20px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 26px;
          -webkit-line-clamp: 1;
        }
      }

      .subtitle {
        font-size: 24px;
        -webkit-line-clamp: 1;

        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 30px;
        }

        @media (max-width: 1024px) and (min-width: 900px) and (orientation: portrait) {
          font-size: 34px;
        }

        @media (max-width: 1367px) and (orientation: landscape) {
          font-size: 22px;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          font-size: 18px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 20px;
          -webkit-line-clamp: 1;
        }
      }

      .description {
        font-size: 18px;
        -webkit-line-clamp: 2;
        font-weight: 400;

        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 23px;
        }

        @media (max-width: 1024px) and (min-width: 900px) and (orientation: portrait) {
          font-size: 22px;
        }

        @media (max-width: 1367px) and (orientation: landscape) {
          font-size: 14px;
          -webkit-line-clamp: 3;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 14px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          font-size: 15px;
          -webkit-line-clamp: 2;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 13px;
          -webkit-line-clamp: 1;
        }
      }
    }

    .title-box {
      width: 50vw;
      padding-left: 3px;

      @media (min-height: 1025px) and (orientation: portrait) {
        width: 70vw;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        width: 65vw;
      }

      @media (max-width: 1367px) and (orientation: landscape) {
        width: 60vw;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        width: 100%;
      }
    }

    .subtitle-box {
      width: 40vw;
      padding-left: 8px;
      margin-bottom: 10px;

      @media (min-height: 1025px) and (orientation: portrait) {
        width: 70vw;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        width: 65vw;
      }

      @media (max-width: 1367px) and (orientation: landscape) {
        width: 50vw;
        padding-left: 3px;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        display: none;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        padding-left: 2px;
        margin-bottom: 0px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        width: 100%;
        padding-left: 5px;
      }
    }

    .description-box {
      width: 40vw;
      padding-left: 8px;

      @media (min-height: 1025px) and (orientation: portrait) {
        width: 70vw;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        width: 65vw;
      }

      @media (max-width: 1367px) and (orientation: landscape) {
        width: 50vw;
        padding-left: 3px;
      }

      // @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      //   display: none;
      // }

      @media (max-width: 700px) and (orientation: portrait) {
        padding-left: 2px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        width: 100%;
        padding-left: 5px;
      }
    }
  }

  .terminus-level-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 130px;
    cursor: pointer;
    overflow: hidden;
    margin: 0% 0% 40px 0%;
  }

  .notification {
    right: auto;
    left: -15%;
  }

  .top-level-overlay {
    background-color: transparent;
    width: 50%;
    height: 25%;
    position: absolute;
    z-index: 1;
    top: auto;
    right: 0;
    bottom: -15%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
      bottom: -25%;
    }

    @media (max-width: 1367px) and (orientation: landscape) {
      bottom: -25%;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      bottom: 0%;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      bottom: auto;
      left: 0%;
      top: -50px;
      width: 100%;
      height: 45px;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      bottom: auto;
      left: 0%;
      top: -50px;
      width: 100%;
      height: 45px;
      justify-content: center;
      align-items: center;
    }

    div,
    section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-shadow: 1px 1px 1px #3b3b3b80;

      @media (max-width: 450px) and (orientation: portrait) {
        height: 100%;
      }

      p {
        color: white;
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-weight: 600;
        text-align: center;
      }

      .overlay-title {
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-family: 'Roboto Slab', serif;

        @media (max-width: 1367px) and (orientation: landscape) {
          font-size: 20px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 12px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          font-size: 14px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 12px;
          -webkit-line-clamp: 1;
        }
      }

      .overlay-subtitle {
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @media (max-width: 1367px) and (orientation: landscape) {
          font-size: 16px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 11px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          font-size: 12px;
          -webkit-line-clamp: 1;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 11px;
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  .modal {
    position: fixed;
    display: grid;
    grid-template-rows: 80px auto;
    left: auto;
    right: 0;
    width: 400px;
    height: 100%;
    top: 0;
    transition: all 0.3s;
    background-color: #3b3b3b59;
    transform: translateX(100%);

    @media (min-height: 1025px) and (orientation: portrait) {
      width: 100vw;
      background-color: #3b3b3bbf;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      width: 100vw;
      background-color: #3b3b3bbf;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      width: 100vw;
      background-color: #3b3b3bbf;
    }

    &.active {
      transform: translateX(0%);
    }

    &.hidden {
      transform: translateX(100%);
    }

    .modal-close {
      grid-row: 1 / span 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;

      @media (min-height: 1025px) and (orientation: portrait) {
        grid-row: auto;
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        grid-row: auto;
        position: absolute;
        z-index: 1;
        top: 5px;
        right: 0;
      }

      svg {
        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 4em !important;
        }
      }
    }

    .modal-body {
      grid-row: 2 / span 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0px;
      position: relative;
      width: 100%;
      left: 0;
      height: auto;

      .modal-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0%;
        width: 100%;
        height: 60px;
        padding-left: 50px;

        @media (min-height: 1025px) and (orientation: portrait) {
          padding-left: 0px;
          width: 550px;
          margin-bottom: 2%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          padding-left: 0px;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          padding-left: 0%;
          height: 40px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px;
          padding-left: 60px;
          display: block;
        }

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 28px;
          font-weight: 600;
          text-shadow: 1px 1px 1px #3b3b3b80;
          font-family: 'Roboto Slab', serif;
          color: white;

          @media (min-height: 1025px) and (orientation: portrait) {
            font-size: 42px;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            padding-left: 5%;
          }

          @media (max-width: 700px) and (orientation: portrait) {
            color: white;
            font-size: 28px;
          }
        }
      }

      .modal-container {
        position: initial;
        top: auto;
        left: auto;
        min-height: 0;
        transform: none;
        padding: 25px 0px 25px 0px;
        width: 350px;
        height: 400px;
        border-radius: 10px;
        box-shadow: 2px 1px 3px 0px #3b3b3b;

        @media (min-height: 1025px) and (orientation: portrait) {
          height: 600px;
          width: 550px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 175px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          height: 300px;
          padding: 10px 0px 10px 0px;
        }

        button {
          @media (max-width: 1300px) and (orientation: landscape) {
            bottom: 15% !important;
          }

          @media (min-height: 1025px) and (orientation: portrait) {
            bottom: 8% !important;
            height: 60px;
            width: 200px;
            font-size: 30px !important;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            bottom: 32% !important;
            height: 40px !important;
            width: 90px !important;
            border-radius: 4px;
            right: 1.5%;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            bottom: 24% !important;
          }
        }

        .customer-info-form {
          justify-content: space-around;
          height: 100%;

          @media (min-height: 1025px) and (orientation: portrait) {
            justify-content: flex-start;
          }

          .add-cc-email-text {
            font-weight: 600;

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              display: none;
            }
          }

          .input-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media (min-height: 1025px) and (orientation: portrait) {
              margin: 2%;
            }

            input {
              @media (min-height: 1025px) and (orientation: portrait) {
                height: 50px !important;
                width: 400px !important;
              }
            }

            p {
              width: 100%;
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-weight: 600;
              font-size: 14px;

              @media (min-height: 1025px) and (orientation: portrait) {
                font-size: 20px;
              }
            }
          }
        }

        .title {
          font-weight: 600 !important;
        }
      }
    }
  }

  .menu-8-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
    }

    @media (max-width: 450px) and (orientation: portrait) {
      height: 450px;
      padding-top: 50px;
    }

    img {
      object-fit: contain;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        width: 150px;
      }

      // @media (max-width: 450px) and (orientation: portrait) {
      //   width: 300px;
      // }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 3vh;
      width: 400px;
      cursor: pointer;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        margin-bottom: 70px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        margin-top: 5vh;
        width: 80vw;
      }

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 24px;
        color: white;
        font-weight: 600;
        text-shadow: 1px 1px 1px #3b3b3b80;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        font-family: 'Roboto Slab', serif;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 20px;
          -webkit-line-clamp: 3;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 18px;
        }
      }
    }
  }

  .portfolio-content-parent {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.4s ease;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
    }

    .scroll-confirm {
      position: absolute;
      bottom: 5%;
      left: 6%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 40px;

      @media (min-height: 1025px) and (orientation: portrait) {
        left: 12%;
      }

      @media (max-width: 1367px) and (orientation: landscape) {
        left: 10%;
        bottom: 10%;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        left: 12%;
        bottom: 15%;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        bottom: 7%;
        display: none;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        bottom: auto;
        top: 2vh;
        left: 90px;
        width: 250px;
        height: 50px;
        display: none;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        bottom: 22vh;
        left: -2%;
        width: 200px;
        height: 25px;
        display: none;
      }

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 20px;
        font-weight: 600;
        color: white;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 2px;
        text-shadow: 1px 1px 1px #3b3b3b80;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          font-size: 16px;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          font-size: 12px;
        }
      }
    }

    .logo-confirm {
      visibility: hidden;
      position: absolute;
      bottom: -5%;
      left: calc(50% - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        display: none;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        visibility: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;
        bottom: -2%;
        display: none;
      }
    }

    .portfolio-toplevel {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      overflow: hidden;
      width: 100%;
      height: 100%;

      button {
        width: 250px;
        height: 50px;
        margin: 0px 0 38px 10px;
        background-color: transparent;
        font-family: 'Oswald', sans-serif;
        // align text in p to left
        p {
          text-align: left;
        }
        &.hidden {
          display: none !important; // need !important prevent override from flex
        }

        &.active {
          display: inherit;
        }
      }

      // as list grows, make the size smaller
      $elements: 10;
      @for $i from 0 to $elements {
        ul:nth-child(#{$i + 1}) {
          button {
            height: calc(45px - #{$i}* 3px);
            white-space: nowrap;
            // overflow: hidden;
            text-overflow: ellipsis;
            text-shadow: 2px 2px 2px black;
            p {
              font-size: calc(36px - #{$i} * 5px);
            }
          }
        }
      }

      .group {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        margin: 1%;
        margin-right: 2%;
        width: 22.5%;
        // hide all ul under, aka level 1 and up will be hidden

        .toplevel-item {
          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-family: 'Oswald', sans-serif;
          }
        }

        div:nth-child(n + 2) {
          display: none; // THIS MEANS NO CHILDREN WILL APPEAR. THIS IS THE ONLY THING THAT MAKES IT SO THE TOP LEVEL ITEMS ARE THE ONLY ONES RENDERED.
          height: 225px;
        }
      }
    }

    .portfolio-big-nav-container,
    .portfolio-big-nav-prev-container {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-direction: row;
      overflow-y: hidden;
      overflow-x: scroll;
      padding-left: 10%;
      padding-right: 2%;
      width: 100%;
      height: 100%;
      z-index: 1;
      scrollbar-width: none;
      position: relative;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        display: none;
        background: transparent;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        padding-left: 15%;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        padding-left: 1%;
      }

      &.submenu {
        height: 43% !important;
        overflow-y: auto;
        transition: 0.5s all ease;
        mask-image: url('../../assets/images/MenuMask.png');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        -webkit-mask-image: url('../../assets/images/MenuMask.png');
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        padding-top: 20px;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          width: calc(100vw - 60px);
          left: 60px;
          position: absolute;
          top: 60px;
          height: 60% !important;
        }
      }

      .scrollback-container {
        height: 35px;
        width: 150px;
        border-radius: 30px;
        position: fixed;
        top: 18.5%;
        left: 150px;
        display: none;
        cursor: pointer;
        box-shadow: 2px 2px 3px 0px #3b3b3b80;
        z-index: 1;
        transition: 0.3s all ease;
        opacity: 0;
        -webkit-overflow-scrolling: touch; // iOS safari

        @media (min-height: 1025px) and (orientation: portrait) {
          top: auto;
          bottom: 10%;
          height: 75px;
          width: 200px;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          top: 20%;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          top: 3%;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          top: 65vh;
          left: 12px;
          width: 150px;
          height: 27px;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          height: 25px;
          width: 135px;
        }

        p {
          margin-block: 0px;
          font-size: 18px;
          font-weight: 600;

          @media (min-height: 1025px) and (orientation: portrait) {
            font-size: 24px;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            font-size: 14px;
          }
        }

        .icon-box {
          height: 100%;
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .text-box {
          height: 100%;
          width: 70%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      // EVERYTHING INSIDE .GROUP IS FOR TOP-LEVEL ELEMENTS. ALL OUTSIDE .GROUP IS FOR SUB-LEVEL AND TERMINUS-LEVEL ELEMENTS
      .group {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center;
        margin: 1%;
        // width: 22.85%;
        // hide all ul under, aka level 1 and up will be hidden

        @media (max-width: 450px) and (orientation: portrait) {
          padding-bottom: 22%;
        }

        .menu-section {
          height: 600px;
          width: 950px;
          background-color: transparent;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          overflow: visible;

          @media (min-width: 1368px) and (orientation: landscape) {
            padding-top: 10%;
          }

          @media (max-width: 1367px) and (orientation: landscape) {
            height: 400px;
            width: 650px;
            overflow: visible !important;
          }

          @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
            height: 350px;
            width: 600px;
            overflow: visible !important;
          }

          @media (max-width: 700px) and (orientation: portrait) {
            margin-bottom: 70px;
            height: 385px;
            width: 385px;
            overflow: visible !important;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            margin-bottom: 0px;
            height: 385px;
            width: 385px;
            overflow: visible !important;
          }

          // mobile landscape
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 250px;
            width: 250px;
            overflow: visible !important;
          }

          @media (max-width: 380px) {
            margin-bottom: 0px;
            width: 345px;
            height: 235px;
          }

          .sublevel-modal {
            position: absolute;
            background-color: #3b3b3bbf;
            height: 80%;
            width: 40%;
            z-index: 2;
            transition: 0.25s ease all;
            border-radius: 8px;
            cursor: default;
            box-shadow: 0px 0px 12px 1px #00000080;

            @media (max-width: 1367px) and (orientation: landscape) {
              height: 425px;
              width: 400px;
            }

            @media (min-height: 1025px) and (orientation: portrait) {
              height: 95%;
              width: 500px;
            }

            @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
              height: 500px;
              width: 400px;
            }

            @media (max-width: 700px) and (orientation: portrait) {
              height: 400px;
              width: 300px;
            }

            .sublevel-modal-header {
              position: absolute;
              background-color: #3b3b3b;
              height: 15%;
              width: 100%;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              box-shadow: 0px 8px 3px -8px #3b3b3b80;

              .back-box {
                width: 10%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 2%;
                padding-top: 8%;

                svg {
                  @media (min-height: 1025px) and (orientation: portrait) {
                    font-size: 32px !important;
                  }
                }
              }

              .title-box {
                width: 80%;
                height: 100%;
                padding: 5% 5% 0% 5%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                @media (max-width: 1367px) and (orientation: landscape) {
                  padding-bottom: 3%;
                }

                @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                  padding-bottom: 3%;
                }

                p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 19px;
                  color: white;
                  margin-block-start: 0px;
                  margin-block-end: 0px;
                  font-weight: 600;

                  @media (min-height: 1025px) and (orientation: portrait) {
                    font-size: 24px;
                  }
                }
              }

              .home-box {
                width: 10%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 2%;
                padding-top: 8%;

                svg {
                  @media (min-height: 1025px) and (orientation: portrait) {
                    font-size: 32px !important;
                  }
                }
              }
            }
          }
        }

        div:nth-child(n + 2) {
          display: none; // THIS MEANS NO CHILDREN WILL APPEAR. THIS IS THE ONLY THING THAT MAKES IT SO THE TOP LEVEL ITEMS ARE THE ONLY ONES RENDERED.
          height: 225px;
        }

        .big-nav {
          overflow: hidden;
          position: relative;
          height: 100%;
          width: 100%;
          // border-radius: 12px;

          @media (max-width: 450px) and (orientation: portrait) {
            overflow: visible !important;
          }
        }

        .portfolio-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          overflow: visible !important;
          height: 100%;
          width: 100%;
          cursor: pointer;
          position: relative;
          object-fit: contain;

          @media (max-width: 450px) and (orientation: portrait) {
            // overflow: visible !important;
          }

          button {
            p {
              font-size: 1.4em;
            }
          }

          .portfolio-single-image {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            // switch back to fill if disliked
          }

          .iframe-container {
            position: relative;
            width: 100%;
            height: 100%;

            .website-link {
              height: 20px;
            }

            iframe {
              display: block;
              background: transparent;
              border: none;
              width: 100%;
              height: 100%;
              scrollbar-width: none;
              -webkit-scrollbar {
                width: 0px;
              }
            }
          }

          .website-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-family: 'Oswald', sans-serif;
              font-size: 4em;
              letter-spacing: 0.1em;
              font-weight: 600;
              color: white;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
          }

          // hide video control in nav
          video::-webkit-media-controls-enclosure {
            // display: none !important;
          }
        }
      }

      // END .GROUP, ALL ELEMENTS HERE ARE FOR SUB-LEVEL AND TERMINUS-LEVEL ELEMENTS
      .big-nav {
        overflow: hidden;
        position: relative;
        width: 22.5%;
        border-radius: 5px;
      }

      .big-nav-extra {
        overflow: hidden;
        height: 200px;
        width: 22.5%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        margin: 1%;
        border: 1px solid black;
        border-radius: 5px;

        .logo-extra {
          img {
            // padding: 5px;
            // background-color: #FFFFFF80;
            // border-radius: 2px;
          }
        }

        .logo-extra-thumb {
        }
      }

      .big-nav-thumb {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;

        .logo-thumb {
          object-fit: contain;
          max-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .portfolio-content {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 100%;
        cursor: pointer;
        position: relative;
        object-fit: contain;

        button {
          padding-right: 10px;
          padding-left: 10px;

          p {
            font-size: 1em;
          }
        }

        .mux-player {
          .overlay-play-button {
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          div {
            video {
              border-radius: 5px;
            }
          }
        }

        .portfolio-single-image {
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .iframe-container {
          position: relative;
          width: 100%;
          height: calc(100vh - 100px);

          .website-link {
            height: 20px;
          }

          iframe {
            display: block;
            background: transparent;
            border: none;
            width: 100%;
            height: 100%;
            scrollbar-width: none;
            -webkit-scrollbar {
              width: 0px;
            }
          }
        }

        .website-content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-family: 'Oswald', sans-serif;
            font-size: 4em;
            letter-spacing: 0.1em;
            font-weight: 600;
            color: white;
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
          }
        }

        // hide video control in nav
        video::-webkit-media-controls-enclosure {
          // display: none !important;
        }
      }
      button {
        margin: 0;
      }

      ::before {
        content: '';
        width: 0;
        // padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    }

    .portfolio-big-nav-prev-container {
      position: static;
      width: 100%;
      height: 100%;
      justify-content: center;
      overflow-x: hidden;
      padding-left: 0%;
      padding-right: 0%;
      z-index: -1;
      flex-direction: column;
      text-align: center;

      @media (min-height: 1025px) and (orientation: portrait) {
        left: 100px !important;
        top: 80px !important;
        grid-template-columns: none !important;
        grid-template-rows: 6fr 2fr 1fr 1fr;
      }

      @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
        grid-template-columns: none !important;
        grid-template-rows: 6fr 2fr 1fr 1fr;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        left: 60px !important;
        top: 50px !important;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        left: auto !important;
        top: 20vh !important;
        height: 55% !important;
        width: 100% !important;
        grid-template-columns: none !important;
        grid-template-rows: 7fr 3fr !important;
      }

      .portfolio-content-menu {
        @media (min-height: 1025px) and (orientation: portrait) {
          grid-column: auto !important;
          flex-direction: row !important;
          grid-row: 2 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          grid-column: auto !important;
          flex-direction: row !important;
          grid-row: 2 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: min-content;
          padding-bottom: 175px;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          grid-column: auto !important;
          flex-direction: row !important;
          grid-row: 2 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.hidden {
          @media (max-width: 450px) and (orientation: portrait) {
            grid-row: auto !important;
          }
        }

        &.maxed {
          @media (orientation: landscape) {
            display: none !important; //don't show if there are no sibling elements to make a menu from
          }
        }

        .menu-item-holder {
          height: 700px !important;
          max-height: 700px !important;
          mask-image: url('../../assets/images/MenuMask.png');
          mask-size: 100% 100%;
          mask-repeat: no-repeat;
          -webkit-mask-image: url('../../assets/images/MenuMask.png');
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          padding-top: 40px;

          scrollbar-width: none;
          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
            background: transparent;
          }

          @media (min-width: 451px) and (orientation: landscape) {
            align-items: flex-end !important;
          }

          @media (min-width: 2300px) and (orientation: landscape) {
            max-height: 950px !important;
            height: 950px !important;
            padding-top: 50px;
          }

          @media (min-width: 3500px) and (orientation: landscape) {
            max-height: 950px !important;
            height: 950px !important;
          }

          @media (max-width: 1368px) and (orientation: landscape) {
            height: 850px !important;
            max-height: 850px !important;
            padding-top: 40px;
            padding-bottom: 200px;
          }

          @media (max-width: 1300px) and (orientation: landscape) {
            height: 700px !important;
            max-height: 700px !important;
            padding-top: 40px;
          }

          @media (min-height: 1025px) and (orientation: portrait) {
            height: 100% !important;
            max-height: none !important;
            flex-direction: row !important;
            width: calc(100vw - 80px);
            max-width: calc(100vw - 80px);
            overflow-y: hidden !important;
            overflow-x: auto !important;
            padding-top: 0px;
          }

          @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
            height: 100% !important;
            max-height: none !important;
            flex-direction: row !important;
            width: calc(100vw - 80px);
            max-width: calc(100vw - 80px);
            overflow-y: hidden !important;
            overflow-x: auto !important;
            padding-top: 0px;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 200px !important;
            max-height: 200px !important;
            padding-top: 50px;
          }

          @media (max-width: 700px) and (orientation: portrait) {
            padding-top: 10px;
            align-items: flex-start !important;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            height: 100% !important;
            max-height: none !important;
            flex-direction: row !important;
            width: 100vw;
            max-width: 100vw;
            overflow-y: hidden !important;
            overflow-x: auto !important;
          }

          .terminus-level-thumb {
            flex-shrink: 0;
            font-family: 'Roboto Slab', serif;
            font-weight: 400;

            @media (max-width: 1300px) and (orientation: landscape) {
              height: 80px;
              margin: 0px 0px 10px 0px;
            }

            @media (min-height: 1025px) and (orientation: portrait) {
              height: 190px;
              width: 250px;
              margin: 0%;
              margin-left: 1%;
              flex-shrink: 0;
            }

            @media (max-width: 1024px) and (min-width: 950px) and (orientation: portrait) {
              height: 190px;
              width: 250px;
              margin: 0%;
              margin-left: 1%;
              flex-shrink: 0;
            }

            @media (max-width: 949px) and (min-width: 451px) and (orientation: portrait) {
              height: 135px;
              width: 165px;
              margin: 0%;
              margin-left: 1%;
              flex-shrink: 0;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              height: 50px;
            }

            @media (max-width: 700px) and (orientation: portrait) {
              height: 95px;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              height: 90px;
              width: 140px;
              margin: 0%;
              margin-left: 1%;
              margin-bottom: 5px;
            }

            @media (max-width: 375px) and (max-height: 800px) and (orientation: portrait) {
              height: 60px;
              width: 100px;
            }
          }
        }
      }

      .portfolio-content-container {
        @media (min-height: 1025px) and (orientation: portrait) {
          grid-column: auto !important;
          grid-row: 1 / span 1;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          grid-column: auto !important;
          grid-row: 1 / span 1;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          grid-column: auto !important;
          grid-row: 1 / span 1;
        }

        &.hidden {
          @media (max-width: 450px) and (orientation: portrait) {
            grid-row: 1 / span 2;
          }
        }

        .portfolio-content {
          @media (min-width: 1301px) and (orientation: landscape) {
            width: 100%;
            left: 2%;
            height: 700px !important;

            &.maxed {
              height: 95% !important;
              bottom: 2%;
            }

            &.normal {
              height: 90% !important;
            }
          }

          @media (min-width: 2300px) and (orientation: landscape) {
            width: 100%;
            left: 2%;
            height: 1200px !important;

            &.maxed {
              height: 90% !important;
              bottom: 2%;
            }
          }

          @media (min-width: 3500px) and (orientation: landscape) {
            width: 100%;
            left: 2%;
            height: 1500px !important;

            &.maxed {
              height: 90% !important;
              bottom: 0%;
            }
          }

          @media (max-width: 1367px) and (min-width: 1025px) and (orientation: landscape) {
            height: 550px !important;

            &.maxed {
              height: 575px !important;
              bottom: 15%;
            }

            &.toggle-content {
              bottom: 0%;
            }
          }

          @media (max-width: 1024px) and (orientation: landscape) {
            height: 400px !important;
            width: 100%;
            left: 3%;
            top: 8%;

            &.maxed {
              height: 470px !important;
              left: 1%;
              top: -17%;
            }

            &.toggle-content {
              top: 0%;
            }
          }

          @media (min-height: 1025px) and (orientation: portrait) {
            height: 600px !important;
            right: 4%;
            width: 90%;

            &.maxed {
              height: 60% !important;
              bottom: 18%;
            }
          }

          @media (max-width: 1024px) and (min-width: 950px) and (orientation: portrait) {
            height: 600px !important;
            right: 4.5%;
            width: 90%;

            &.maxed {
              overflow: visible;
              height: 70% !important;
            }

            &.toggle-content {
              right: 0%;
            }
          }

          @media (max-width: 949px) and (min-width: 451px) and (orientation: portrait) {
            height: 90%; //450px !important;
            right: 5%;
            width: 86vw; // NOTE: using vw since % causes issue for Slider gallery flow

            &.toggle-content {
              right: 0%;
              height: 400px !important;
            }
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 220px !important;
            top: 10%;
            width: 75%;
            left: 15%;

            &.maxed {
              height: 250px !important;
              top: -15%;
              width: 75%;
              left: 15%;

              &.toggle-content {
                left: 0%;
              }
            }
          }

          @media (max-width: 700px) and (orientation: portrait) {
            height: 100%; //280px !important;
            width: 80vw; // NOTE: using vw since % causes issue for Slider gallery flow
            right: 7%;

            &.toggle-content {
              height: 100%; //280px !important;
            }
          }

          @media (max-width: 450px) and (orientation: portrait) {
            height: 100%; //250px !important;
            width: 100vw; // NOTE: using vw since % causes issue for Slider gallery flow
            right: auto;

            &.toggle-content {
              height: 100%; //250px !important;
            }
          }

          .overlay-play-button {
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .portfolio-gallery-view {
            position: relative;

            .carousel-root {
              flex-shrink: 0;
              width: 100%;

              @media (orientation: landscape) and (min-width: 1368px) {
                height: 100% !important;
              }

              @media (orientation: landscape) and (max-width: 1367px) {
                height: 100% !important;
              }

              @media (min-height: 1025px) and (orientation: portrait) {
                height: 100% !important;
              }

              @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                height: 100% !important;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                height: 100% !important;
              }
            }

            .hovering-chevron {
              @media (orientation: landscape) {
                display: block;
                position: absolute;
                z-index: 4;
                border-radius: 50%;
                background-color: #3b3b3b80;
                top: 45%;
              }

              @media (orientation: landscape) and (max-width: 1368px) and (min-width: 1025px) {
                top: 34%;
              }

              @media (orientation: landscape) and (max-width: 1024px) {
                top: 40%;
              }

              @media (min-height: 1025px) and (orientation: portrait) {
                display: block;
                position: absolute;
                z-index: 4;
                border-radius: 50%;
                background-color: #3b3b3b80;
                top: 45%;
              }

              @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                display: block;
                position: absolute;
                z-index: 4;
                border-radius: 50%;
                background-color: #3b3b3b80;
                top: 45%;
              }

              @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                top: 35%;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                display: block;
                position: absolute;
                z-index: 4;
                border-radius: 50%;
                background-color: #3b3b3b80;
                top: 45%;
              }

              .containDiv {
                @media (orientation: landscape) {
                  height: 70px !important;
                  width: 70px !important;
                }

                @media (orientation: landscape) and (max-width: 1368px) and (min-width: 1025px) {
                  height: 70px !important;
                  width: 70px !important;
                }

                @media (orientation: landscape) and (max-width: 1024px) {
                  height: 50px !important;
                  width: 50px !important;
                }

                @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                  height: 70px !important;
                  width: 70px !important;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  height: 40px !important;
                  width: 40px !important;
                }

                @media (max-width: 700px) and (orientation: portrait) {
                  height: 40px !important;
                  width: 40px !important;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 40px !important;
                  width: 40px !important;
                }
              }
            }

            .page-count-container {
              @media (orientation: landscape) {
                display: none !important;
              }

              @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                display: none !important;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                display: none !important;
              }
            }

            .thumb-container {
              @media (orientation: landscape) {
                display: none !important;
              }

              @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                display: none !important;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                display: none !important;
              }
            }
          }
        }
      }
    }

    .portfolio-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column: 2 / span 1;

      &.maxed {
        @media (orientation: landscape) {
          grid-column: 1 / span 2;
        }
      }

      .portfolio-content-wrapper {
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 80px); // 80px is height of top banner

        @media (min-height: 1025px) and (orientation: portrait) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.hidden {
          @media (max-width: 450px) and (orientation: portrait) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .portfolio-content-menu-container {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .portfolio-content-menu {
        display: flex;
        justify-content: center;
        overflow: visible;
        position: absolute;
        z-index: 3;
        background-color: red;

        button {
          background-color: transparent;
          transition: all 0.15s ease;

          // increase font size
          p {
            font-size: 24px;
          }
        }

        button:hover {
          transform: scale(1.1, 1.1);
        }
      }
    }
  }

  .portfolio-action-menu {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 5;
    height: 94px;

    .action-menu-popup {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px !important;
        width: 90px !important;
        position: unset !important;
        img {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }

    .action-menu-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 700px;

      .button-popup-left {
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in;

        div {
          transition: left 0.3s ease-in 0s;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
          background-color: #00000080;
          opacity: inherit;

          .containDiv {
            position: unset;
            transform: unset;
          }
        }
      }

      .button-popup-right {
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in;

        div {
          transition: left 0.3s ease-in 0s;
          //transition: transform 0.3s ease-in 0s;
          // transition: opacity 0s step-end 0s;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
          background-color: #00000080;
          opacity: inherit;
          .containDiv {
            position: unset;
            transform: unset;
            //opacity: inherit;
          }
        }
      }
    }

    &:hover {
      .action-menu-container {
        .button-popup-left {
          visibility: visible;
          opacity: 1;
          div:first-child {
            left: 33%;
          }
          div:nth-child(2) {
            left: 16.5%;
          }
          div:nth-child(3) {
            left: 0;
          }
        }

        .button-popup-right {
          visibility: visible;
          opacity: 1;
          div:first-child {
            left: 66.5%;
          }
          div:nth-child(2) {
            left: 83.5%;
          }
          div:nth-child(3) {
            left: 100%;
          }
        }
      }
    }
  }

  .portfolio-briefcase-container {
    position: fixed;
    display: grid;
    grid-template-rows: 80px auto;
    right: 0;
    width: 400px;
    height: 100%;
    transition: all 0.3s;
    transform: translateX(100%);
    background-color: #3b3b3b59;

    @media (min-height: 1025px) and (orientation: portrait) {
      width: 100vw;
      min-width: 0;
      background-color: #3b3b3bbf;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      width: 100vw;
      min-width: 0;
      background-color: #3b3b3bbf;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      width: 100vw;
      min-width: 0;
      background-color: #3b3b3bbf;
    }

    &.active {
      transform: translateX(0%);
    }

    &.hidden {
      transform: translateX(100%);
    }

    .close-container {
      grid-row: 1 / span 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;

      @media (min-height: 1025px) and (orientation: portrait) {
        grid-row: auto;
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        grid-row: auto;
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
      }

      svg {
        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 4em !important;
        }
      }
    }

    .briefcase-body {
      grid-row: 2 / span 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0px;
      position: relative;

      @media (min-height: 1025px) and (orientation: portrait) {
        grid-row: auto;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        grid-row: auto;
        width: 100%;
      }

      .briefcase-title-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 9%;
        width: 100%;
        height: 60px;

        @media (min-height: 1025px) and (orientation: portrait) {
          padding-left: 0%;
          margin-bottom: 2%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          padding-left: 0%;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          height: 40px;
        }

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 28px;
          font-weight: 600;
          color: white;
          text-shadow: 1px 1px 1px #3b3b3b80;
          font-family: 'Roboto Slab', serif;

          @media (min-height: 1025px) and (orientation: portrait) {
            font-size: 42px;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            padding-left: 5%;
          }

          @media (max-width: 700px) and (orientation: portrait) {
            font-size: 28px;
            color: white;
          }
        }
      }
    }

    .pages-count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      width: 85%;

      @media (min-height: 1025px) and (orientation: portrait) {
        width: 100%;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 33px;
      }

      p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 18px;
        font-weight: 600;
        color: white;
        text-shadow: 1px 1px 1px #3b3b3b;

        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 24px;
        }
      }
    }

    .briefcase-action-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
      bottom: auto;
      margin-top: 20px;

      @media (max-width: 1367px) and (min-width: 451px) and (orientation: landscape) {
        margin-top: 0px;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        margin-top: 0px;
      }

      .briefcase-send-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 40px;
        background-color: #449ff5;
        margin-left: 1%;
        margin-right: 1%;
        cursor: pointer;

        @media (min-height: 1025px) and (orientation: portrait) {
          width: 150px;
          height: 50px;
        }

        p {
          font-weight: 600;
          font-size: 11px;
          color: white;
          margin-block-end: 0px;
          margin-block-start: 0px;

          @media (min-height: 1025px) and (orientation: portrait) {
            font-size: 16px;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            font-size: 10px;
          }
        }
      }
    }

    .briefcase-content-list {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      scrollbar-width: none;
      width: 90%;
      height: 600px;
      padding: 0;
      background-color: white;
      border-radius: 5px;

      @media (max-width: 1300px) and (orientation: landscape) {
        height: 450px;
      }

      @media (min-height: 1025px) and (orientation: portrait) {
        width: 700px;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 130px;
      }

      @media (max-width: 700px) and (orientation: portrait) {
        height: 350px;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        margin: 20px 0;
        background-color: rgb(163, 163, 163);
        color: white;
      }

      .briefcase-content-list-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        background-color: white;
        border-bottom: 1px solid #a3a3a380;

        @media (min-height: 1025px) and (orientation: portrait) {
          height: 80px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 57px;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 10px;
          padding-right: 10px;
          overflow: hidden;

          svg {
            @media (min-height: 1025px) and (orientation: portrait) {
              font-size: 2.5em !important;
            }
          }

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-weight: 600;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @media (min-height: 1025px) and (orientation: portrait) {
              font-size: 24px;
            }
          }
        }
      }
    }
    .briefcase-content-list::-webkit-scrollbar {
      width: 0px;
    }

    .briefcase-send-button {
      grid-row: 4/5;
    }
  }

  .portfolio-notepad-container {
    position: fixed;
    display: grid;
    grid-template-rows: 80px auto;
    right: 0;
    width: 400px;
    height: 100%;
    top: 0;
    transition: all 0.3s;
    transform: translateX(100%);
    background-color: #3b3b3b59;

    @media (min-height: 1025px) and (orientation: portrait) {
      width: 100vw;
      background-color: #3b3b3bbf;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      width: 100vw;
      background-color: #3b3b3bbf;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    @media (max-width: 700px) and (orientation: portrait) {
      width: 100vw;
      background-color: #3b3b3bbf;
    }

    &.active {
      transform: translateX(0%);
    }

    &.hidden {
      transform: translateX(100%);
    }

    // contains md close icon
    .notepad-close-container {
      grid-row: 1 / span 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;

      @media (min-height: 1025px) and (orientation: portrait) {
        grid-row: auto;
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
        grid-row: auto;
      }

      svg {
        @media (min-height: 1025px) and (orientation: portrait) {
          font-size: 4em !important;
        }
      }
    }

    .textarea-container {
      grid-row: 2 / span 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0px;
      position: relative;
      width: 92%;
      left: 4%;
      height: auto;

      @media (min-height: 1025px) and (orientation: portrait) {
        left: auto;
      }

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        grid-row: auto;
        width: 100%;
        left: auto;
      }

      .saved-notification {
        @media (min-height: 1025px) and (orientation: portrait) {
          top: 47% !important;
        }
      }

      .copied-notification {
        @media (min-height: 1025px) and (orientation: portrait) {
          top: 47% !important;
        }
      }

      .quill-container {
        background-color: white;
        height: 500px;
        display: inline-flex;
        width: 100%;

        @media (min-height: 1025px) and (orientation: portrait) {
          width: 800px;
          height: 600px;
        }

        @media (max-width: 1367px) and (min-width: 451px) and (orientation: landscape) {
          height: 450px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 150px;
          width: 87%;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          height: 350px;
        }
      }

      .scriptnote-text-container {
        background-color: white;
        height: 500px;
        display: inline-flex;
        width: 100%;
        overflow-y: auto;

        @media (min-height: 1025px) and (orientation: portrait) {
          width: 800px;
          height: 600px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 160px;
          width: 87%;
        }

        @media (max-width: 700px) and (orientation: portrait) {
          height: 350px;
        }

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          padding: 2%;
          font-family: 'Roboto Slab', serif;
          font-size: 14px;
        }
      }

      .note-title-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0%;
        width: 100%;
        height: 60px;

        @media (min-height: 1025px) and (orientation: portrait) {
          width: 800px;
          margin-bottom: 1%;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          padding-left: 0px;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px;
        }

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 28px;
          font-weight: 600;
          text-shadow: 1px 1px 1px #3b3b3b80;
          font-family: 'Roboto Slab', serif;
          color: white;

          @media (min-height: 1025px) and (orientation: portrait) {
            font-size: 42px;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            padding-left: 6%;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            color: white;
          }
        }
      }

      .page-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0%;
        width: 100%;
        margin-bottom: 10px;

        @media (min-height: 1025px) and (orientation: portrait) {
          width: 800px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          margin-left: 13%;
        }

        p {
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Roboto Slab', serif;
          color: white;

          @media (min-height: 1025px) and (orientation: portrait) {
            font-size: 20px;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            color: white;
          }
        }
      }

      .notepad-button-container-8 {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 60px;

        @media (min-height: 1025px) and (orientation: portrait) {
          margin-top: 2%;
          width: 800px;
          justify-content: space-around;
        }

        button {
          width: 40%;
          font-family: 'Oswald', sans-serif;
          transition: all 0.3s ease;
          font-size: 12px;
          border-radius: 3px;

          @media (min-height: 1025px) and (orientation: portrait) {
            height: 60px;

            div {
              .title {
                font-size: 24px !important;
              }
            }
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 30px !important;
            margin-top: 0px;
          }
        }

        &:hover {
        }
      }
    }
  }

  .back-container {
    display: none;
  }

  .pdf-body {
    &::-webkit-scrollbar {
      display: block !important;
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      display: block !important;
      background-color: rgb(50, 54, 53);
      border-radius: 20px;
    }
  }

  .whole-pdf {
    height: 100% !important; // changing this to 89 to 100% (9/07/23)

    @media (max-width: 450px) and (orientation: portrait) {
      height: 89% !important;
      margin-top: 4%;
    }
  }

  .splash-page-video-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
  }

  /* Safari 10.1+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      // Seems like safari does not work well with ::before to grow the grid contents
      // Override button in nav for safari to positon button text to center
      .portfolio-big-nav-container,
      .portfolio-big-nav-prev-container {
        button {
          position: relative;
          :first-child {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
