.portfolio-template.theme4.mobile {
    // override background-color in menu-template
    background-color: rgb(59, 59, 59);

    @media (max-width: 450px) and (orientation: portrait) {
      overflow: hidden;
    }

    @media (max-height: 450px) and (orientation: landscape) {
      overflow: hidden; 
      display: grid;
      grid-template-rows: 58px calc(100% - 110px) 52px;//42px 30px;
    }

    .toggle-mobile-toolbar {
      position: fixed;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-bottom-left-radius: 8px;
      z-index: 6
    }
    .portfolio-action-menu{
      display: none;
    }
    .MobileToolbar{
      grid-row: 3/3;
      height: 100%;
      position: static !important;
    }
    .portfolio-topright-toolbar {
      position: absolute;
      z-index: 7;
      right: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-color: #3b3b3b80;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s all ease;

      &.active {
        transform: translateY(0px);
      }

      &.hidden {
        transform: translateY(100%);
      }

      .icon-flex-container {
        height: 100%;
        grid-auto-rows: min-content;
        display: grid;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: clip;
        .icon-and-text-parent {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0px;
          width: 100vw;
  
          .test-absolute-text {
            width: 250px;
            height: 30px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            
            p {
              color: white;
              font-size: 20px;
              font-weight: 600;
              text-shadow: 1px 1px 1px #3b3b3b;
              cursor: pointer;
              font-family: 'Roboto Slab', serif;
            }
          }
        }
  
        .menu-icon-custom {
  
          .containDiv {
            height: 60px !important;
            width: 60px !important;
          }
        }
      }
    }

    .overlay-play-button {
      position: absolute;
      z-index: 1;
      height: 50% !important;
      width: 50%;
      top: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .send-email-popup {
      .send-email-popup-box {
        @media (max-width: 450px) and (orientation: portrait) {
          width: 250px;
        }

        .send-email-popup-text {
          input {
            @media (max-width: 450px) and (orientation: portrait) {
              width: 230px !important;
            }
          }
        }
      }
    }

    .modal {
      @media (max-width: 450px) and (orientation: portrait) {
        z-index: 8;
      }

      .modal-container {
        
        @media (max-width: 450px) and (orientation: portrait) {
          width: 250px;
        }

        button {
          @media (max-width: 450px) and (orientation: portrait) {
            width: 230px;
            height: 40px;
          }
        }

        .customer-info-form {

          input {
            @media (max-width: 450px) and (orientation: portrait) {
              width: 230px;
            }
          }
        }
      }
    }

    .control-dots {
      @media (max-width: 450px) and (orientation: portrait) {
        margin: 35px 0;
      }
    }

    .portfolio-scriptnote-container {
      position: fixed;
      right: 0%;
      width: 350px;
      height: 700px;
      top: calc(50% - 350px);
      background-color: white;
      border-radius: 12px;
      box-shadow: 0px 3px 12px 2px #3b3b3b;
      transition: all 0.5s;
      transform: translateX(100%);
      z-index: 5;

      @media (max-width: 450px) and (orientation: portrait) {
        height: 426px;
        width: 250px;
        top: calc(50% - 213px);
        left: calc(50% - 125px);
        box-shadow: none;
        transition: all 0s ease;
        z-index: 7 !important;
      }
  
      &.active {
        transform: translateX(-2%);
        @media (max-width: 450px) and (orientation: portrait) {
          transform: translateX(0%);
          visibility: visible;
        }
      }
  
      &.hide {
        transform: translateX(100%);

        @media (max-width: 450px) and (orientation: portrait) {
          transform: translateX(0%);
          visibility: hidden;
        }
      }
  
      // contains md close icon
      .scriptnote-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 2;
  
        .scriptnote-close-button {
          height: 70px !important;
          width: 70px !important;
          margin: 10px 10px 0px !important;
        }
      }
  
      .scriptnote-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        width: 100%;
        height: calc(100% - 110px);
        margin-top: 60px;
      }
  
      // content of script note
      .text-container {
        position: relative;
        width: 90%;
        height: 100%;
        margin-top: 10px;
        background-color: white;
        list-style: inside !important;
        overflow: auto;
  
        h1 {
          margin: 5px;
          // font-family: 'Newsreader', serif;
          font-size: 28px;
          font-weight: 600;
        }
  
        ul{
          list-style: inside !important;
        }
  
        p {
          margin: 6px;
        }
  
      }
    }

    .menu-icon-custom {
      transition: 0.2s all ease;

      .containDiv {
        height: 70px;
        width: 70px;

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }
      }

      img {

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }
      }
      
    }

    .menu-icon-custom:hover {
      transform: scale(1.15, 1.15);
    }

    .portfolio-action-menu {

      .action-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 330px;
  
        .exit-button {
          margin-top: auto;
        }
  
        .row-first {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
  
        .row-second {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
      }
    }

    .portfolio-menu-container {

        display: grid;
        grid-template-rows: 1fr 8fr;
        height: 100px;

        @media (max-width: 450px) and (orientation: portrait) {
          position: fixed; 
          bottom: 0px;
          z-index: 5;
          top: unset;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          position: fixed; 
          bottom: 0px;
          z-index: 5;
          top: unset;
        }
        @media (max-height: 450px) and (orientation: landscape) {
          grid-row: 1/1;
        }


        .logo {
          object-fit: contain;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          grid-row: 1/2;
          position: unset;
          grid-column: 1 / span 1;

          @media (max-width: 450px) and (orientation: portrait) {
            display: none;
            position: fixed;
            height: 40px;
            top: 5px;
          }
          @media (max-height: 450px) and (orientation: landscape) {
            display: none;
            position: fixed;
            height: 40px;
            top: 5px;
          }
          @media (max-width: 380px) and (orientation: portrait) {
            display: none;
          }

          // .top-gradient {

          // }

          
        }

        .menus-container {
          height: 100px;
          grid-row: 2/2;

          .back-container {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            padding-top: 40px;
            padding-bottom: 55px;
            height: 60vh;
            max-height: 90vh;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -webkit-scrollbar {
              width: 0px;
            }
            @media (max-width: 1367px) {
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              mask-image: none;
              -webkit-mask-image: none;
              flex-direction: row;
              justify-content: center;
              padding-bottom: 35px;
              padding-top: 50px;
              height: auto;
              overflow-y: hidden;
              overflow-x: auto;
              //touch-action: pan-x;
              width: 100vw;
              max-width: 100vw;
              justify-content: flex-start;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              padding-left: 50px;
              margin-top: 0px;
              padding: 0px;
            }
            @media (max-height: 450px) and (orientation: landscape) {
              padding-left: 50px;
              margin-top: 0px;
              height: 100%;
              padding: 0px;
              margin: 0px;
              flex-direction: row;
              overflow-y: clip;
            }

            @media (max-width: 380px) and (orientation: portrait) {
              padding-left: 0px;
              margin-top: 0px;
              padding: 0px;
            }
            
          .back-container:hover{
            filter: brightness(115%);
              transform: scale(1.05, 1.05);
          }
      
            img {
              height: 100px;
              width: 100px;
              transition: all 0.4s;
              cursor: pointer;
            }

            .portfolio-content-menu-container {
              @media (max-height: 450px) and (orientation: landscape) {
                grid-row: 2/2;
                height: 100%;
              }

              button {
                // margin-bottom: 30px;

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 75px;
                }
                @media (max-height: 450px) and (orientation: landscape) {
                  height: 48px;
                  margin: 0px 4px;
                }

                div {
                  overflow: hidden;
                }
              }

              .portfolio-content-menu {

                @media (max-height: 450px) and (orientation: landscape) {
                  height: 100%;
                  margin: 0px 4px;
                  align-items: center;
                }

                button {
                  transition: 0.5s all ease;
                  margin-bottom: 0px;

                  

                  div {
                    overflow: hidden;
                  }

                }

                button:hover {
                  filter: brightness(120%);
                }
              }
            }
      
            // img:hover {
            //   filter: brightness(115%);
            //   transform: scale(1.05, 1.05);
            // }
          }

          .portfolio-menu {
            // change hidden and active to be button specific
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            height: 80vh;
            max-height: 90vh;
            padding-top: 5px !important;
            scrollbar-width: none;
            -webkit-scrollbar {
              width: 0px;
            }
            &.isAtEnd{              
              opacity: 0;
            }            
            right: 5.5%;

            @media (max-width: 1024px) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              height: auto;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              padding-right: 80px;
              padding-left: 0px;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              width: 100vw;
              max-width: 100vw;
              padding-bottom: 25px;
              padding-top: 25px;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              margin-top: 0px !important;
              padding-bottom: 25px;
              padding-left: 5px;
            }
            @media (max-height: 450px) and (orientation: landscape) {
              margin-top: 0px !important;
              padding-bottom: 0px;
              padding-top: 0px;
              padding-left: 5px !important;
              right: 0px;
              height: 100%;
            }

            // .row-container {

            // }

             .menu-filler {
              @media (max-width: 1367px) and (min-width: 1023px) {
                display: none;
              }

              @media (max-width: 1024px) {
                display: none;
              }
             }

            // .group {

            // }

            .active {
              display: flex;
              visibility: visible;
              opacity: 1;
              animation: appear-from-left 0.5s forwards;
            }
      
            .hidden {
              display: none;
              animation: hide-to-right 0.5s forwards;
            }
                // all button under menu


            button {
              // add small animation
              transition: all 0.5s;
              &:hover {
                margin-right: 10px;
                filter: brightness(120%);
              }
              }

              .title-container {
                overflow: hidden;
              }
            }

            button.level-0 {
              @media (max-width: 450px) and (orientation: portrait) {
                height: 75px;
              }
              @media (max-height: 450px) and (orientation: landscape) {
                height: 48px;
                margin: 0px 4px;
              }
            }

            button.level-1 {
              @media (max-width: 450px) and (orientation: portrait) {
                height: 70px;
              }
              @media (max-height: 450px) and (orientation: landscape) {
                height: 42px;
                margin: 0px 4px;
              }
            }

            button.level-2 {
              @media (max-width: 450px) and (orientation: portrait) {
                height: 65px;
              }
              @media (max-height: 450px) and (orientation: landscape) {
                height: 36px;
                margin: 0px 4px;
              }
            }

            button.level-3 {
              @media (max-width: 450px) and (orientation: portrait) {
                height: 60px;
              }
              @media (max-height: 450px) and (orientation: landscape) {
                height: 30px;
                margin: 0px 4px;
              }
            }
            @media (max-height: 450px) and (orientation: landscape) {
              justify-content: flex-start;
            }
            

        }
        }

    
    
        // change all button color under menu red
        button {
            margin-top: 8px;
        }

        
  
    .portfolio-content-parent {
      // change all button color under portfolio-content-parent yellow

      @media (max-width: 450px) and (orientation: portrait) {
        position: fixed;
        top: 70px;
        margin-bottom: 0px;
        bottom: 100px;
      }
      @media (max-height: 450px) and (orientation: landscape) {
        position: fixed;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100vw;
        top: 100px;
        bottom: 0px;
      }

      @media (max-width: 380px) and (orientation: portrait) {
        position: fixed;
        top: 70px;
        margin-bottom: 0px;
        bottom: 100px;
      }

      button {
        background-color: rgb(55, 128, 52);
        margin-top: 15px;
      }

      // .containDiv {
      
      // }

      .portfolio-content-container {
        
        @media (max-width: 450px) and (orientation: portrait) {
          // height: 400px;
          height: 100%;
        }
        @media (max-height: 450px) and (orientation: landscape) {
          height: 100%;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          // height: 300px;
          height: 100%;
        }
        

        .portfolio-content {

          .pdf-banner {
            .menu-icon-custom {
              .containDiv {
                

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 40px !important;
                  width: 40px !important;
                }
                @media (max-height: 450px) and (orientation: landscape) {
                  height: 40px !important;
                  width: 40px !important;
                }

                @media (max-width: 380px) and (orientation: portrait) {
                  height: 35px !important;
                  width: 35px !important;
                }
                @media (max-height: 380px) and (orientation: landscape) {
                  height: 35px !important;
                  width: 35px !important;
                }
              }
            }
          }

          .portfolio-gallery-view {
            position: relative;

            

            .hovering-chevron {
              display: block;
              position: absolute;
              z-index: 4;
              border-radius: 50%;
              background-color: #3b3b3b80;
              top: 45%;

            
              .menu-icon-custom {

                // .containDiv {
            
                // }

                img {
                  @media (max-width: 450px) and (orientation: portrait) {
                    height: 40px;
                    width: 40px;
                  }
                }
              }
            }

            .carousel-root {
              height: 90% !important;
              width: 100%;
              flex-shrink: 0;
            

              // .containDiv {

              // }
            }

            .thumb-container {
              display: none !important;

              
            }

            // .page-count-container {
              
            // }

          }
        }
      }

    }

    .portfolio-notepad-container {
      transition: all 0s;
      border-left-width: 4px !important;
      box-shadow: 0px 3px 12px 2px #3b3b3b;
      width: 400px;
      height: 700px;
      top: calc(50% - 350px);
      right: calc(50% - 200px);
      border-radius: 12px;
      z-index: 5;
      @media (max-height: 450px) and (orientation: landscape) {
        height: 350px;
        width: 426px;
        right: calc(50% - 213px);
        top: calc(50% - 175px);
        z-index: 7 !important;
      }
      
      @media (max-width: 450px) and (orientation: portrait) {
        width: 250px;
        height: 426px;
        top: calc(50% - 213px);
        right: calc(50% - 125px);
        z-index: 7 !important;
      }

      &.active {
        visibility: visible;

      
      }

      &.hidden {
        transform: translateX(0%);
        visibility: hidden;

        
      }

      &.hide {
        transform: translateX(0%);
        visibility: hidden;

        
      }

      .notepad-close-button {
        height: 70px !important;
        width: 70px !important;
        margin: 10px 10px 0px !important;

        
      }

      .textarea-container {
        margin-top: 0px !important;
        font-family: 'Newsreader', serif;

        

        .notes-title {
          margin-bottom: 15px !important;

        
        }

        .saved-notification {
          top: 53% !important;
          box-shadow: 1px 1px 2px 1px #808080;
          font-size: 17px !important;
          height: 34px !important;
          width: 115px !important;
          left: calc(50% - 69px) !important;
          letter-spacing: 0.05em;
        }

        .copied-notification {
          top: 53% !important;
          box-shadow: 1px 1px 2px 1px #808080;
          font-size: 17px !important;
          height: 34px !important;
          width: 140px !important;
          left: calc(50% - 77px) !important;
          letter-spacing: 0.05em;

          
        }
      }

      .notepad-button-container {
        margin-bottom: 20px;

        button {
          border-radius: 8px;

          

          p {
            font-weight: 600;
          }
        }
      }

    }

    .portfolio-briefcase-container {
        position: fixed;
        grid-template-rows: 1fr 9fr 1.25fr 50px;
        display: grid;
        right: 0;
        width: 400px;
        height: 700px;
        background-color: rgb(68, 68, 68);
        border-width: 4px;
        border-left-width: 4px;
        border-color: #b0b0b0;//PortSett
        border-style: solid;
        transition: all 0s;
        overflow: hidden;
        // transform: translateX(100%);
        box-shadow: 0px 3px 12px 2px #3b3b3b;
        border-radius: 12px;
        top: calc(50% - 350px);
        right: calc(50% - 200px);
        @media (max-height: 450px) and (orientation: landscape) {
          height: 350px;
          width: 426px;
          right: calc(50% - 213px);
          top: calc(50% - 175px);
          z-index: 7 !important;
          grid-template-rows: auto 119fr 80px 50px;
        display: grid;
        }
        @media (max-width: 450px) and (orientation: portrait) {
          width: 250px;
          height: 426px;
          top: calc(50% - 213px);
          right: calc(50% - 125px);
          min-width: 0;
          z-index: 7 !important;
        }
    
        &.active {
          // transform: translateX(0);
          visibility: visible;

        }
    
        &.hidden {
          // transform: translateX(100%);
          transform: translateX(0%);
          visibility: hidden;

        }

        .page-saved-holder {
          @media (max-width: 450px) and (orientation: portrait) {
            display: none !important;
          }
        }

        .briefcase-action-button-container {

          button {

            p {
              font-weight: 600;
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 15px;

              @media (max-width: 450px) and (orientation: portrait) {
                font-size: 13px;
              }
            }
          }
        }

        .portfolio-briefcase-label{
            grid-row: 1/2;
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            flex-basis: 12vw;
            z-index: 1;

            div {
              
              height: 70px !important;
              width: 70px !important;
              margin: 10px 10px 0px !important;

              @media (max-height: 450px) and (orientation: landscape) {
                height: 35px !important;
                width: 35px !important;
              }
            }

            p {
              margin: 0px 0px 6px 23px !important;

              @media (max-height: 450px) and (orientation: landscape) {
                font-size: 22px ;//Flag
              }
              @media (max-width: 450px) and (orientation: portrait) {
                font-size: 22px;
              }
            }
        }

        .portfolio-briefcase {
          margin-bottom: 48px;
          z-index: 2;
          margin-left: 12px;
          margin-right: 12px;
          grid-column: 1/2;
          width: 94%;
          grid-row: 2/3;
          }

          .briefcase-content-list {
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;          
            width: 94%;
            padding: 0;

            .filler-container {
              margin-bottom: 18px !important;

            }
            
            li {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 70px;
              margin: 20px 0;
              background-color: rgb(163, 163, 163);
              color: white;
            }

          .briefcase-content-list-item {
              width:calc(100% - 24px);
              display: flex;
              justify-content: space-between;
              align-items:center;
              height:45px;
              margin-top:1px;
              margin-bottom: 1px;

              @media (max-width: 450px) and (orientation: portrait) {
                height: 20px;
              }
          }
            
          
    
          
        }
        .briefcase-content-list::-webkit-scrollbar{
            width: 0px;
        }
        .briefcase-send-button {
            grid-row: 4/5;

            // p {

            // }
          }
      }
      
      @keyframes appear-from-left {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
    
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      @keyframes hide-to-right {
        0% {
          opacity: 1;
          transform: translateX(0%);
        }
        100% {
          position: absolute; // setting postion absolute to remove the empty sapce
          left: -100%; // since setting position absolute messes up the transform: translateX, set -100% here too
          opacity: 0;
          transform: translateX(-100%);
        }
      }
    }