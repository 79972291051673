*{
  overscroll-behavior-x: none;
  overscroll-behavior-x: contain;
}
TERMCTextdiv{
  overscroll-behavior:contain;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div{
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hoveredMark{
  transition: 0.2s all ease;
  width:100%;

  transform: translate(0%,100%);
  &:hover{
    transform: translate(0%,90%);
  } 
 }
 
.logo {
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  grid-row: 1/2;
  position: unset;
  //display: none;
  grid-column: 2/2;
  height: calc(100% - 4px)!important;

  @media (max-width: 1367px) {
    //display: none;
  }

  // .top-gradient {

  // }
}
#logo9{
  height: 96%!important;
}
.portfolio-template.theme9 {
  .portfolio-toolbar {
    z-index: 3;
    position: fixed;
    height: 70px;
    width: 70px;
    // z-index: 1;
    bottom: 4px;
    left: 2px;
    background-color: #E2DFDD;
    border: 1px solid #3b3b3b40;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: calc(100vh - 8px);

  
    &.expanded-toolbar {
      transform: translateY(0px);
      width: 400px;
      border-radius: 2px;
      box-shadow: -1px 0px 6px 0px #3b3b3b40;
      top: calc(100% * 114/1080);
      height: calc(100% * (1080 - 114)/1080);
  
      @media (max-width: 1366px) and (orientation: landscape) {
        padding-bottom: 25px;
      }
  
      @media (max-width: 450px) and (orientation: portrait) {
        width: calc(100% - 6px);
      }
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        bottom: 3px;
        padding-bottom: 0px;
      }
    }
  
    &.retracted-toolbar {
      transform: translateY(calc(100vh - 74px)) translateX(2px);
      border: none;
      border-left: 1px solid #3b3b3b40;
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        //transform: translateY(calc(100vh - 44px)) translateX(25px);
        left: unset;
        right:0px;
      }
    }
  
    .toolbar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      position: absolute;
      z-index: 1;
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        
      }
      
      svg {
        color: #3b3b3bBF;
        font-size: 36px;
        cursor: pointer;
      }
  
      &.expanded-toolbar {
        left: 5px;
        top: 5px;

  
        // @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
        //   top: auto;
        //   bottom: 5px;
        // }
  
        // @media (max-width: 1366px) and (orientation: landscape) {
        //   top: 110px;
        // }
  
        // @media (max-width: 1024px) and (orientation: portrait) {
        //   top: 140px;
        // }
  
        @media (max-width: 450px) and (orientation: portrait) {
          top: 105px;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          top: 5px;
          left: 5px;
        }
      }
  
      &.retracted-toolbar {
        left: 5px;
        top: 5px;
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 40px;
          width: 40px;
          left: 4px;
          top: 1px;
  
          svg {
            font-size: 24px;
          }
        }
      }
    }
  
    .toolbar-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      position: absolute;
      z-index: 1;
  
      p {
        color: #3b3b3bBF;
        font-family: 'Space Mono', monospace;
        font-size: 20px;
        font-size: 600;
      }
  
      &.expanded-toolbar {
        top: 5px; 
        left: 85px;
  
        // @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
        //   top: auto;
        //   bottom: 5px;
        // }
  
        // @media (max-width: 1366px) and (orientation: landscape) {
        //   top: 110px;
        // }
  
        // @media (max-width: 1024px) and (orientation: portrait) {
        //   top: 140px;
        // }
  
        @media (max-width: 450px) and (orientation: portrait) {
          top: 105px;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          top: 5px;
          left: 85px;
        }
      }
  
      &.retracted-toolbar {
        display: none;
      }
    }
  
    .icon-flex-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 70px;
      width: 100%;
      height: calc(100% - 70px);
      padding-bottom: 35px;
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 100px;
        width: 100%;
        top: calc(50% - 50px);
        padding-bottom: 0px;
        flex-direction: row;
      }
  
      .icon-and-text-parent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 4% 0 4% 0;
  
        @media (max-width: 1366px) and (orientation: landscape) {
          margin: 2% 0%;
        }
  
        @media (max-width: 1024px) and (orientation: portrait) {
          margin: 2% 0%;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          margin: 0% 2%;
        }
  
        .test-absolute-text {
          width: 100%;
          height: 30px;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
  
          @media (max-width: 1366px) and (orientation: landscape) {
            height: min-content;
          }
  
          @media (max-width: 450px) and (orientation: portrait) {
            height: min-content;
          }
          
          p {
            color: #3b3b3bBF;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            font-family: 'Space Mono', monospace;
            letter-spacing: 0.5px;
  
            @media (max-width: 1366px) and (orientation: landscape) {
              font-size: 10px;
              margin-block: 0px;
            }
  
            @media (max-width: 450px) and (orientation: portrait) {
              font-size: 10px;
              margin-block: 0px;
            }
  
            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              font-size: 9px;
            }
          }
        }
  
        .menu-icon-custom {
  
          .containDiv {
            height: 50px !important;
            width: 50px !important;
            background-color: #3b3b3bBF;
  
            @media (max-width: 1366px) and (orientation: landscape) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 1024px) and (orientation: portrait) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 450px) and (orientation: portrait) {
              height: 35px !important;
              width: 35px !important;
            }
          }
  
          img {
            height: 50px !important;
            width: 50px !important;
            // background-color: #3b3b3bBF;
            color: #3b3b3bBF;
            padding: 0% !important;
  
            @media (max-width: 1366px) and (orientation: landscape) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 1024px) and (orientation: portrait) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 450px) and (orientation: portrait) {
              height: 35px !important;
              width: 35px !important;
            }
          }
        }
      }
    }
  }
  .portfolio-toolbar2wrapper {
    pointer-events: none;
    // *{
    //   pointer-events: unset;
    // }
    z-index: 1;
    position: fixed;
    // height: 70px;
    height: calc(1080px * 568 / 1080);
    width: calc(1920px * 101.5 / 1920);
    // z-index: 1;
    bottom: 0px;
    left: 0px;
    // background-color: #E2DFDD;
    // border: 1px solid #3b3b3b40;
    //transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    //height: calc(100vh - 8px);
  
    &.expanded-toolbar {
      // mask-image: url("../../assets/images/toolbar bg_v1Asset 20.png");
      //     mask-size: 100% 100%;
      //     mask-repeat: no-repeat;
      //     -webkit-mask-image: url("../../assets/images/toolbar bg_v1Asset 20.png");
      //     -webkit-mask-repeat: no-repeat;
      //     -webkit-mask-size: 100% 100%;
      // transform: translateY(0px);
      width: calc(1920px * 101.5 / 1920);
      // border-radius: 2px;
      // box-shadow: -1px 0px 6px 0px #3b3b3b40;
      //top: calc(100% * 114/1080);
      height: calc(1080px * 568 / 1080);
      // background: rgba($color: #000000, $alpha: 0.6);
  
      @media (max-width: 1366px) and (orientation: landscape) {
        padding-bottom: 25px;
      }
  
      @media (max-width: 450px) and (orientation: portrait) {
        width: 100%;
        height: 100%;
      }
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        width: 100%;
        height: 100%;
        top: 0px;
        padding-bottom: 0px;
        -webkit-mask-image: unset;
        mask-image: unset;
        z-index: 2;
      }
    }
  
    &.retracted-toolbar {
      //transform: translateY(calc(100vh - 74px)) translateX(2px);
      border: none;
      //border-left: 1px solid #3b3b3b40;
      @keyframes WinddownRT{
        from{
          //opacity: 1;
          transform: unset
        }
        50%{
          //opacity: 1;
          transform: unset
        }
        to{
          //opacity:1;
          transform: unset;
        }
      }
      animation-name: fadeout;
      animation-duration: 2s;

      @media (max-width: 450px) and (orientation: portrait) {
        width: 100%;
        height: 100%;
      }    

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        // transform: translateY(calc(100vh - 44px)) translateX(25px);
        left: unset;
        top: 0px;
        right:0px;
        -webkit-mask-image: unset;
        mask-image: unset;
      }
    }
  
    .toolbar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 60px;
      // width: 60px;
      position: absolute;
      z-index: 1;
      width: calc(1920px * 22 / 1920);
      opacity: 0;
      transition: opacity 0.2s;
      @media (max-width: 450px) and (orientation: portrait) {
        //top: 105px;
        right: 6px !important;
        z-index: 4;
      }
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        top: 5px;
right: 0px;
z-index: 4;
      }
      
      svg {
        color: #3b3b3bBF;
        font-size: 36px;
        cursor: pointer;
      }
  
      &.expanded-toolbar {
        right: calc(-1920px * 22 / 1920);
        top: 0px;
        opacity:1;
        transition: opacity 0.2s 0.2s;
        pointer-events: auto;
        // @keyframes fadein{
        //   from{opacity: 1;}
        //   50%{
        //     opacity: 1;
        //   }
        //   to{opacity:0}
        // }
        // animation-name:fadein;
        // animation-duration: 1s;
  
        // @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
        //   top: auto;
        //   bottom: 5px;
        // }
  
        // @media (max-width: 1366px) and (orientation: landscape) {
        //   top: 110px;
        // }
  
        // @media (max-width: 1024px) and (orientation: portrait) {
        //   top: 140px;
        // }
  
        @media (max-width: 450px) and (orientation: portrait) {
          //top: 105px;
          right: 6px !important;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          top: 5px;
          right: 6px;
          z-index: 4;
        }
      }
  
      &.retracted-toolbar {
        right: calc(-1920px * 22 / 1920);
        top: 0px;
        pointer-events: none;

        @keyframes fadeout{
          from{
            display: flex;
            //opacity: 0;
          }
          50%{
            //opacity: 0;
          }
          to{
            display: none;
            //opacity:1
          }
        }
        // display: none;
        animation-name: fadeout;
        animation-duration: 1s;
        //display: none;
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          // height: 40px;
          // width: 40px;
          // left: 4px;
          // top: 1px;
          @keyframes ToolbarDownWidth {
            from{
              width: calc(100% - 6px);
              left: 0%;
              // height: calc(1080px * 568 / 1080);
              // transform: translateY(0);
              //opacity: 1;
            }
            50%{
              width: calc(1920px * 101.5 / 1920);
              left: 100%;
              // height: calc(1080px * 568 / 1080);
              // transform: translateY(0);
              //opacity: 1;
            }
            to{
              width: calc(1920px * 101.5 / 1920);
              // height: 0px;
              // //opacity: 1;            
              // transform: translateY(calc(100vh - 74px)); //translateX(2px);
            }
          }
          animation-name: ToolbarDownWidth;
          animation-duration: 0.5s;
          animation-iteration-count: infinite;
          svg {
            font-size: 24px;
          }
        }
      }
    }
    .toolbar-icon2 {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 60px;
      // width: 60px;
      position: absolute;
      z-index: 1;
      // width: calc(1920px * 68 / 1920);
      // height: calc(1920px * 68 / 1920);
      width: 48px;
      height: 48px;
      opacity: 0;
      transition: opacity 0.2s;
      @media (max-width: 450px) and (orientation: portrait) {
        //top: 105px;
        right: 12px !important;
      }
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        top: 5px;
        right: 0px;
        z-index: 4;
      }
      
      svg {
        color: #3b3b3bBF;
        font-size: 36px;
        cursor: pointer;
      }
      .DeployBackground{
        background:rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;
        .Dot{
          height: 10%;
          width: 10%;
          background-color: white;
          border-radius: 50%;
        }
      }
  
      &.expanded-toolbar {
        // right: 0px;
        // bottom: calc(100vh * ((102 - 18) / 1080));
        left: 48px;
        bottom: 48px;
        pointer-events: none;
        //display: none;

  
        // @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
        //   top: auto;
        //   bottom: 5px;
        // }
  
        // @media (max-width: 1366px) and (orientation: landscape) {
        //   top: 110px;
        // }
  
        // @media (max-width: 1024px) and (orientation: portrait) {
        //   top: 140px;
        // }
  
        @media (max-width: 450px) and (orientation: portrait) {
          //top: 105px;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          top: 5px;
          right: 0px;
          z-index: 4;
          display: none;
        }
      }
  
      &.retracted-toolbar {
        // bottom: calc(100vh * ((102 - 18) / 1080));
        // right: 0px;
        left: 48px;
        bottom: 48px;
        z-index: 4;
        opacity: 1;
        transition: opacity 0.2s 0.2s;
        pointer-events: auto;
        
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 40px;
          width: 40px;
          //left: 4px;
          // top: 1px;
          top:calc(((100vh * 129/896) - 40px)/2);
          right:"calc(100% * 16/414)";
  
          svg {
            font-size: 24px;
          }
        }
      }
    }
  }
  .portfolio-toolbar2 {
    z-index: 3;
    position: fixed;
    // height: 70px;
    width: calc(1920px * 101.5 / 1920);
    // z-index: 1;
    bottom: 0px;
    left: 0px;
    //background-color: #E2DFDD;
    //border: 1px solid #3b3b3b40;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    //height: calc(100vh - 8px);
    mask-image: url("../../assets/images/toolbar bg_v1Asset 20.png");
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    -webkit-mask-image: url("../../assets/images/toolbar bg_v1Asset 20.png");      
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    @media (max-width: 450px) and (orientation: portrait) {
      width:100% !important;
    }
    &.expanded-toolbar {
      
      transform: translateY(0px);
      width: calc(1920px * 101.5 / 1920);
      border-radius: 2px;
      box-shadow: -1px 0px 6px 0px #3b3b3b40;
      //top: calc(100% * 114/1080);
      height: calc(1080px * 568 / 1080);
      @keyframes ToolbarUp {
        from{
          height:0px;
          //opacity: 0;
        }
        75%{
          height: calc(1080px * 568 / 1080);
          //opacity: 1;
        }
        to{
          height: calc(1080px * 568 / 1080);
          //opacity: 1;            
        }
      }
      animation-name: ToolbarUp;
      animation-duration: 0.5s;
      
  
      @media (max-width: 1366px) and (orientation: landscape) {
        padding-bottom: 25px;
      }
  
      @media (max-width: 450px) and (orientation: portrait) {
        width: 100%;
        height: 100%;
        @keyframes ToolbarUp2 {
          from{
            height:0px;
            //opacity: 0;
          }
          75%{
            height: 100%;
            //opacity: 1;
          }
          to{
            height: 100%
            //opacity: 1;            
          }
        }
        animation-name: ToolbarUp2;
        animation-duration: 0.5s;
        -webkit-mask-image: unset;
        mask-image: unset;
      }
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        position: relative;
        width: 100%;
        height: 100%;
        bottom: 0px;
        padding-bottom: 0px;
        -webkit-mask-image: unset;
        mask-image: unset;
        @keyframes ToolbarUpRight {
          from{
            left:100%;
            opacity: 0;
          }
          75%{
            right: 33%;
            opacity: 1;
          }
          to{
            right: 0%;
            opacity: 1;            
          }
        }
        animation-name: ToolbarUpRight;
        animation-duration: 00.5s;
        //animation-iteration-count: infinite;
      }
    }
  
    &.retracted-toolbar {
      //transform: translateY(calc(100vh - 74px)) translateX(2px);
      border: none;
      //border-left: 1px solid #3b3b3b40;
      @keyframes ToolbarDown {
        from{
          height: calc(1080px * 568 / 1080);
          transform: translateY(0);
          //opacity: 1;
        }
        50%{
          height: calc(1080px * 568 / 1080);
          transform: translateY(0);
          //opacity: 1;
        }
        to{
          height: 0px;
          //opacity: 1;            
          transform: translateY(calc(100vh - 74px)); //translateX(2px);
        }
      }
      animation-name: ToolbarDown;
      animation-duration: 1s;
      @media (max-width: 450px) and (orientation: portrait) {
        -webkit-mask-image: unset;
        mask-image: unset;
        @keyframes ToolbarDown2 {
          from{
            height: 100%;
            transform: translateY(0);
            //opacity: 1;
          }
          50%{
            height: 100%;
            transform: translateY(0);
            //opacity: 1;
          }
          to{
            height: 0px;
            //opacity: 1;            
            transform: translateY(calc(100vh - 74px)); //translateX(2px);
          }
        }
        animation-name: ToolbarDown2;
        animation-duration: 1s;
      }
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        // transform: translateY(calc(100vh - 44px)) translateX(25px);
        //width: 0px;
        left: 100%;
        right:0px;
        height: 100%;
        -webkit-mask-image: unset;
        mask-image: unset;
        @keyframes ToolbarDownWidth {
          from{
            width:100%;
            left: 0%;
            // height: calc(1080px * 568 / 1080);
            // transform: translateY(0);
            //opacity: 1;
          }
          50%{
            width:100%;
            left: 100%;
            // height: calc(1080px * 568 / 1080);
            // transform: translateY(0);
            //opacity: 1;
          }
          to{
            width:100%;
            left: 100%;
            // height: 0px;
            // //opacity: 1;            
            // transform: translateY(calc(100vh - 74px)); //translateX(2px);
          }
        }
        animation-name: ToolbarDownWidth;
        animation-duration: 0.5s;
        // animation-iteration-count: infinite;
      }
    }
  
    .toolbar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 60px;
      // width: 60px;
      position: absolute;
      z-index: 1;
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        top: 5px;
right: 0px;
z-index: 4;
      }
      
      svg {
        color: #3b3b3bBF;
        font-size: 36px;
        cursor: pointer;
      }
  
      &.expanded-toolbar {
        right: calc(-1920px * 22 / 1920);
        top: 0px;

  
        // @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
        //   top: auto;
        //   bottom: 5px;
        // }
  
        // @media (max-width: 1366px) and (orientation: landscape) {
        //   top: 110px;
        // }
  
        // @media (max-width: 1024px) and (orientation: portrait) {
        //   top: 140px;
        // }
  
        @media (max-width: 450px) and (orientation: portrait) {
          top: 105px;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          top: 5px;
          right: 0px;
          z-index: 4;
        }
      }
  
      &.retracted-toolbar {
        left: 5px;
        top: 5px;
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 40px;
          width: 40px;
          left: 4px;
          top: 1px;
  
          svg {
            font-size: 24px;
          }
        }
      }
    }
  
    .toolbar-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      position: absolute;
      z-index: 1;
  
      p {
        color: #3b3b3bBF;
        font-family: 'Space Mono', monospace;
        font-size: 20px;
        font-size: 600;
      }
  
      // &.expanded-toolbar {
      //   top: 5px; 
      //   left: 85px;
  
      //   @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
      //     top: auto;
      //     bottom: 5px;
      //   }
  
      //   @media (max-width: 1366px) and (orientation: landscape) {
      //     top: 110px;
      //   }
  
      //   @media (max-width: 1024px) and (orientation: portrait) {
      //     top: 140px;
      //   }
  
      //   @media (max-width: 450px) and (orientation: portrait) {
      //     top: 105px;
      //   }
  
      //   @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
      //     top: 5px;
      //     left: 85px;
      //   }
      // }
  
      &.retracted-toolbar {
        display: none;
      }
    }
  
    .icon-flex-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 50px;
      width: 100%;
      height: calc(100% - 90px);
      padding-bottom: 35px;
  
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 100px;
        width: 100%;
        top: calc(50% - 50px);
        padding-bottom: 0px;
        flex-direction: row;
      }
  
      .icon-and-text-parent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 4% 0 4% 0;
  
        @media (max-width: 1366px) and (orientation: landscape) {
          margin: 2% 0%;
        }
  
        @media (max-width: 1024px) and (orientation: portrait) {
          margin: 2% 0%;
        }
  
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          margin: 0% 2%;
        }
  
        .test-absolute-text {
          width: 100%;
          height: 30px;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
  
          @media (max-width: 1366px) and (orientation: landscape) {
            height: min-content;
          }
  
          @media (max-width: 450px) and (orientation: portrait) {
            height: min-content;
          }
          
          p {
            color: #3b3b3bBF;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            font-family: 'Space Mono', monospace;
            letter-spacing: 0.5px;
  
            @media (max-width: 1366px) and (orientation: landscape) {
              font-size: 10px;
              margin-block: 0px;
            }
  
            @media (max-width: 450px) and (orientation: portrait) {
              font-size: 10px;
              margin-block: 0px;
            }
  
            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              font-size: 9px;
            }
          }
        }
  
        .menu-icon-custom {
  
          .containDiv {
            height: 50px !important;
            width: 50px !important;
            background-color: #3b3b3bBF;
  
            @media (max-width: 1366px) and (orientation: landscape) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 1024px) and (orientation: portrait) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 450px) and (orientation: portrait) {
              height: 35px !important;
              width: 35px !important;
            }
          }
  
          img {
            height: 50px !important;
            width: 50px !important;
            // background-color: #3b3b3bBF;
            color: #3b3b3bBF;
            padding: 0% !important;
  
            @media (max-width: 1366px) and (orientation: landscape) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 1024px) and (orientation: portrait) {
              height: 42px !important;
              width: 42px !important;
            }
  
            @media (max-width: 450px) and (orientation: portrait) {
              height: 35px !important;
              width: 35px !important;
            }
          }
        }
      }
    }
  }
  // .portfolio-page{
  //   display: flex;
  //   flex-direction: column;
  // }
    // override background-color in menu-template
    background-color: rgb(59, 59, 59);
    display: grid !important;
    grid-template-rows: calc(100% * 114/1080) calc(100% * (1080 - 114)/1080);
    flex-shrink: 0;
    .Wrapper{
      grid-row: 1/3;
      position: absolute;
      z-index: 2;
    }

    @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
      grid-template-rows: calc(100% * 129/896) calc(100% * (896 - 129)/896);
    }
    @media (max-width: 1367px) and (min-width: 1023px) {
      flex-direction: column;
    }

    @media (max-width: 1367px) and (min-width: 1023px) and (min-height: 1025px) {
      justify-content: space-between;
    }

    @media (max-width: 1367px) and (min-height: 1025px) {
      flex-direction: column;
      justify-content: space-between;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
      flex-direction: column;
      justify-content: space-between;
    }

    @media (max-width: 768px) and (max-height: 1024px) {
      flex-direction: column;
      justify-content: space-between;
    }

    .modal {
      @media (max-width: 1367px) {
        z-index: 6;
      }

      .modal-container {
        @media (max-width: 1367px) {
          top: 40%;
        }
      }
    }

    .menu-icon-custom {
      transition: 0.2s all ease;

      .containDiv {
        height: 70px;
        width: 70px;

        // @media (max-width: 1367px) and (min-width: 1025px) {
        //   height: 40px !important;
        //   width: 40px !important;
        // }

        // @media (max-width: 1024px) and (max-height: 1024px) {
        //   height: 35px !important;
        //   width: 35px !important;
        // }

        // @media (max-width: 1367px) and (min-height: 1025px) {
        //   height: 80px !important;
        //   width: 80px !important;
        // }

        // @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
        //   height: 70px !important;
        //   width: 70px !important;
        // }

        // @media (max-width: 768px) and (max-height: 1024px) {
        //   height: 60px !important;
        //   width: 60px !important;
        // }
      }
      
    }

    .menu-icon-custom:hover {
      transform: scale(1.15, 1.15);
    }

    .portfolio-action-menu {

      .action-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 330px;
  
        .exit-button {
          margin-top: auto;
        }
  
        .row-first {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
  
        .row-second {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
      }
    }

    .portfolio-menu-container2 {
      grid-row: 2/2;
      grid-column: 1/2;
      background-color: #3b3b3b;
      overscroll-behavior: none;
      .MM{
        display: none;
        @media  (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display:block;
          width: calc(100% * 33.5/687) !important;
          height: calc(100% * 33.5/47) !important;
          top: calc(100% * (47 - (33.5 / 0.8)) / 47) !important ;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          display:block;
        }
      }
      .MT{
        display: none;
        @media  (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display:block;
          width: calc(100% * 33.5/687) !important;
          height: calc(100% * 33.5/47) !important;
          top: calc(100% * (47 - (33.5 / 0.8)) / 47) !important ;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          display:block;
        }
        @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) {
          
          
        }
      }
    }

    .portfolio-menu-container {
      .MM{
        display: none;
        @media  (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display:block;
          width: calc(100% * 33.5/687) !important;
          height: calc(100% * 33.5/47) !important;
          top: calc(100% * (47 - (33.5 / 0.8)) / 47) !important ;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          display:block;
        }
      }
      //(max-width: 450px) and (orientation: portrait)
      .MT{
        display: none;
        @media  (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display:block;
          width: calc(100% * 33.5/687) !important;
          height: calc(100% * 33.5/47) !important;
          top: calc(100% * (47 - (33.5 / 0.8)) / 47) !important ;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          display:block;
          
        }
      }
      .colorFooter{
        background-color: #D8C5AA;
        width: 100%;
        position:absolute;
        height: calc(100% * 5/119);
        // 5px
        bottom: 0px;
        
      }
      grid-row: 1/1;
      grid-column: 1/2;
      min-width: 200px;
      grid-template-rows: none;
      max-width: none;
      grid-template-columns: calc(100% * 34/1920) calc(100% * 345/1920) 1fr;
      display: grid;
        @media (max-width: 1367px) and (min-width: 1023px) {
          min-width: 200px;
          grid-template-rows: none;
          max-width: none;
        }

        @media (max-width: 1367px) {
          max-width: none;
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          grid-template-columns: calc(100% * 65/414) 1fr calc(100% * 65/414);
          
          .logo{
            max-height: 30px;
            height: 100%;
            &.T9{
              grid-row: 1;
              grid-column: 1;
              width: 75%;
              left: calc(25%/2);
            }
          }
        }

        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          grid-template-columns: calc(100% * 65/414) 1fr calc(100% * 65/414);
          
          .logo{
            max-height: 120px;
            height: 100%;
            &.T9{
              grid-row: 1;
              grid-column: 1;
              width: 75%;
              left: calc(25%/2);
            }
          }
        }


        .menus-container {
          height: 100%;
          grid-row: 1/1;
          grid-column: 3/3;
          width: 80.2604166667vw ;
          //width: calc(100% - calc(100% * 34/1920) - calc(100% * 345/1920));
          //display: grid;
                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            display: none;
          }
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            display: flex;
            justify-content: flex-end;
            align-items: inherit;
            //height: 100px;
          }

          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            display: none;
          }
        

          // @media (max-width: 1024px) and (max-height: 1024px) {
          //   display: flex;
          //   justify-content: center;
          //   align-items: inherit;
          //   //height: 70px;
          // }

          // @media (max-width: 1367px) and (min-height: 1025px) {
          //   display: flex;
          //   justify-content: center;
          //   align-items: inherit;
          //   //height: 300px;
          // }

          .back-container {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            padding-top: 40px;
            padding-bottom: 55px;
            height: 60vh;
            max-height: 90vh;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -webkit-scrollbar {
              width: 0px;
            }

            
            @media (max-width: 1367px) {
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              mask-image: none;
              -webkit-mask-image: none;
              flex-direction: row;
              justify-content: center;
              padding-bottom: 35px;
              padding-top: 50px;
              height: auto;
              overflow-y: hidden;
              overflow-x: auto;
              //touch-action: pan-x;
              width: 100vw;
              max-width: 100vw;
              justify-content: flex-start;
            }

            @media (max-width: 1024px) and (min-width: 1366px) {
              padding-top: 20px;
            }

            @media (max-width: 1024px) and (max-height: 1024px) {
              padding-top: 5px;
              margin-top: 40px;
              //padding-bottom: 5px;
            }

            @media (max-width: 768px) and (max-height: 1024px) {
              padding-top: 25px;
              //padding-bottom: 25px;
              margin-top: 145px;
            }
            
          .back-container:hover{
            filter: brightness(115%);
              transform: scale(1.05, 1.05);
          }
      
            img {
              height: 100px;
              width: 100px;
              transition: all 0.4s;
              cursor: pointer;
            }

            .portfolio-content-menu-container {
              display: flex;
              align-items: center;
              @media (max-width: 1367px) {
                display: flex;
                align-items: center;
              }

              button {
                margin-bottom: 30px;

                @media (max-width: 1367px) and (min-width: 1025px) {
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                  height: 93px;
                  width: 200px;
                }

                @media (max-width: 1024px) and (max-height: 1024px) {
                  width: 130px;
                  height: 70px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 1024px) and (min-height: 1025px) {
                  width: 180px;
                  height: 120px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 1024px) and (min-width: 769px) {
                  margin-right: 10px;
                }

                @media (max-width: 768px) and (max-height: 1024px) {
                  width: 120px;
                  height: 130px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                div {
                  overflow: hidden;

                  p {
                    @media (max-width: 1024px) and (max-height: 1024px) {
                      font-size: 15px;
                    }

                    @media (max-width: 1024px) and (min-height: 1025px) {
                      font-size: 22px;
                    }
                  }
                }
              }

              .portfolio-content-menu {

                @media (max-width: 1367px) {
                  display: flex;
                }

                button {
                  transition: 0.5s all ease;
                  margin-bottom: 0px;

                  @media (max-width: 1367px) and (min-width: 1025px) {
                    width: 160px;
                    margin-right: 0px;
                  }

                  @media (max-width: 1024px) and (max-height: 1024px) {
                    width: 110px;
                    margin-right: 0px;
                  }

                  @media (max-width: 1024px) and (min-height: 1025px) {
                    width: 130px;
                    margin-right: 0px;
                  }

                  div {
                    overflow: hidden;

                    @media (max-width: 1367px) {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    }

                    p {
                      @media (max-width: 1367px) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: horizontal;
                        -webkit-line-clamp: 2;
                      }

                      @media (max-width: 1024px) and (max-height: 1024px) {
                        font-size: 14px !important;
                      }

                      @media (max-width: 1024px) and (min-height: 1025px) {
                        font-size: 20px !important;
                      }
                    }
                  }

                }

                button:hover {
                  filter: brightness(120%);
                }
              }
            }
      
            // img:hover {
            //   filter: brightness(115%);
            //   transform: scale(1.05, 1.05);
            // }
          }

          .portfolio-menu {
            .group{
              flex-direction: row;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0%;
            height: 100%;
            mask-image: none;
            -webkit-mask-image: none;
            min-height: 100%;
            // border-left: 2px solid #80808080;
            // border-right: 2px solid #80808080;
            // padding-right: 80px;
            // margin-top: 30px !important;
            // padding-left: 30px;
            // padding-top: 10px !important;
            overflow-y: hidden;
            overflow-x: auto;
            touch-action: pan-x;
            scrollbar-width: none;
            //width: 90%; 
            justify-self: center;
            
            -webkit-scrollbar {
              height: 0px;
            }
            // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //width: 100vw;
            //max-width: 100vw;
            // &.isAtEnd{
            //   //opacity: 0;
            // }            
            //right: 5.5%;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              width: calc(100% * (374)/(1024 - ((1024 * 34 / 1920) + (1024 * 345 / 1920))));
            }
            // @media (max-width: 1367px) and (min-width: 1025px) {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   right: 0%;
            //   height: auto;
            //   mask-image: none;
            //   -webkit-mask-image: none;
            //   // border-left: 2px solid #80808080;
            //   // border-right: 2px solid #80808080;
            //   // padding-right: 80px;
            //   // margin-top: 30px !important;
            //   //padding-left: 30px;
            //   //padding-top: 10px !important;
            //   overflow-y: hidden;
            //   overflow-x: auto;
            //   touch-action: pan-x;
            //   scrollbar-width: none;
            //   -webkit-scrollbar {
            //     height: 0px;
            //   }
            //   // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //   // width: 100vw;
            //   // max-width: 100vw;
            // }

            // @media (max-width: 1024px) {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   right: 0%;
            //   height: auto;
            //   mask-image: none;
            //   -webkit-mask-image: none;
            //   // border-left: 2px solid #80808080;
            //   // border-right: 2px solid #80808080;
            //   // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //   padding-right: 80px;
            //   padding-left: 0px;
            //   overflow-y: hidden;
            //   overflow-x: auto;
            //   touch-action: pan-x;
            //   scrollbar-width: none;
            //   -webkit-scrollbar {
            //     height: 0px;
            //   }
            //   width: 100vw;
            //   max-width: 100vw;
            //   //padding-bottom: 25px;
            //   //padding-top: 25px;
            // }

            // @media (max-width: 1367px) and (min-height: 1025px) {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   right: 0%;
            //   height: auto;
            //   mask-image: none;
            //   -webkit-mask-image: none;
            //   // border-left: 2px solid #80808080;
            //   // border-right: 2px solid #80808080;
            //   // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //   padding-right: 80px;
            //   padding-left: 0px;
            //   overflow-y: hidden;
            //   overflow-x: auto;
            //   touch-action: pan-x;
            //   scrollbar-width: none;
            //   -webkit-scrollbar {
            //     height: 0px;
            //   }
            //   width: 100vw;
            //   max-width: 100vw;
            //   //padding-bottom: 25px;
            //   //padding-top: 25px;
            // }

            // @media (max-width: 1024px) and (max-height: 1024px) {
            //   //padding-top: 5px;
            //   //padding-bottom: 5px;
            //   //margin-top: 40px !important;
            //   border-left: 0px solid #80808080;
            //   border-right: 0px solid #80808080;
            //   padding-left: 30px;
            // }

            // @media (max-width: 768px) and (max-height: 1024px) {
            //   width: 100vw;
            //   //padding-top: 25px;
            //   //margin-top: 145px !important;
            //   border-left: 0px solid #80808080;
            //   border-right: 0px solid #80808080;
            // }

            .row-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              overflow-x: scroll;
              overflow-y: hidden;
              height: 100%;
              
              gap: 4px;
              ul{
                height: 100%;


              }
                  // hide scrollbar
              ::-webkit-scrollbar {
                display: none;
              }
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */

              @media (max-width: 1367px) and (min-width: 1023px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }
              
              @media (max-width: 1367px) and (min-height: 1025px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }

              @media (max-width: 1024px) and (min-width: 769px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }
            }

            .menu-filler {

              @media (max-width: 1367px) and (min-width: 1023px) {
                display: none;
              }

              @media (max-width: 1024px) {
                display: none;
              }
            }

            .group {
              @media (max-width: 1367px) {
                flex-direction: row;
              }
            }

            .active {
              display: flex;
              visibility: visible;
              opacity: 1;
              animation: appear-from-left 0.5s forwards;
            }
      
            .hidden {
              display: none;
              animation: hide-to-right 0.5s forwards;
            }
                // all button under menu


            button {
              // add small animation
              transition: all 0.5s;
              &:hover {
                background-image: "test";
                //margin-right: 10px;
                filter: brightness(120%);
              }

              @media (max-width: 1367px) {
                &:hover {
                  margin-right: 0px;
                  filter: none;
                  // box-shadow: 3px 3px 5px -3px #3b3b3b;
                }
              }

              .title-container {
                overflow: hidden;

                @media (max-width: 1367px) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                }

                // p {
                //   // @media (max-width: 1367px) {
                //   //   overflow: hidden;
                //   //   text-overflow: ellipsis;
                //   //   display: -webkit-box;
                //   //   -webkit-box-orient: horizontal;
                //   //   -webkit-line-clamp: 2;
                //   // }

                //   // @media (max-width: 1024px) and (max-height: 1024px) {
                //   //   font-size: 13px !important;
                //   // }

                //   // @media (max-width: 1024px) and (min-height: 1025px) {
                //   //   font-size: 23px !important;
                //   // }

                //   // @media (max-width: 768px) and (max-height: 1024px) {
                //   //   font-size: 16px !important;
                //   // }
                // }

              }
            }

            button.level-0 {
              width: 150px;
                border-radius: 8px;
                @media (max-width: 1367px) {
                  height: 93px;
                }
                @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){
                  height: 93px;
                } 
                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  height: 93px;
                }
            //   @media (max-width: 1367px) and (min-width: 1025px) {
            //     width: 150px;
            //     border-radius: 8px;
            //   }

            //   @media (max-width: 1024px) and (max-height: 1024px) {
            //     width: 120px;
            //     border-radius: 8px;
            //     height: 70px;
            //   }

            //   @media (max-width: 1024px) and (min-height: 1025px) {
            //     width: 200px;
            //     border-radius: 8px;
            //     height: 150px;
            //   }

            //   @media (max-width: 768px) and (max-height: 1024px) {
            //     width: 120px;
            //     border-radius: 8px;
            //     height: 130px;
            //   }
            // }
            }

            button.level-1 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 130px;
                height: 85px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 110px;
                height: 60px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 150px;
                border-radius: 8px;
                height: 120px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 100px;
                border-radius: 8px;
                height: 110px;
              }
            }

            button.level-2 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 110px;
                height: 78px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 90px;
                height: 52px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 135px;
                border-radius: 8px;
                height: 105px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 90px;
                border-radius: 8px;
                height: 100px;
              }
            }

            button.level-3 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 90px;
                height: 70px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 70px;
                height: 45px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 120px;
                border-radius: 8px;
                height: 95px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 80px;
                border-radius: 8px;
                height: 90px;
              }
            }
          
            

        }
        }
        .menus-container2 {
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          //width: calc(100% - calc(100% * 34/1920) - calc(100% * 345/1920));
          //display: grid;
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            display: flex;
            justify-content: flex-end;
            align-items: inherit;
            //height: 100px;
          }
          
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            // display: none;
          }
          &.Show{
            display: block; 
            transform: translateX(0%);
           }
        transform: translateX(-100%);
        transition: all 250ms ease 0s;

          // @media (max-width: 1024px) and (max-height: 1024px) {
          //   display: flex;
          //   justify-content: center;
          //   align-items: inherit;
          //   //height: 70px;
          // }

          // @media (max-width: 1367px) and (min-height: 1025px) {
          //   display: flex;
          //   justify-content: center;
          //   align-items: inherit;
          //   //height: 300px;
          // }

          .back-container {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            padding-top: 40px;
            padding-bottom: 55px;
            height: 60vh;
            max-height: 90vh;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -webkit-scrollbar {
              width: 0px;
            }

            
            @media (max-width: 1367px) {
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              mask-image: none;
              -webkit-mask-image: none;
              flex-direction: row;
              justify-content: center;
              padding-bottom: 35px;
              padding-top: 50px;
              height: auto;
              overflow-y: hidden;
              overflow-x: auto;
              //touch-action: pan-x;
              width: 100vw;
              max-width: 100vw;
              justify-content: flex-start;
            }

            @media (max-width: 1024px) and (min-width: 1366px) {
              padding-top: 20px;
            }

            @media (max-width: 1024px) and (max-height: 1024px) {
              padding-top: 5px;
              margin-top: 40px;
              //padding-bottom: 5px;
            }

            @media (max-width: 768px) and (max-height: 1024px) {
              padding-top: 25px;
              //padding-bottom: 25px;
              margin-top: 145px;
            }
            
          .back-container:hover{
            filter: brightness(115%);
              transform: scale(1.05, 1.05);
          }
      
            img {
              height: 100px;
              width: 100px;
              transition: all 0.4s;
              cursor: pointer;
            }

            .portfolio-content-menu-container {
              display: flex;
              align-items: center;
              @media (max-width: 1367px) {
                display: flex;
                align-items: center;
              }

              button {
                margin-bottom: 30px;

                @media (max-width: 1367px) and (min-width: 1025px) {
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                  height: 93px;
                  width: 200px;
                }

                @media (max-width: 1024px) and (max-height: 1024px) {
                  width: 130px;
                  height: 70px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 1024px) and (min-height: 1025px) {
                  width: 180px;
                  height: 120px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 1024px) and (min-width: 769px) {
                  margin-right: 10px;
                }

                @media (max-width: 768px) and (max-height: 1024px) {
                  width: 120px;
                  height: 130px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                div {
                  overflow: hidden;

                  p {
                    @media (max-width: 1024px) and (max-height: 1024px) {
                      font-size: 15px;
                    }

                    @media (max-width: 1024px) and (min-height: 1025px) {
                      font-size: 22px;
                    }
                  }
                }
              }

              .portfolio-content-menu {

                @media (max-width: 1367px) {
                  display: flex;
                }

                button {
                  transition: 0.5s all ease;
                  margin-bottom: 0px;

                  @media (max-width: 1367px) and (min-width: 1025px) {
                    width: 160px;
                    margin-right: 0px;
                  }

                  @media (max-width: 1024px) and (max-height: 1024px) {
                    width: 110px;
                    margin-right: 0px;
                  }

                  @media (max-width: 1024px) and (min-height: 1025px) {
                    width: 130px;
                    margin-right: 0px;
                  }

                  div {
                    overflow: hidden;

                    @media (max-width: 1367px) {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    }

                    p {
                      @media (max-width: 1367px) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: horizontal;
                        -webkit-line-clamp: 2;
                      }

                      @media (max-width: 1024px) and (max-height: 1024px) {
                        font-size: 14px !important;
                      }

                      @media (max-width: 1024px) and (min-height: 1025px) {
                        font-size: 20px !important;
                      }
                    }
                  }

                }

                button:hover {
                  filter: brightness(120%);
                }
              }
            }
      
            // img:hover {
            //   filter: brightness(115%);
            //   transform: scale(1.05, 1.05);
            // }
          }

          .portfolio-menu {
            .group{
              flex-direction: row;
            }
            
            
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0%;
            height: 100%;
            mask-image: none;
            -webkit-mask-image: none;
            min-height: 100%;
            // border-left: 2px solid #80808080;
            // border-right: 2px solid #80808080;
            // padding-right: 80px;
            // margin-top: 30px !important;
            // padding-left: 30px;
            // padding-top: 10px !important;
            overflow-y: hidden;
            overflow-x: auto;
            touch-action: pan-x;
            scrollbar-width: none;
            //width: 90%; 
            justify-self: center;
            
            -webkit-scrollbar {
              height: 0px;
            }
            // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //width: 100vw;
            //max-width: 100vw;
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
              display:grid;
              grid-template-rows: 100px calc(100vh - 100px);
              grid-template-columns: 1fr;
              z-index: 12;
              width: 100% !important;
            }          
            .Exit{
              position: absolute;
              right: calc(100% * 19/894);
              top: calc(100% * 15/894);
              height: calc(100% * 24/894);
              width: auto;
              @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                height: calc(100% * 96/894);
                
              }
            }
            // &.isAtEnd{
            //   //opacity: 0;
            // }            
            //right: 5.5%;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              width: calc(100% * (374)/(1024 - ((1024 * 34 / 1920) + (1024 * 345 / 1920))));
            }
            // @media (max-width: 1367px) and (min-width: 1025px) {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   right: 0%;
            //   height: auto;
            //   mask-image: none;
            //   -webkit-mask-image: none;
            //   // border-left: 2px solid #80808080;
            //   // border-right: 2px solid #80808080;
            //   // padding-right: 80px;
            //   // margin-top: 30px !important;
            //   //padding-left: 30px;
            //   //padding-top: 10px !important;
            //   overflow-y: hidden;
            //   overflow-x: auto;
            //   touch-action: pan-x;
            //   scrollbar-width: none;
            //   -webkit-scrollbar {
            //     height: 0px;
            //   }
            //   // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //   // width: 100vw;
            //   // max-width: 100vw;
            // }

            // @media (max-width: 1024px) {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   right: 0%;
            //   height: auto;
            //   mask-image: none;
            //   -webkit-mask-image: none;
            //   // border-left: 2px solid #80808080;
            //   // border-right: 2px solid #80808080;
            //   // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //   padding-right: 80px;
            //   padding-left: 0px;
            //   overflow-y: hidden;
            //   overflow-x: auto;
            //   touch-action: pan-x;
            //   scrollbar-width: none;
            //   -webkit-scrollbar {
            //     height: 0px;
            //   }
            //   width: 100vw;
            //   max-width: 100vw;
            //   //padding-bottom: 25px;
            //   //padding-top: 25px;
            // }

            // @media (max-width: 1367px) and (min-height: 1025px) {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   right: 0%;
            //   height: auto;
            //   mask-image: none;
            //   -webkit-mask-image: none;
            //   // border-left: 2px solid #80808080;
            //   // border-right: 2px solid #80808080;
            //   // box-shadow: -1px -1px 21px -8px #3b3b3b;
            //   padding-right: 80px;
            //   padding-left: 0px;
            //   overflow-y: hidden;
            //   overflow-x: auto;
            //   touch-action: pan-x;
            //   scrollbar-width: none;
            //   -webkit-scrollbar {
            //     height: 0px;
            //   }
            //   width: 100vw;
            //   max-width: 100vw;
            //   //padding-bottom: 25px;
            //   //padding-top: 25px;
            // }

            // @media (max-width: 1024px) and (max-height: 1024px) {
            //   //padding-top: 5px;
            //   //padding-bottom: 5px;
            //   //margin-top: 40px !important;
            //   border-left: 0px solid #80808080;
            //   border-right: 0px solid #80808080;
            //   padding-left: 30px;
            // }

            // @media (max-width: 768px) and (max-height: 1024px) {
            //   width: 100vw;
            //   //padding-top: 25px;
            //   //margin-top: 145px !important;
            //   border-left: 0px solid #80808080;
            //   border-right: 0px solid #80808080;
            // }

            .row-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              overflow-x: scroll;
              overflow-y: hidden;
              height: 100%;
              
              
              gap: 4px;
              ul{
                height: 100%;
                @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  overflow: overlay;
                  overflow: clip;
                  pointer-events: none;
                  grid-row: 2;
                  max-height: 96px;
                  justify-content: center;
                }

              }
                  // hide scrollbar
              ::-webkit-scrollbar {
                display: none;
              }
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */

              // @media (max-width: 1367px) and (min-width: 1023px) {
              //   display: flex;
              //   align-items: center;
              //   flex-direction: row;
              // }
              
              // @media (max-width: 1367px) and (min-height: 1025px) {
              //   display: flex;
              //   align-items: center;
              //   flex-direction: row;
              // }

              // @media (max-width: 1024px) and (min-width: 769px) {
              //   display: flex;
              //   align-items: center;
              //   flex-direction: row;
              // }

              // @media (max-width: 768px) and (max-height: 1024px) {
              //   display: flex;
              //   align-items: center;
              //   flex-direction: row;
              // }
              
              @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
                overflow-x: hidden;
              overflow-y: scroll;
              flex-direction: column;
              grid-row: 2;
              display: block;
              }
            }

            .menu-filler {

              @media (max-width: 1367px) and (min-width: 1023px) {
                display: none;
              }

              @media (max-width: 1024px) {
                display: none;
              }
            }

            .group {
              @media (max-width: 1367px) {
                flex-direction: row;
              }
            }

            .active {
              display: flex;
              visibility: visible;
              opacity: 1;
              animation: appear-from-left 0.5s forwards;
            }
      
            .hidden {
              display: none;
              animation: hide-to-right 0.5s forwards;
            }
                // all button under menu


            button {
              // add small animation
              transition: all 0.5s;
              &:hover {
                background-image: "test";
                //margin-right: 10px;
                filter: brightness(120%);
              }

              @media (max-width: 1367px) {
                &:hover {
                  margin-right: 0px;
                  filter: none;
                  // box-shadow: 3px 3px 5px -3px #3b3b3b;
                }
              }

              .title-container {
                overflow: hidden;

                @media (max-width: 1367px) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                }

                // p {
                //   // @media (max-width: 1367px) {
                //   //   overflow: hidden;
                //   //   text-overflow: ellipsis;
                //   //   display: -webkit-box;
                //   //   -webkit-box-orient: horizontal;
                //   //   -webkit-line-clamp: 2;
                //   // }

                //   // @media (max-width: 1024px) and (max-height: 1024px) {
                //   //   font-size: 13px !important;
                //   // }

                //   // @media (max-width: 1024px) and (min-height: 1025px) {
                //   //   font-size: 23px !important;
                //   // }

                //   // @media (max-width: 768px) and (max-height: 1024px) {
                //   //   font-size: 16px !important;
                //   // }
                // }

              }
            }

            // button.level-0 {
            //   // width: 150px;
            //   //   border-radius: 8px;
            //   // @media (max-width: 1367px) and (min-width: 1025px) {
            //   //   width: 150px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (max-height: 1024px) {
            //   //   width: 120px;
            //   //   border-radius: 8px;
            //   //   height: 70px;
            //   // }

            //   // @media (max-width: 1024px) and (min-height: 1025px) {
            //   //   width: 200px;
            //   //   border-radius: 8px;
            //   //   height: 150px;
            //   // }

            //   // @media (max-width: 768px) and (max-height: 1024px) {
            //   //   width: 120px;
            //   //   border-radius: 8px;
            //   //   height: 130px;
            //   // }
            // }

            // button.level-1 {
            //   // @media (max-width: 1367px) and (min-width: 1025px) {
            //   //   width: 130px;
            //   //   height: 85px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (max-height: 1024px) {
            //   //   width: 110px;
            //   //   height: 60px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (min-height: 1025px) {
            //   //   width: 150px;
            //   //   border-radius: 8px;
            //   //   height: 120px;
            //   // }

            //   // @media (max-width: 768px) and (max-height: 1024px) {
            //   //   width: 100px;
            //   //   border-radius: 8px;
            //   //   height: 110px;
            //   // }
            // }

            // button.level-2 {
            //   // @media (max-width: 1367px) and (min-width: 1025px) {
            //   //   width: 110px;
            //   //   height: 78px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (max-height: 1024px) {
            //   //   width: 90px;
            //   //   height: 52px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (min-height: 1025px) {
            //   //   width: 135px;
            //   //   border-radius: 8px;
            //   //   height: 105px;
            //   // }

            //   // @media (max-width: 768px) and (max-height: 1024px) {
            //   //   width: 90px;
            //   //   border-radius: 8px;
            //   //   height: 100px;
            //   // }
            // }

            // button.level-3 {
            //   // @media (max-width: 1367px) and (min-width: 1025px) {
            //   //   width: 90px;
            //   //   height: 70px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (max-height: 1024px) {
            //   //   width: 70px;
            //   //   height: 45px;
            //   //   border-radius: 8px;
            //   // }

            //   // @media (max-width: 1024px) and (min-height: 1025px) {
            //   //   width: 120px;
            //   //   border-radius: 8px;
            //   //   height: 95px;
            //   // }

            //   // @media (max-width: 768px) and (max-height: 1024px) {
            //   //   width: 80px;
            //   //   border-radius: 8px;
            //   //   height: 90px;
            //   // }
            // }
          
            

        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          
          height: 100vh;
          width: 100%;
          z-index: 3;
          // background: rgba(200,200,200,0.7);
          background-color: #eee;
        }
        }

    
    
        // change all button color under menu red
        button {
            margin-top: 0px;

            // @media (max-width: 1367px) and (min-width: 1023px) {
            //   margin-top: 0px;
            //   margin-left: 8px;
            // }

            // @media (max-width: 1024px) and (min-width: 769px) {
            //   margin-left: 8px;
            // }

            // @media (max-width: 768px) and (max-height: 1024px) {
            //   margin-top: 0px;
            //   margin-left: 8px;
            // }
        }

        
    }
  
    .portfolio-content-parent {
      // change all button color under portfolio-content-parent yellow
      grid-row: 1/3;
      grid-column: 1/2;
      margin: 0px;
      display: none;
      // @media (max-width: 1367px) and (min-width: 1025px) {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   margin-top: 20px;
      //   margin-bottom: 0px;
      // }

      // @media (max-width: 768px) and (max-height: 1024px) {
      //   margin-top: 0px;
      // }

      // @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
      //   // margin-bottom: 200px;
      //   margin-top: 0px;
      // }

      // @media (max-width: 1367px) and (min-width: 1023px) and (min-height: 1025px) {
      //   // margin-bottom: 300px;
      //   margin-top: 0px;
      // }
      // @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){

      // }

      button {
        background-color: rgb(55, 128, 52);
        margin-top: 15px;
      }

      .containDiv {
        @media (max-width: 1367px) and (min-width: 1023px) {
          //display: none;
        }
      }

      .portfolio-content-container {
        // @media (max-width: 1367px) and (min-width: 1025px) {
        //   height: 500px
        // }

        // @media (max-width: 1024px) and (max-height: 1024px) {
        //   height: 500px;
        // }

        // @media (max-width: 1024px) and (min-width: 769px) {
        //   height: 500px;
        // }

        // @media (max-width: 1367px) and (min-height: 1025px) {
        //   height: 600px;
        // }
        

        .portfolio-content {

          .portfolio-gallery-view {
            position: relative;

            @media (max-width: 1367px) {
              position: relative;
            }

            .hovering-chevron {
              display: block;
              position: absolute;
              z-index: 4;
              border-radius: 50%;
              background-color: #3b3b3b80;
              top: 45%;

              @media (max-width: 1367px) {
                display: block;
                position: absolute;
                z-index: 4;
                border-radius: 50%;
                background-color: #3b3b3b80;
                top: 50%;
              }

              .menu-icon-custom {

                .containDiv {
                  @media (max-width: 1367px) {
                    display: block;
                  }
                }
              }
            }

            .carousel-root {
              height: 90% !important;
              flex-shrink: 0;
              width: 100%;
              @media (max-width: 1367px) {
                height: 100% !important;
              }

              .containDiv {
                @media (max-width: 1367px) and (min-width: 1023px) {
                  display: block;
                  height: 35px !important;
                  width: 35px !important;
                }

                @media (max-width: 768px) and (max-height: 1024px) {
                  height: 35px !important;
                  width: 35px !important;
                }
              }
            }

            .thumb-container {
              display: none !important;

              @media (max-width: 1367px) {
                display: none !important;
              }
            }

            .page-count-container {
              @media (max-width: 1367px) {
                display: none !important;
              }
            }

          }
        }
      }

    }
    .portfolio-scriptnote-container{
      display: none;
    }

    .portfolio-notepad-container {
      display: none;
      transition: all 0s;
      border-left-width: 4px !important;
      box-shadow: 0px 3px 12px 2px #3b3b3b;
      width: 400px;
      height: 700px;
      top: calc(50% - 350px);
      right: calc(50% - 200px);
      border-radius: 12px;
      z-index: 5;

      @media (max-width: 1367px) {
        transition: all 0s;
        border-left-width: 4px !important;
        box-shadow: 0px 3px 12px 2px #3b3b3b;
        width: 400px;
        height: 550px;
        top: calc(50% - 300px);
        right: calc(50% - 200px);
        border-radius: 12px;
        z-index: 5;
      }

      &.active {
        visibility: visible;

        @media (max-width: 1367px) {
          visibility: visible;
        }
      }

      &.hidden {
        transform: translateX(0%);
        visibility: hidden;

        @media (max-width: 1367px) {
          transform: translateX(0%);
          visibility: hidden;
        }
      }

      &.hide {
        transform: translateX(0%);
        visibility: hidden;

        @media (max-width: 1367px) {
          transform: translateX(0%);
          visibility: hidden;
        }
      }

      .notepad-close-button {
        height: 70px !important;
        width: 70px !important;
        margin: 10px 10px 0px !important;

        @media (max-width: 1367px) {
          height: 60px !important;
          width: 60px !important;
          margin: 8px 8px 0px !important;
        }
      }

      .textarea-container {
        margin-top: 0px !important;
        font-family: 'Newsreader', serif;

        @media (max-width: 1367px) {
          margin-top: 0px !important;
          font-family: 'Newsreader', serif;
        }

        .notes-title {
          margin-bottom: 15px !important;

          @media (max-width: 1367px) {
            margin-bottom: 15px !important;
          }
        }

        .saved-notification {
          top: 53% !important;
          box-shadow: 1px 1px 2px 1px #808080;
          font-size: 17px !important;
          height: 34px !important;
          width: 115px !important;
          left: calc(50% - 69px) !important;
          letter-spacing: 0.05em;

          @media (max-width: 1367px) {
            top: 53% !important;
            box-shadow: 1px 1px 2px 1px #808080;
            font-size: 17px !important;
            height: 34px !important;
            width: 115px !important;
            left: calc(50% - 69px) !important;
            letter-spacing: 0.05em;
          }
        }

        .copied-notification {
          top: 53% !important;
          box-shadow: 1px 1px 2px 1px #808080;
          font-size: 17px !important;
          height: 34px !important;
          width: 140px !important;
          left: calc(50% - 77px) !important;
          letter-spacing: 0.05em;

          @media (max-width: 1367px) {
            top: 53% !important;
            box-shadow: 1px 1px 2px 1px #808080;
            font-size: 17px !important;
            height: 34px !important;
            width: 140px !important;
            left: calc(50% - 77px) !important;
            letter-spacing: 0.05em;
          }
        }
      }

      .notepad-button-container {
        margin-bottom: 20px;

        @media (max-width: 1367px) {
          margin-bottom: 20px;
        }

        button {
          border-radius: 8px;

          @media (max-width: 1367px) {
            border-radius: 8px;
          }

          p {
            font-weight: 600;

            @media (max-width: 1367px) {
              font-weight: 600;
            }
          }
        }
      }

    }

    .portfolio-briefcase-container {
        position: fixed;
        grid-template-rows: 1fr 9fr 1.25fr 50px;
        display: grid;
        right: 0;
        width: 400px;
        height: 700px;
        background-color: rgb(68, 68, 68);
        border-width: 4px;
        border-left-width: 4px;
        border-color: #b0b0b0;//PortSett
        border-style: solid;
        transition: all 0s;
        overflow: hidden;
        // transform: translateX(100%);
        box-shadow: 0px 3px 12px 2px #3b3b3b;
        border-radius: 12px;
        top: calc(50% - 350px);
        right: calc(50% - 200px);

        @media (max-width: 1367px) {
          transition: all 0s;
          border-left-width: 4px;
          box-shadow: 0px 3px 12px 2px #3b3b3b;
          width: 400px;
          height: 550px;
          top: calc(50% - 300px);
          right: calc(50% - 200px);
          border-radius: 12px;
        }
    
        &.active {
          // transform: translateX(0);
          visibility: visible;

          @media (max-width: 1367px) {
            visibility: visible;
          }
        }
    
        &.hidden {
          // transform: translateX(100%);
          transform: translateX(0%);
          visibility: hidden;

          @media (max-width: 1367px) {
            transform: translateX(0%);
            visibility: hidden;
          }
        }

        .briefcase-action-button-container {

          button {

            p {
              font-weight: 600;
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 15px;

              @media (max-width: 1367px) {
                font-weight: 600;
                margin-block-start: 0px;
                margin-block-end: 0px;
                font-size: 15px;
              }
            }
          }
        }

        .portfolio-briefcase-label{
            grid-row: 1/2;
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            flex-basis: 12vw;
            z-index: 1;

            div {
              height: 70px !important;
              width: 70px !important;
              margin: 10px 10px 0px !important;

              @media (max-width: 1367px) {
                height: 60px !important;
                width: 60px !important;
                margin: 8px 8px 0px !important;
              }
            }

            p {
              margin: 0px 0px 6px 23px !important;

              @media (max-width: 1367px) {
                margin: 0px 0px 6px 23px !important;
              }
            }
        }

        .portfolio-briefcase {
          margin-bottom: 48px;
          z-index: 2;
          margin-left: 12px;
          margin-right: 12px;
          grid-column: 1/2;
          width: 94%;
          grid-row: 2/3;            
    

          }

          .briefcase-content-list {
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;          
            width: 94%;
            padding: 0;

            @media (max-width: 1367px) {
              -webkit-mask-image: none !important;
              mask-image: none;
              scrollbar-width: auto;
            }

            .filler-container {
              margin-bottom: 18px !important;

              @media (max-width: 1367px) {
                margin-bottom: 12px !important;
              }
            }
            
            li {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 70px;
              margin: 20px 0;
              background-color: rgb(163, 163, 163);
              color: white;
            }
          .briefcase-content-list-item {
            width:calc(100% - 24px);
            display: flex;
            justify-content: space-between;
            align-items:center;
            height:45px;
            margin-top:1px;
            margin-bottom:1px
            }
            
          
    
          
        }
        .briefcase-content-list::-webkit-scrollbar{
            width: 0px;
        }
        .briefcase-send-button {
            grid-row: 4/5;

            p {
              @media (max-width: 1367px) {
                font-weight: 600;
                margin-block-start: 0px;
                margin-block-end: 0px;
              }
            }
          }
      }
      
      @keyframes appear-from-left {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
    
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      @keyframes hide-to-right {
        0% {
          opacity: 1;
          transform: translateX(0%);
        }
        100% {
          position: absolute; // setting postion absolute to remove the empty sapce
          left: -100%; // since setting position absolute messes up the transform: translateX, set -100% here too
          opacity: 0;
          transform: translateX(-100%);
        }
      }
    }
    .ActionBack{
      position:absolute;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,0.4);
      z-index: 10;
      
      .ActionMenu{
        height: calc(100% * 842/1080);
        // width: calc(100% * 654/1920);
        width: 200px;
        &.Notepad{
          width: calc(100% * 654/1920);
        }
        &.Briefcase{
          width: calc(100% * 654/1920);
        }
        &.PII{
          width: calc(100% * 654/1920);
        }
        

        position: relative;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          height: calc(100% * 574/768);
          width: calc(100% * 445/1024);
          &.Notepad{
            width: calc(100% * 445/1024);
          }
          &.Briefcase{
            width: calc(100% * 445/1024);
          }
          &.PII{
            width: calc(100% * 445/1024);
          }
        }
        @media (max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){
          height: auto;
          width: 60%;
          &.Notepad{
            height: calc(100% * 574/768);
            width: 60%;
          }
          &.Briefcase{
            height: calc(100% * 574/768);
            width: 60%;
          }
          &.PII{
            height: calc(100% * 574/768);
            width: 60%;
          }
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          height: 100%;
          width: 100%;
          &.Notepad{
            width: 100%;
          }
          &.Briefcase{
            width: 100%;
          }
          &.PII{
            width: 100%;
          }
        }
        .HeadBar{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background-color: #fff;
          .closeButton{
            max-height: 25px;
            max-width: 25px;
            position: absolute;
            right: 10px;
            // position: absolute;
            // height: calc(100% * 32/842);
            // width: calc(100% * 32/654);
            // top: calc(100% * 10/842);
            // right: calc(100% * 20/654);
            // @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            //   height: 32px;
            // width: 32px;
            // }
            // @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
            //   height: calc(100% * 32/842);
            // width: calc(100% * 32/441);
            // }
          }
          .BackButton{
            position: absolute;
            height: calc(100% * 25/842);
            width: calc(100% * 12/654);
            // top: calc(100% * 22/842);
            left: calc(100% * 20/654);
            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              height: 32px;
            width: 32px;
            }
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
              width: calc(100% * 12/414);
            }
            
          }
          .Title{
            font-size: inherit;
            font-weight: 500;
            line-height: 116.4%;
            letter-spacing: -0.14px;
            color: #333;
            text-align: center;
            width: 100%;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              font-size: 24px;
              margin-block: 13px;
            }
          }
          .Subtitle{
            font-size: 12px;
            font-weight: 500;
            line-height: 116.4%;
            letter-spacing: -0.14px;
            color: #333;
            text-align: center;
            width: 100%;
          }
        }
        .Options.Notepad{
          justify-content: center;
        }
        .Options.Briefcase{
          .briefcase-content-list{
            ::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          .briefcase-action-button-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: space-between;
            width: 100%;
            background-color: transparent !important;
            .T9B{
              .title-container{
                .title{
                  color: #166DC0;
                  font-size: 24px;
                  letter-spacing: -0.14px;
                  line-height: 116.4%;
                }
              }
              
              &:hover{
                text-decoration: underline;
                color: #166DC0;

              }
            }
            
          }
        }
        .Options{
          background-color: #eee;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          //overflow-y: scroll;
          //max-height: 40%;
          // flex-wrap: wrap;
          &.Icons{
            //max-height: 282px ;
            
          }

          //height: calc(100% * 744/842);
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: calc(100% - 54px);  
            justify-content: unset;
            flex-wrap: nowrap;
            overflow-y: scroll;
            
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){ // Primary Mobile view in portrait orientation @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: calc(100% - 114px);
          }
          ::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          p{
            margin-block: 0px;
          }
          //Briefcase
          .portfolio-briefcase {
            margin-bottom: 48px;
            z-index: 2;
            margin-left: 12px;
            margin-right: 12px;
            grid-column: 1/2;
            width: calc(100% - 37*2px);
            grid-row: 2/3;            
      
  
            }
            .TextContainer{
              width: calc(100% - 37*2px);
              flex-direction: row;
              justify-content: space-between;
              p{
                font-size: 18px;
                font-weight: 500;
              }
              @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
                flex-direction: column !important;
              }
            }
  
            .briefcase-content-list {
              overflow-y: scroll;
              display: flex;
              flex-direction: column;
              align-items: center;          
              width: calc(100% - 37*2px);
              height: 100%;
              padding: 0;
  
              @media (max-width: 1367px) {
                -webkit-mask-image: none !important;
                mask-image: none;
                scrollbar-width: auto;
              }
              @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
                height:68%;
                margin-bottom: 0px;
              }
  
              .filler-container {
                margin-bottom: 18px !important;
  
                @media (max-width: 1367px) {
                  margin-bottom: 12px !important;
                }
              }
              
              li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 70px;
                margin: 20px 0;
                background-color: rgb(163, 163, 163);
                color: white;
              }
            .briefcase-content-list-item {
              width:calc(100% - 24px);
              display: flex;
              justify-content: space-between;
              align-items:center;
              height:45px;
              margin-top:1px;
              margin-bottom:1px
              }
              
            
      
            
          }
          .briefcase-content-list::-webkit-scrollbar{
              width: 0px;
          }
          .briefcase-send-button {
              grid-row: 4/5;
  
              p {
                @media (max-width: 1367px) {
                  font-weight: 600;
                  margin-block-start: 0px;
                  margin-block-end: 0px;
                }
              }
            }
          //EndBriefcase
          //NotePad
          .textarea-container {
            margin-top: 0px !important;
            font-family: 'Newsreader', serif;
            width: 100%;
            &:first-child {
              width: calc(100% - 37*2px);
              // height: calc(854px * 613/854) !important;
              height: 100%;
              // .quill{
              //   .ql-toolbar{
                  
              //   }
              // }
            }
            @media (max-width: 1367px) {
              margin-top: 0px !important;
              font-family: 'Newsreader', serif;
            }
    
            .notes-title {
              margin-bottom: 15px !important;
    
              @media (max-width: 1367px) {
                margin-bottom: 15px !important;
              }
            }
    
            .saved-notification {
              top: 53% !important;
              box-shadow: 1px 1px 2px 1px #808080;
              font-size: 17px !important;
              height: 34px !important;
              width: 115px !important;
              left: calc(50% - 69px) !important;
              letter-spacing: 0.05em;
    
              @media (max-width: 1367px) {
                top: 53% !important;
                box-shadow: 1px 1px 2px 1px #808080;
                font-size: 17px !important;
                height: 34px !important;
                width: 115px !important;
                left: calc(50% - 69px) !important;
                letter-spacing: 0.05em;
              }
            }
    
            .copied-notification {
              top: 53% !important;
              box-shadow: 1px 1px 2px 1px #808080;
              font-size: 17px !important;
              height: 34px !important;
              width: 140px !important;
              left: calc(50% - 77px) !important;
              letter-spacing: 0.05em;
    
              @media (max-width: 1367px) {
                top: 53% !important;
                box-shadow: 1px 1px 2px 1px #808080;
                font-size: 17px !important;
                height: 34px !important;
                width: 140px !important;
                left: calc(50% - 77px) !important;
                letter-spacing: 0.05em;
              }
            }
          }
    
          .notepad-button-container {
            display: flex;
            margin-bottom: 0px !important;
            height: 100%;
            align-items: center;
            justify-content: center;
            width: 100%;
            @media (max-width: 1367px) {
              //margin-bottom: 20px;
            }
    
            button {
              border-radius: 8px;
    
              @media (max-width: 1367px) {
                border-radius: 8px;
              }
    
              p {
                font-weight: 600;
    
                @media (max-width: 1367px) {
                  font-weight: 600;
                }
              }
            }
          }
          //EndNotepad          
      }
      //Script
      .Options.Script{
        .textarea-container{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        hr{
          width: 100%;
        }
        .Body{
          align-self: flex-start;
        }
      }

    }
    }
    .TerminalMenu{//plan, move Toolbar to top row, increase column count for content area.
      grid-template-columns: calc(100% * 250/1920) calc(100% * 1550/1920) calc(100% * 120/1920);
      position: absolute;
      top: 0px;
      left: 0px;
      // grid-template-rows: calc(100% * 57/957) calc(100% * 784/957) calc(100% * 116/957);
      grid-template-rows: calc(100% * 57/957) calc(100% * 810/957) calc(100% * 90/957);
      background-color: #333333;
      display: grid;
      z-index: 2;
      // height: calc(100% * (1080 - 114)/1080);
      height: 100%;
      grid-row: 2/2;
      width: 100%;
      .SubpageBlockContentWrapper{
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          //display:none;
          grid-row: 4;
          overflow-y: scroll;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          //display:none;
          grid-row: 4;
          overflow-y: scroll;
        }
      }
      .Exit{
        display: none;
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          display: block;
          position: absolute;
          // right: 0px;
          right: calc(100% * 19/414);
          top: calc(100% * 16/767);
          height: 24px;
          width: 24px;
          
        }
      };
      // @media (min-width:1000px) and (min-height:1700px) {
      //   grid-template-rows: calc(100% * 96/1920) calc(100% * 96/1920) calc(100% * 1638/1920) calc(100% * 90/1920);
      // }
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        grid-template-columns: calc(100% * 112/1024) calc(100% * 835/1024) calc(100% * 77/1024);
        grid-template-rows: calc(100% * 90/697) calc(100% * 423/697) calc(100% * 184/697);
      }
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        grid-template-columns: 1fr;
        grid-template-rows: 40px calc(100% - 90px) 50px ;
      }
      @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) {
        grid-template-rows: 70px 550px 61px 1fr;
        grid-template-columns: 1fr;
      }
      //(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait)
      @media (max-width: 450px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
        grid-template-columns: 1fr;
        grid-template-rows: 70px 288px 36px 1fr;
      }
      
      &.Single{
        grid-template-columns: calc(100% * 150/1920) calc(100% * 1650/1920) calc(100% * 120/1920);
        // @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        //   grid-template-columns: calc(100% * 96/1024) calc(100% * 831/1024) calc(100% * 96/1024);
        //   grid-template-rows: calc(100% * 90/697) calc(100% * 423/697) calc(100% * 184/697);
        // } 
        // @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        //   grid-template-columns: 0px 1fr;
        // }
      }
      &.Ctype{
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          grid-template-columns: calc(100% * 96/1024) calc(100% * 831/1024) calc(100% * 96/1024);
          grid-template-rows: calc(100% * 90/697) calc(100% * 423/697) calc(100% * 184/697);
        } 
      }
      .title{

        font-size:30px; font-weight:500; line-height:116.4%;text-align:center;width:100%; color:#eee;
        
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          grid-row: 1;
          grid-column: 2;          
          margin-block: auto;
        }
        @media (max-width:880px) and (max-height:1100px) and (orientation: portrait) {
          grid-row: 1;
          grid-column: 2;          
          margin-block: auto;
        }
        
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          // grid-row: 1;
          // grid-column: 1;          
          // margin-block: auto;
          font-size:24px; font-weight:500; line-height:116.4%;text-align:center;width:100%; color:#eee;
          margin-block: 0px;
          position: absolute;
          left: 4.59%;
          top: calc(100% * 16/767);
          //grid-row: 1/1;
          max-width: 80vw;
          width: auto;
          &.GallerySet{
            grid-column: 1;
          }
          &.Single{
            top: 0px;
          }
        }
      }
      .ScrollWrap{
        overflow-y: scroll;
        overflow-x: hidden ;
        display: flex;
        flex-direction: column;
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          overflow-y: hidden !important;
          overflow-x: scroll;
          flex-direction: row;
          align-items: flex-start;
          grid-row: 4/4;
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          overflow-y: hidden !important;
          overflow-x: scroll;
          flex-direction: row;
          //align-items: center;
          grid-row: 3/3;
          align-items: end;
        }
        .TMGThumb{
          // height:144px; width:266px;
          height:100%; width:83%;
          background-color: rgba(255,255,255,0.7); height: 160px;
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
            height: 85px;
            width: 154px;
            flex-shrink: 0;
          }
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 40px;
            width: 154px;
            flex-shrink: 0;
          }
          .portfolio-single-image{
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

        }
        ::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        >*{
          .TMGThumb{
            // height:144px; width:266px;
            height:100%; width:83%;
            background-color: rgba(255,255,255,0.7); height: 160px;

          }
        }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          >*{
            margin-right: 12px;
            margin-left: 31px;
            .TMGThumb{
              //height:144px; width:266px;
              height:100%; width:83%;
              background-color: rgba(255,255,255,0.7); height: 160px;
              @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
                height: 100%; width: 100%;
              }
            }
          }
        }
        
      }
      .GallerySlider{ grid-column: 1; grid-row: 2;
        display: flex;
        flex-direction: column;
        // background-color: aqua;
        @media (max-width: 1367px) and (orientation: landscape){
          grid-row: 2/3;
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          grid-column: 1;
          grid-row: 4;
          flex-direction: row;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          grid-column: 1;
          grid-row: 4;
          .ScrollWrap{
            ::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            >*{
              .TMGThumb{
                height:84px; width:154px;
  
              }
            }
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              >*{
                margin-right: 12px;
                margin-left: 31px;
                .TMGThumb{
                  //height:144px; width:266px;
                  height:100%; width:83%;
                  background-color: rgba(255,255,255,0.7); height: 160px;
                  @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
                    height: 100%; width: 100%;
                  }
                }
              }
            }
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
              >*{
                flex-direction: row !important;
                // margin-right: 12px;
                // margin-left: 31px;
                .TMGThumb{
                  height:84px; width:154px;
                  @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
                    height: 100%; width: 100%;
                  }
                }
              }
            }
            
          }
        }
        // @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          
        // }
      }
      .textBox{
        grid-row: 3;
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          grid-row: 3;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          grid-row: 4;
        }
        }
      .Tools{ grid-row: 2; grid-column: 3;
        // background-color: blanchedalmond;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          grid-row: 2; grid-column: 3;
          flex-direction: column;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          //display: none;
          grid-row: 3;
          grid-column: 1;
          flex-direction: row;
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          grid-row: 1;
          grid-column: 1;
          flex-direction: row;
        }
        &.Ctype{
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            grid-row: 2; grid-column: 3;
            flex-direction: column;
          }
        }
        &.SingleItemTMG{
          .Exit{
            display: block;
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
              display: none;
            }
          }
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            grid-row: 1;
            grid-column: 1;
            flex-direction: row;
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
            //display: none;
            grid-row: 3;
            grid-column: 2;
            flex-direction: row;
            align-items: flex-start;
            // &.Single{
            //   //grid-row: 5;
            // }
          } 
        }
        .Exit{
          display: block;
          height:calc(100% * 43/781);width:calc(100% * 43/118); margin-bottom:43px;
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            // height:calc(100% * 43/90);
            // width:calc(100% * 43/785); 
            height: 70px;
            // margin-left:42px; 
            margin-bottom: 0px;
            
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape){
            display: none;
          }
          &.Ctype{
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              height:calc(100% * 24/423);width:calc(100% * 24/96); margin-bottom:43px; margin-left: 0px;
              
            } 
          }
        }
        .OnMediaTools{
          display: flex;
        flex-direction: column;
        align-items: center;
        width:96%;
        p{
          display: none;
        }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)          
          flex-direction: column-reverse;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape){
          flex-direction: row-reverse;
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape){
          justify-content:flex-start;
          position:absolute;
          right: 64px;
          width: calc(100% - 64px);
          p{
            display: none;
          }
        }
        &.Ctype{

          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)          
          flex-direction: column;
          }
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape){
            flex-direction: row;
          }

        }
        justify-content: flex-start;
        color:rgb(238, 238, 238);
        p{
          text-align: center;
          margin-block: 0px;
        }
        @media (max-width: 450px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          height: 40px;
          // width: 24px;
        }
        @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 61px;
        }
        .menu-icon-custom .containDiv{
          @media (max-width: 450px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
            height: 36px !important;
            width: 36px !important;
          }
          @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 50px !important;
            width: 50px !important;
          }
        }
        }
      }
      .ToolsIcon{ grid-row: 3; grid-column: 1;
        // background-color: brown;
      }
      .ContentArea{ 
        grid-column: 2; grid-row: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        //max-height: calc(100% * (1080 - 116)/1080);
        max-height: 100%;
        // background-color: chartreuse;
        &.TMG{
          
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)          
            margin-left: 12px;
          } 
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            grid-column: 1;
            grid-row: 2;
          }
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            grid-column: 1;
            grid-row: 2;
            margin-left: 0px;
          }
        }
        &.SingleItemTMG{
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)          
            grid-row: 2/3;
          } 
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){ // Primary Mobile view in portrait orientation
            grid-column: 2;
            grid-row: 2;
          }
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape)  {
            grid-column: 1/4;
            grid-row: 2/3;
          }
          &.Single{
            grid-row:2/4;
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
              //grid-row:2/5;
              grid-row:2/3;
            }
          }
        }
        .portfolio-content-parent{
          min-height: 0px;
          width: 100% !important;
          overflow: overlay !important;
          overflow: clip !important;
          display: flex !important;
          height: 100%;
          .portfolio-content-container{
            //width: auto !important;
            justify-content: flex-start !important;
            .portfolio-content{
              justify-content: flex-start !important;
              .iframe-container{iframe{height: 100%;};embed{height: 100%;}}}}   
        }
      } 
    }
    .SubMenuRoot{
         // hide scrollbar
         ::-webkit-scrollbar {
          display: none;
        }
        width: 100vw;
        flex-shrink: 0;
        z-index: 1;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        .SubpageMenuContent{
          .ColorBar{
            height: calc(100% * 5/566);
        width: 100%;
        position: absolute;
        bottom: 0px;
        background-color: #981E59;
          }
          //z-index: 3;
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          display: grid;
          grid-template-rows: calc(100% * 35/331) calc(100% * 128/331) calc(100% * 115.6/331) calc(100% * 52/331);
          
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          display: grid;
          grid-template-rows: calc(100% * 81/770) calc(100% * 275/770) calc(100% * 176/770) calc(100% * 238/770);
        }
        // @media (max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){
        //   display: none;

        // }
        .ColorBar{
          height: calc(100% * 5/566);
          width: 100%;
          position: absolute;
          bottom: 0px;
          background-color: #981E59;
          grid-row: 3/3;
        }
        .SubpageBlockContent{
          background-color:white;
          width:100%; 
          position:absolute; 
          top:calc(100% * 159/967); 
          height:calc(100% * 558/967);
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            top:calc(100% * ((86)/(768 - 67))); 
            height:calc(100% * (442/(768 - 67)));
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            grid-row: 2/2;
            width: 100%;       
            top: 0px;   
            height: 100%;
            //background: linear-gradient(178.86deg, #166DC0 -42%, rgba(37, 121, 201, 0.5) 99.03%);
          }

        .TERMCScroll{
          overscroll-behavior: contain;
          ::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */    
          // overscroll-behavior: contain;
        }
        .SubpageBlockContentWrapper{
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            //top:calc(100% * 46/275);
            left:calc(100% * 22/414);
            width: calc(100% * (414 - 11)/414);
            //height: calc(100% - calc(100% * 46/275));
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            position: relative;
            //height:calc(100% * (270 - (94 * 2))/270);
            // height: 73px;
            // overflow: hidden;
          }
          p{
            margin-block: 0px;
            font-family: inherit;
          }
          h1{
            margin-block: 0px;
            font-family: inherit;
          }
          h3{
            margin-block: 0px;
            font-family: inherit;
          }
          h2{
            margin-block: 0px;
            font-family: inherit;
          };
          &.MSPACE{
            height:calc(100% * 46/275); 
            @media (max-width:880px) and (max-height:1100px) and (orientation: portrait){
              height:calc(100% * 46/1100); 
            }
          }
        }
        .SubpageBlockContentWrapper.header{
          // position:absolute;
          position: unset;
          top:calc(100% * 64/558);
          // height:calc(100% * 225/558);
          height:auto;
          width:calc(100% * 773/1159);
          // left:calc(100% * 64/1159);        
          margin-left:calc(100% * 64/1159);
          margin-bottom: 5%;
          font-weight: 900;
          line-height: 116.4%;
          letter-spacing: -0.14px;
          text-transform: uppercase;
          // @media (min-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          p{
            font-size: inherit;
            font-weight: 900;
            font-family: inherit;
          }
          h1{
            font-size: inherit;
            font-weight: 900;
            font-family: inherit;
          }
          h3{
            font-size: inherit;
            font-weight: 900;
            font-family: inherit;
          }
          h2{
            font-size: inherit;
            font-weight: 900;
            font-family: inherit;
          }
          font-size: inherit;
        // }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          top:calc(100% * 61/442);
          left:calc(100% * 34/650);
          width: calc(100% * 971/1024);
          height:calc(100% * 140/442);
          p{
            font-size: inherit;
            font-weight: 900;
          }
          h1{
            font-size: inherit;
            font-weight: 900;
          }
          h3{
            font-size: inherit;
            font-weight: 900;
          }
          h2{
            font-size: inherit;
            font-weight: 900;
          }
          font-size: inherit;
        }
        @media (max-width: 450px) and (orientation: portrait),
        (max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          // top:calc(100% * 46/275);
          // left:calc(100% * 22/414);
          // width: calc(100% * (414 - 11)/414);
          // //height:calc(100% * (270 - (94 * 2))/270);
          // height: 73px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          width: 100%;
          height: auto;
          position: static;
          p{
            font-size: 30px;
            font-weight: 900;
            line-height: unset;
          }
          h1{
            font-size: 30px;
            font-weight: 900;
            line-height: unset;
          }
          h3{
            font-size: 30px;
            font-weight: 900;
            line-height: unset;
          }
          h2{
            font-size: 30px;
            font-weight: 900;
            line-height: unset;
          }
          font-size: 30px;
          line-height: unset;
        }
          // font-family:'Gothic A1';
@media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) {
  // top:calc(100% * 46/275);
          // left:calc(100% * 22/414);
          // width: calc(100% * (414 - 11)/414);
          // //height:calc(100% * (270 - (94 * 2))/270);
          // height: 73px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          
          height: auto;
          position: static;
          p{
            font-size: 42px;
            font-weight: 900;
            line-height: 116.4%;
            
            overflow-y: clip;
            overflow-y: hidden;
          }
          h1{
            font-size: 42px;
            font-weight: 900;
            line-height: 116.4%;
            
            overflow-y: clip;
            overflow-y: hidden;
          }
          h3{
            font-size: 42px;
            font-weight: 900;
            line-height: 116.4%;
            
            overflow-y: clip;
            overflow-y: hidden;
          }
          h2{
            font-size: 42px;
            font-weight: 900;
            line-height: 116.4%;
            
            overflow-y: clip;
            overflow-y: hidden;
          }
          font-size: 42px;
          line-height: 116.4%;
          
          overflow-y: clip;
          overflow-y: hidden;
        }
        }
        .SubpageBlockContentWrapper.description{
          // position:absolute;
          position:unset;
          top:calc(100% * 291/558);
          // height:calc(100% * 262/558);
          height:auto;
          width:calc(100% * ((460 * 2) + 37) / 1159);
          // left:calc(100% * 64/1159);
          margin-left:calc(100% * 64/1159);
          
          //@media (min-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          p{
            font-size: inherit;
            font-family: inherit;
          }
          h1{
            font-size: inherit;
            font-family: inherit;
          }
          h3{
            font-size: inherit;
            font-family: inherit;
          }
          h2{
            font-size: inherit;
            font-family: inherit;
          }
          font-size: inherit;
        //}
          // font-family:'Gothic A1';
          line-height: 125%;
          letter-spacing: -0.14px;
          //columns:2;
          //text-transform: uppercase;
          &.SingleItem{
            width: calc(100% * (773) / 1159);
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
              width: calc(100% * (414 - 46) / 414);
              // height: calc(100% * 85 / 275);
              //height: 66px;
              margin-bottom: 0px;
              line-height: unset;

            }
          }
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            top:calc(100% * 214/442);
            left:calc(100% * 34/650);
            
            p{
              font-size: 15px;
              line-height: unset;
            }
            h1{
              font-size: 15px;
              line-height: unset;
            }
            h3{
              font-size: 15px;
              line-height: unset;
            }
            h2{
              font-size: 15px;
              line-height: unset;
            }
            font-size: 15px;
            line-height: unset;
          }
          @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait){
//   top:calc(100% * 142/275);
          // left:calc(100% * 22/414);
          // width: calc(100% * (414 - 46)/414);
          // height:calc(100% * (270 - 146)/270);
          // overflow: hidden;
          // text-overflow: ellipsis;
          //position: relative;
          height:auto;
          overflow-y: auto;
        position: static;
        p{
          font-size: 24px;
          font-weight: 900;
          line-height: 125%;
          
          overflow-y: clip;
          overflow-y: hidden;
        }
        h1{
          font-size: 24px;
          font-weight: 900;
          line-height: 125%;
          
          overflow-y: clip;
          overflow-y: hidden;
        }
        h3{
          font-size: 24px;
          font-weight: 900;
          line-height: 125%;
          
          overflow-y: clip;
          overflow-y: hidden;
        }
        h2{
          font-size: 24px;
          font-weight: 900;
          line-height: 125%;
          
          overflow-y: clip;
          overflow-y: hidden;
        }
        font-size: 24px;
        line-height: 125%;
        
        overflow-y: clip;
        overflow-y: hidden;
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          //   top:calc(100% * 142/275);
          // left:calc(100% * 22/414);
          // width: calc(100% * (414 - 46)/414);
          // height:calc(100% * (270 - 146)/270);
          // overflow: hidden;
          // text-overflow: ellipsis;
          //position: relative;
            height:auto;
            overflow-y: auto;
          position: static;
          p{
            font-size: 14px;
            font-weight: 900;
            line-height: unset;
          }
          h1{
            font-size: 14px;
            font-weight: 900;
            line-height: unset;
          }
          h3{
            font-size: 14px;
            font-weight: 900;
            line-height: unset;
          }
          h2{
            font-size: 14px;
            font-weight: 900;
            line-height: unset;
          }
          font-size: 14px;
          line-height: unset;
          }
        }
        }
    }
    .SubtitleHeader{
      margin-block: 0px;
      color: #eee;
      position:absolute;
      top: calc(100% * 95/1080);
      left: calc(100% * 64/1080);
      font-size: 40px;
      line-height: 116.4%;
      font-weight: 500;
      letter-spacing: -0.14px;
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        font-size: 18px;
      }
    }
    .OverGhost{
      margin-block: 0px;
      color: #eee;
      position:absolute;
      z-index: 1;
      //bottom: -90px;
      left: calc(100vw * 240/1920);
      font-size: 458px;
      overflow: hidden;
      line-height: 116.4%;
      font-weight: 900;
      letter-spacing: -7.14px;
      word-wrap: unset;
      white-space: nowrap;
      pointer-events: none;
      // width: calc(100% * (1920 - 264)/1920);
      width: calc(100% * (1920 - 240)/1080);
      opacity: 0.3;
      user-select: none;
      bottom: calc(100vh * -91/1080);
      &.Small{
        font-size: calc(458px / 2);
        left: calc(100vw * ((240 / 2)/1920));
        bottom: calc(100vh * -1 * ((91 / 2)/1080));
      }
      &.Medium{
        font-size: calc(458px * (3 / 4));
        left: calc(100vw * ((240 * 0.75)/1920));
        bottom: calc(100vh * -1 * ((91 * 0.75)/1080));
      }
    @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          display:none;
         }
    }
    .SubpageBlockContentWrapper{
      p{
        margin-block: 0px;
        font-family: inherit;
      }
      h1{
        margin-block: 0px;
        font-family: inherit;
      }
      h3{
        margin-block: 0px;
        font-family: inherit;
      }
      h2{
        margin-block: 0px;
        font-family: inherit;
      }
    }
    .SubpageBlockContentWrapper.OnGray{
      p{
        color: #eee;
      }
      h1{
        color: #eee;
      }
      h3{
        color: #eee;
      }
      h2{
        color: #eee;
      }
      span{
        color: #eee !important;
      }
    }
    //TMG
    .SubpageBlockContentWrapper.header.TMG{
      p{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      h1{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      h3{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      h2{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      font-size: inherit;
      font-weight: 900;
      // font-family:'Gothic A1';
      line-height: 116.4%;
      letter-spacing: -0.14px;
      text-transform: uppercase;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        p{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h1{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h3{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h2{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        font-size: 24px;
        font-weight: 500;
        font-family: inherit;
        line-height: unset;
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        p{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h1{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h3{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h2{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        font-size: 24px;
        font-weight: 500;      
        font-family: inherit;
        line-height: unset;
      }

    }
    .SubpageBlockContentWrapper.header.TMG.TERMC{
      p{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      h1{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      h3{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      h2{
        font-size: inherit;
        font-weight: 900;
        font-family: inherit;
      }
      font-size: inherit;
      font-weight: 900;
      // font-family:'Gothic A1';
      line-height: 116.4%;
      letter-spacing: -0.14px;
      text-transform: uppercase;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        p{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h1{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h3{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        h2{
          font-size: 24px;
          font-weight: 500;
          font-family: inherit;
          line-height: unset;
        }
        font-size: 24px;
        font-weight: 500;
        font-family: inherit;
        line-height: unset;
      }
    }
    .SubpageBlockContentFooterSingle{
      width: calc(100% * (320 - 44)/1159);
      position: absolute;
      right: calc(100% * (44)/1159);
      display: grid;
      // height: calc(100% * (28 + 35 + 9)/558);
      height: auto;
      bottom: calc(100% * 27/558);
      //background-color: #981E59;
      grid-template-columns: 1fr calc(100% * 45/(320 - 44));
      // grid-template-rows: calc(100% * 39/(calc(100% * (28 + 35 + 9)/558))) 1fr; //Error Flag
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)

      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
        right: unset;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: calc(100% * 5 / 275);
        background: rgba(200,200,200,0.7);
        height: min-content;
        position: static;
        grid-row: 3/3;
        z-index: 1;
      }
      .Title{
        font-size: 30px;
        font-weight: 500;
        line-height: 116.4%;
        letter-spacing: -0.14px;
        text-align: right;
        grid-column: 1/1;
        grid-row: 1/1;
        margin-block: 0px;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
         font-size: 16.2px; 
        }


        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
          font-size: inherit;
        }
      }
      .Subtitle{
        font-size: 24px;
        font-weight: 400;
        line-height: 116.4%;
        letter-spacing: -0.14px;
        text-align: right;
        grid-column: 1/1;
        grid-row: 2/2;
        margin-block: 0px;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          font-size: 13.5px; 
         }
      }
      .ArrowContainer{
        grid-column: 2/2;
        grid-row: 1/1;
        align-self:center;
        justify-self: end;
        margin-top: auto;
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
          width: 7px;
          height: 14px;
        }
      }
      .TextContainer{
        grid-column: 1/1;
        display: grid;        
        p{
        margin-block: 0px;
        }
        // grid-template-rows: calc(100% * 39/(calc(100% * (28 + 35 + 9)/558))) 1fr;
        .Title{
          font-size: 30px;
          font-weight: 500;
          line-height: 116.4%;
          letter-spacing: -0.14px;
          text-align: right;
          grid-row: 1/1;
        }
        .Subtitle{
          font-size: 24px;
          font-weight: 400;
          line-height: 116.4%;
          letter-spacing: -0.14px;
          text-align: right;
          grid-row: 2/2;
        }
      }
    }
    // .SubpageBlockContentWrapper.header{
    //   p{
    //     font-size: inherit;
    //     font-weight: 900;
    //   }
    //   h1{
    //     font-size: inherit;
    //     font-weight: 900;
    //   }
    //   h3{
    //     font-size: inherit;
    //     font-weight: 900;
    //   }
    //   h2{
    //     font-size: inherit;
    //     font-weight: 900;
    //   }
    //   font-size: inherit;
    //   font-family:'Gothic A1';
    //   font-weight: 900;
    //   line-height: 116.4%;
    //   letter-spacing: -0.14px;
    //   text-transform: uppercase;
    // }
    .SubpageBlockContentWrapper.description{
      p{
        font-size: inherit;
        font-family: inherit;
      }
      h1{
        font-size: inherit;
        font-family: inherit;
      }
      h3{
        font-size: inherit;
        font-family: inherit;
      }
      h2{
        font-size: inherit;
        font-family: inherit;
      }
      font-size: inherit;
      // font-family:'Gothic A1';
      line-height: 125%;
      letter-spacing: -0.14px;
      margin-bottom: 12px;
      @media (max-width:880px) and (max-height:1100px) and (orientation: portrait){
        margin-bottom: 0px;
      }
      
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        p{
          font-size: 15px;
          font-weight: 400;
          font-family: inherit;
          line-height: unset;
        }
        h1{
          font-size: 15px;
          font-weight: 400;
          font-family: inherit;
          line-height: unset;
        }
        h3{
          font-size: 15px;
          font-weight: 400;
          font-family: inherit;
          line-height: unset;
        }
        h2{
          font-size: 15px;
          font-weight: 400;
          font-family: inherit;
          line-height: unset;
        }
        font-size: 15px;
        font-weight: 400;
        line-height: unset;
      }
      //columns:2;
      //text-transform: uppercase;
    }
    .SubMenuTextWrapper{
      .Header{
        font-weight: 600;
        font-size: 32px;
        line-height: 121%;
        letter-spacing: -0.0725027px;
        color: #333;
        margin-block: 0px;
        font-family: inherit;
      }
      .Subtitle{
        font-weight: 400;
        font-size: inherit;
        line-height: 125%;
        letter-spacing: -0.0725027px;
        color: #333;
        margin-block: 0px;
        font-family: inherit;
      }
    }
    .quill{
      width: 100%;
    }
    .ql-container{
      height: calc(100% - 50px) !important;
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        height: calc(100% - 81px) !important;
      }
    }
    .NotePadButton{
      &:hover{
        text-decoration: underline;
      }
      .terminus-button-div{
        //background-color: antiquewhite;
        .title{
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .SubpageBlockContent{
      .ColorBar{
        height: calc(100% * 5/566);
        width: 100%;
        position: absolute;
        bottom: 0px;
        background-color: #981E59;
      }
    .TERMCScroll{
      overscroll-behavior: contain;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */    
    }
    }
    .TMGCItem{
      position: relative; 
      width: calc(100% * 404/(1920 - 67)); 
      height: calc(100% * 285/(340)); 
      flex-shrink: 0; 
      margin-right: 24px;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        width:calc(100% * 323/(1024 - 31));
        height: calc(100% * 215/(286)); 
      }
      
      @media (max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait) {
        width:calc(100% * 315/(880));
        height: calc(100% * 675/(1100)); 
      }
      @media (min-width: 1070px) and (max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) {
        width:calc(100% * 675/(1250));
        height: calc(100% * 1025/(2000)); 
      }
      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height:calc(100% * 365/(414));
        width: calc(100% * 115/(286)); 
      }
      @media (max-width: 450px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
        width:calc(100% * 365/(414));
        height: calc(100% * 215/(286)); 
      }
      img:first-of-type{
        height: auto !important;
      }
      div:first-of-type{
        height: 100%;
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape){
          height: 47px !important;
        }
      }
    }
    .SubMenuItemWrapper{
      .IconHolder{
        img{
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .portfolio-template.portfolio-content-parent{
      overflow: overlay;
      overflow: clip;
    }
    .ScrollIndicator{
      background-color: white;
      right: calc(100% * 142/1920);
      bottom: calc(100% * 63/1080);
      width: calc(100% * 289/1920);
      height: calc(100% * 78/(1080 - 114));
      position: absolute;
      z-index: 2;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        //font-size: calc(120px * 13.5/120);
        width: calc(100% * (1024 - (34 + 806)) / 1024);
        height: calc(100% * 1024 - (35 + 683) / (768));
      }
      @media (max-width:880px) and (max-height:1100px) and (orientation: portrait) {
        width:calc(100% * 289/880);
        height: calc(100% * 78/(1100 - 114));
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
        display:none;
      }
    }
    .CoverRoot{
      position: relative;
      z-index: 6;
      .CoverQuote{
        >p{font-family: inherit;}
        //DefaultDesktop
        text-align: right;        
        position:absolute; 
        top:calc(100% * 101/1080);
        left:calc(100% * 976/1920); 
        font-size:calc(100VW * 98/1920);
        color:#eeeeee;
        line-Height:"116.4%";
        font-Weight:900; 
        width:calc(100% * 885/1920); 
        height:calc(100% * 456/1080);
        text-transform: capitalize;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          top:calc(100% * 77/768);
          left: calc(100% * 512/1024);
          width:calc(100% * 459/1024); 
          height:calc(100% * 266/768);
          font-size:60px;
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
          top: 33%;
          left: calc(50% - 90%/2);
          font-size: 42px;
          line-Height: 48.89px;
          width: 90%;
          height: calc(20%);
        }
      
      }
      .CoverBaseBack{
        background:linear-gradient(180deg, #166DC0 0%, rgba(37, 121, 201, 0.8) 100%);
        width:100%;
        height:37.96vh;
        position:absolute;
        bottom:0px;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          height:calc(100% * 278/768);
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
          height: 32.16vh;
        }
          .CoverSubtitle{
            >p{font-family: inherit;}
            position:relative;
            color:#eee;
            font-Weight:300;
            top:calc(100% * 33/410); 
            left:calc(100% * 920/1920); 
            font-Size:41px;
            line-Height:116.4%;
            letter-Spacing:-0.14px;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              top:calc(100% * 23/278); 
              left:calc(100% * 496/1024); 
              font-Size:24px;
            }
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
              top: calc(100% * 33/410);
              left: calc(50% - (55%/2));
              font-Size: inherit;
              width: 55%;
              text-align: center;
            }
          }
        }
        .CoverLogoBack{
          position:absolute;
          top:calc(100% * 577/1080);
          left:0px;
          background-Color:white;
          height:23.5vh;
          width:calc(100% * 877/1920);
          .logo{
            position: relative;
          }
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            top:calc(100% * 437/768); 
            height:calc(100% * 168/768);
            width: calc(100% * 468/1024);
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
            top: 0px;
            width: 100%;
            height: 123px;
            display: flex;
            justify-content: center;
            align-items: center;
            .logo{
              width: calc(64.1961%);
              height: 123px;
              position: static;
              left: calc(17.252%);
              top: -4%;
            }
          }
          
          .CoverColorBar{
            position:relative;
            @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
              position:absolute;
            }
          }
        }
    }
    .SubmenuItemBackground{
      
      position:absolute;
      width:calc(100% * (1920 - 1159)/1920); 
      // width:calc(100% * (1920 - 800)/1920); 
      //width:100%;
      height:100%;
      top:0px; 
      right:0px;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
         width: calc(100% * (1024 - (1024 - 374))/1024);
        // width: calc(100% * (1024 - (1024 - 574))/1024);

        //width: 100%;
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
        width: 100%;

      }
      :first-child{
        position:absolute;
      }
    }
    .SubmenuItemMenuBackground{
      position:relative; 
      height:100%; 
      width:calc(100% * 1159/1920);
      background: radial-gradient(80.35% 80.35% at 9.45% 19.65%, #3690E4 0%, #166DC0 100%,);
      box-shadow:30px 4px 54px rgba(0, 0, 0, 0.25);
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        width: calc(100% * (1024 - 374)/1024);
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        grid-row: 1/1;
        width: 100%;
        background: linear-gradient(178.86deg, #166DC0 -42%, rgba(37, 121, 201, 0.5) 99.03%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .SubmenuItemMenuBackground1{
        position:relative; 
        height:100%; 
        width:calc(100vw * 1159/1920);
        // background: radial-gradient(80.35% 80.35% at 9.45% 19.65%, #3690E4 0%, #166DC0 100%,);
        // box-shadow:30px 4px 54px rgba(0, 0, 0, 0.25);
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          width: calc(100vw * (1024 - 374)/1024);
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          grid-row: 1/1;
          width: 100%;
          background: linear-gradient(178.86deg, #166DC0 -42%, rgba(37, 121, 201, 0.5) 99.03%);
          display: flex;
          justify-content: center;
          align-items: center;
        } 
      // .OverGhost{
      //   margin-block: 0px;
      //   color: #eee;
      //   pointer-events: none;
      //   position:absolute;
      //   z-index: 1;
      //   bottom: calc(100vh * -90/1080 );
      //   //top: calc(100vh - 458px/1.4);
      //   left: calc(100vw * 240/1920);
      //   // font-size: 458px;
      //   font-size: calc(100vw * 459/1920);
      //   line-height: 116.4%;
      //   font-weight: 900;
      //   letter-spacing: -7.14px;
      //   word-wrap: unset;
      //   white-space: nowrap;
      //   // width: calc(100% * (1920 - 264)/1920);
      //   // width: calc(1920px - (100% * 264/1080));
      //   width: calc(100vw - (100% * 240/1920));
      //   max-width: calc(100vw - (100vw * 240/1920));
      //   opacity: 0.3;
      //   user-select: none;
      //   overflow: hidden;
      //   @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
      //     // width: calc(1024px - (100% * 127/1024));
      //     width: calc(100vw - (100% * 96/1024));
      //     max-width: calc(100vw - (100vw * 100/1024));
      //     // font-size: 267px;
      //     font-size: calc(100vw * 267/1024);
      //     left: calc(100vw * 96/1024);
      //    }
      // }
      .SubtitleHeader{
        margin-block: 0px;
      color: #eee;
      position:absolute;
      top: calc(100% * 95/1080);
      left: calc(100% * 64/1080);
      font-size: calc(100vw * 40/1920);
      line-height: 116.4%;
      font-weight: 500;
      letter-spacing: -0.14px;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        font-size: inherit;
        top: calc(100% * (36 / 768));
        left: calc(100% * 31/1024);
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        font-size: 18px;
        position: static;
      }
      }
      .SubpageBlockContent{
        background-color:white;
        width:100%; 
        position:absolute; 
        top:calc(100% * 159/967); 
        height:calc(100% * 558/967);
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          top:calc(100% * ((86)/(768 - 67))); 
          height:calc(100% * (442/(768 - 67)));
        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          grid-row: 2/2;
          width: 100%;          
          //background: linear-gradient(178.86deg, #166DC0 -42%, rgba(37, 121, 201, 0.5) 99.03%);
        }
        
        .ColorBar{
          height: calc(100% * 5/566);
          width: 100%;
          position: absolute;
          bottom: 0px;
          background-color: #981E59;
        }
      .TERMCScroll{
        overscroll-behavior: contain;
        ::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */    
      }
      .SubpageBlockContentWrapper{
        p{
          margin-block: 0px;
          font-family: inherit;
        }
        h1{
          margin-block: 0px;
          font-family: inherit;
        }
        h3{
          margin-block: 0px;
          font-family: inherit;
        }
        h2{
          margin-block: 0px;
          font-family: inherit;
        }
      }
      .SubpageBlockContentWrapper.header{
        //position:absolute;
        position: unset;
        top:calc(100% * 64/558);
        // height:calc(100% * 225/558);
        height:auto;
        width:calc(100% * 773/1159);
        // left:calc(100% * 64/1159);
        margin-left:calc(100% * 64/1159);   
        margin-bottom: 3.5%;
        margin-top: 1.75%;

        // @media (min-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        p{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        h1{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        h3{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        h2{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        font-size: inherit;
      // }
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        top:calc(100% * 61/442);
        left:calc(100% * 34/650);
        width: calc(100% * 971/1024);
        // height:calc(100% * 140/442);
        p{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        h1{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        h3{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        h2{
          font-size: inherit;
          font-weight: 900;
          font-family: inherit;
        }
        font-size: inherit;
      }
        // font-family:'Gothic A1';
        font-weight: 900;
        line-height: 116.4%;
        letter-spacing: -0.14px;
        text-transform: uppercase;
      }
      .SubpageBlockContentWrapper.description{
        // position:absolute;
        position:unset;
        top:calc(100% * 291/558);
        // height:calc(100% * 262/558);
        height:auto;
        width:calc(100% * ((460 * 2) + 37) / 1159);
        // left:calc(100% * 64/1159);
        margin-left:calc(100% * 64/1159);
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          top:calc(100% * 214/442);
          left:calc(100% * 34/650);
          
          p{
            font-size: 15px;
            line-height: unset;
            
          }
          h1{
            font-size: 15px;
            line-height: unset;
            
          }
          h3{
            font-size: 15px;
            line-height: unset;
            
          }
          h2{
            font-size: 15px;
            line-height: unset;
            
          }
          font-size: 15px;
          line-height: unset;
        }
        //@media (min-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        p{
          font-size: inherit;
          
        }
        h1{
          font-size: inherit;
          
        }
        h3{
          font-size: inherit;
          
        }
        h2{
          font-size: inherit;
          
        }
        font-size: inherit;
      //}
        // font-family:'Gothic A1';
        line-height: 125%;
        letter-spacing: -0.14px;
        //columns:2;
        //text-transform: uppercase;
        &.SingleItem{
          width: calc(100% * (773) / 1159);
        }
      }
      }
    }
    }
    .SubmenuItemMenuBackgroundTERMC{
      z-index: 1;
      position:relative; 
      height:100%; 
      width:calc(100% * 1159/1920);
      background: radial-gradient(80.35% 80.35% at 9.45% 19.65%, #3690E4 0%, #166DC0 100%,);
      box-shadow:30px 4px 54px rgba(0, 0, 0, 0.25);
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        width: calc(100% * (1024 - 374)/1024);
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        grid-row: 1/1;
        width: 100%;
        background: linear-gradient(178.86deg, #166DC0 -42%, rgba(37, 121, 201, 0.5) 99.03%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      // .OverGhost{
      //   margin-block: 0px;
      //   color: #eee;
      //   position:absolute;
      //   z-index: 1;
      //   pointer-events: none;
      //   bottom: calc(100% * -90/1080 ) ;
      //   //top: calc(100vh - 458px/1.4);
      //   left: calc(100vw * 240/1920);
      //   font-size: 458px;
      //   line-height: 116.4%;
      //   font-weight: 900;
      //   letter-spacing: -7.14px;
      //   word-wrap: unset;
      //   white-space: nowrap;
      //   // width: calc(100% * (1920 - 264)/1920);
      //   width: calc(100vw - (100% * 240/1920));
      //   max-width: calc(100vw - (100vw * 240/1920));
      //   opacity: 0.3;
      //   user-select: none;
      //   overflow: hidden;
      //   @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
      //     width: calc(100vw - (100% * 96/1024));
      //     max-width: calc(100vw - (100vw * 100/1024));
      //     // font-size: 267px;
      //     font-size: calc(100vw * 267/1024);
      //     left: calc(100vw * 96/1024);

      //    }
      //    @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
      //     display:none;
      //    }
      // }
      .SubpageBlockContent{
        background-color:white;
        width:100vw; 
        position:absolute; 
        top:calc(100% * 159/967); 
        height:calc(100% * 558/967);
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          top:calc(100% * ((86)/(768 - 67))); 
          height:calc(100% * (442/(768 - 67)));
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          top:calc(16.4426%) !important;
          height: calc(100% - 16.4426%) !important;
        }
        .TMGCFooter{
          display: none;
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            width: 100%;
            background-color: rgba($color: #ffffff, $alpha: 0.7);
          } 
        }
        .ColorBar{
          height: calc(100% * 5/566);
          width: 100%;
          position: absolute;
          bottom: 0px;
          background-color: #981E59;
        }
      .TERMCScroll{
        overscroll-behavior: contain;
        ::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */    
      }
      .SubpageBlockContentWrapper{
        p{
          margin-block: 0px;
          font-family: inherit;
        }
        h1{
          margin-block: 0px;
          font-family: inherit;
        }
        h3{
          margin-block: 0px;
          font-family: inherit;
        }
        h2{
          margin-block: 0px;
          font-family: inherit;
        }
      }
      
    }
  }
    .SubpageMenuContent{
      background-color:rgba(255,255,255,0.7);
      width:calc(100% * 563/1920); 
      position:absolute; 
      height:calc(100% * 558/967);
      top:calc(100% * 159/967);
      left:calc(100% * 1159/1920);
      //z-index: 3;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        top:calc(100% * ((86)/(768 - 67))); 
        height:calc(100% * (442/(768 - 67)));
        left:calc(100% * 650/1024);
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        grid-row: 3/3;
        flex-direction: row;
        justify-content:unset;
        align-items:unset;
        overflow-x: scroll;
        overflow-y: hidden;
      }
      
      &.Single{
        background-color:rgba(255,255,255,0);
        width:calc(100% * 563/1920);
        position:absolute;
        height:calc(100% * 558/967);
        top:calc(100% * 159/967);
        left:calc(100% * 1159/1920);        
        flex-direction:column;
        justify-content:center;
        align-items:center;
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait) { // Primary Mobile view in portrait orientation
          grid-row: 3/5;
          height: 100%;
          width: 100%;
          top: 0px;
          left: 0px;
          //position: static;
        }
        .portfolio-single-image{
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          width:calc(100% * 375/1024);
          position:absolute;
          height:calc(100% * 376/768);
          top:calc(100% * 161/768);
          left:calc(100% * 650/1024);
        }
        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          grid-row: 3/5;
          height: 100%;
          width: 100%;
          top: 0px;
          left: 0px;
          //position: static;
        }
        .ColorBar{
          height: calc(100% * 5/566);
      width: 100%;
      position: absolute;
      bottom: 0px;
      background-color: #981E59;
      grid-row: 3/3;
        }
      }
      .UpArrow{
        position:absolute; 
        // background-color:#981E59; 
        width:calc(100% * 60/563); 
        height:calc(100% * 60/558); 
        top:calc(-100% * 39.64/558);
        left:calc(100% * 260/563); 
        display:flex;
        justify-content:center;
        align-items:center;
        mask-size:100% 100%;
        -webkit-mask-size:100% 100%;
        .arrow{
          background-color:white;
          width:calc(100% * 25.26/60);
          height:calc(100% * 12.63/60); 
          
          mask-size:100% 100%;
          -webkit-mask-size:100% 100%;

        }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          width:calc(100% * 40/300); 
        height:calc(100% * 40/433); 
        top:calc(-100% * 18/558);
        left:calc(100% * 260/563); 
        }
        
      }

      
      .DownArrow{
        position:absolute; 
        // background-color:#981E59; 
        width:calc(100% * 60/563); 
        height:calc(100% * 60/558); 
        transform:rotate(180deg);
        
        left:calc(100% * 260/563); 
        display:flex;
        justify-content:center;
        align-items:center;
        mask-size:100% 100%;
        -webkit-mask-size:100% 100%;
        bottom:calc(-100% * 39.64/558);
        .arrow{
          transform:rotate(180);
          background-color:white;
          width:calc(100% * 25.26/60);
          height:calc(100% * 12.63/60); 
          
          mask-size:100% 100%;
          -webkit-mask-size:100% 100%;
        }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
          width:calc(100% * 40/300); 
        height:calc(100% * 40/433); 
        bottom:calc(-100% * 18/558);
        left:calc(100% * 260/563); 
        }
      }
      .SubMenuItemdivisor{
       
        width:calc(100% * (563 - (61 + 66)) / 563);
        // height:calc((100% * 558/967)/2);
        height: 2px;
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)

        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          // grid-template-columns:90px 1fr;
          //width:calc(100% * (414 - (41)) / 414);
          width: 100%;
          flex-shrink: 0;
          height: 100%;
          display: none;
        }
      }
      .SubMenuItemWrapper{
        display:grid;
        grid-template-rows:1fr 2px;
        width:calc(100% * (563 - (61 + 66)) / 563);
        height:calc((100% * 558/967)/2);
        grid-template-columns:1fr 90px;
        transition: all 0.2s;
        &#active{
          transform: scale(1.08);
          filter: drop-shadow(3px 3px 3px #00000059) drop-shadow(-3px -3px 5px #00000059);
        }
        &:hover{
          transform: scale(1.08);
          filter: drop-shadow(3px 3px 3px #00000059) drop-shadow(-3px -3px 5px #00000059);
        }
        @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)

        }
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          grid-template-columns:90px 1fr;
          //width:calc(100% * (414 - (41)) / 414);
          width: 100%;
          flex-shrink: 0;
          height: 100%;
        }
        .SubMenuTextWrapper{
          display:flex;
          flex-direction:column;
          justify-content:center;
          grid-column:1/1;
          margin-left:calc(100% * 13 / (563 - (61 + 66)));
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            grid-column:2/2;
          }
          .Header{
            font-weight: 600;
            font-size: 24px;
            line-height: 121%;
            letter-spacing: -0.0725027px;
            color: #333;
            margin-block: 0px;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              font-size: calc(120px * 18.9/120);
            }
          }
          .Subtitle{
            font-weight: 400;
            font-size: inherit;
            line-height: 125%;
            letter-spacing: -0.0725027px;
            color: #333;
            margin-block: 0px;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              font-size: calc(120px * 13.5/120);
            }
          }
        }
        .IconHolder{
          grid-column:2/2;
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            grid-column:1/1;
            grid-row: 1/1;
          }
        }
      }
    }
    .ToolbarIcon{
      position:absolute;
      left:calc(100% * 36/1920);
      z-index: 10;
      bottom:calc(100% * 32/967);
      height:calc(100% * 42/967);
      width:calc(100% * 48/1920);
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        height:calc(100% * 28/768);//
        width:calc(100% * 32/1024);
      }
      @media (max-width:880px) and (max-height:1100px) and (orientation: portrait) {
        height:calc(100% * 28/880);
        width:calc(100% * 32/1100);
      }
      @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation        
        display:none;
      }
    }
    .TMGCTextRoot{
      display:flex;
      flex-direction:column;
      margin-left:calc(100% * 66/1920);
      overflow-x:visible;
      overflow-y:hidden;
      height:100%;
      display: grid;
      grid-template-rows: (calc(100% * 403/558) calc(100% * 155/558));
      @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
        margin-left:calc(100% * 34/1024);
      }
      .TERMCText{
        display: flex; flex-direction: row; justify-content: space-between;
        // overflow-x: hidden;
        overscroll-behavior: contain;
        @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
          //width: 100%;
          overflow-y: scroll;
          ::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
         }
        .TERMCTextContainer{
          display: flex; flex-direction: column; height: 100%; width: calc(100% * 661/(1920 - 66)); flex-shrink: 0;
          overflow-y: scroll;
          overflow-x: hidden;
          overscroll-behavior: contain;
          @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            width:calc(100% * 661/(1024 - 43));
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            width: 100%;
            overflow-y: scroll;
            ::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
           }
          
        }
        .TERMCButtons{
          display: flex; 
          flex-direction: row; 
          height: 100%; 
          width: 100%; 
          align-items: flex-end; 
          justify-content: flex-end;
          .LeftArrow{
            margin-bottom: calc(100% * 124/1080); 
            height: calc(100% * 60/228); 
            // width: calc(100% * 60/909); 
            width: calc(100% * 43.6/909);
            object-fit: contain;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              height: calc(100% * 40/101); 
            width: calc(100% * 40/(1024 - 43 - (1024 * 661/(1024 - 43)))); 
            }
          }
          .Divider{
            margin-bottom: calc(100% * 124/1080); 
            height: 1px; 
            width: calc(100% * 39/(1024 - 43 - (1024 * 661/(1024 - 43))));
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
            }
          }
          .RightArrow{
            margin-right:calc(100% * 82/909);
            margin-bottom: calc(100% * 124/1080); 
            height: calc(100% * 60/228); 
            // width: calc(100% * 60/909); 
            width: calc(100% * 43.6/909);
            object-fit: contain;
            @media (max-width: 1367px) and (orientation: landscape) { // Main tablet view in landscape (large tablet down to mobile)
              height: calc(100% * 40/101); 
            width: calc(100% * 40/(1024 - 43 - (1024 * 661/(1024 - 43)))); 
            margin-right:calc(100% * 43/(1024 - 43 - (1024 * 661/(1024 - 43))));
            }
          }
          @media (max-width: 450px) and (orientation: portrait),(max-width: 880px) and (max-height: 1100px) and (orientation: portrait),(max-width: 1000px) and (max-height: 1500px) and (orientation: portrait),(max-width: 1250px) and (max-height: 2000px) and (orientation: portrait),(max-width: 815px) and (max-height: 550px) and (orientation: landscape) { // Primary Mobile view in portrait orientation
            display: none;
          }
        }
      }
      
    }
    p{
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    img{
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    video{
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    div{
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
