// use styles from portfolio-template
.portfolio-template.theme1 {
  // override background-color in portfolio-template
  background-color: white;

  .portfolio-menu-container {
    .logo {
      .logo-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .portfolio-menu {
      .active {
        display: flex;
        visibility: visible;
        opacity: 1;
        animation: appear-from-left 0.5s forwards;
      }

      .hidden {
        display: none;
        animation: hide-to-right 0.5s forwards;
      }
    }

    // all button under menu
    button {
      // add small animation
      transition: all 0.5s;
      &:hover {
        margin-right: 10px;
      }
    }
  }

  .bottom-gradient {
    width: 100%;
    height: 50px;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    );
  }

  .back-container {
    position: absolute;
    bottom: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1;
  }

  //--------------------------
  // animations
  //--------------------------
  @keyframes appear-from-left {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  @keyframes hide-to-right {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      position: absolute; // setting postion absolute to remove the empty sapce
      left: -100%; // since setting position absolute messes up the transform: translateX, set -100% here too
      opacity: 0;
      transform: translateX(-100%);
    }
  }
}
