// use styles from portfolio-template
// + template-z
.portfolio-template.theme7 {
    position: relative;
  
    .portfolio-content-parent {
      margin: 0;

      .portfolio-toplevel {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: hidden;
        width: 100%;
        height: 100%;

        button {
          width: 250px;
          height: 50px;
          margin: 0px 0 38px 10px;
          background-color: transparent;
          font-family: 'Oswald', sans-serif;
          // align text in p to left
          p {
            text-align: left;
          }
          &.hidden {
            display: none !important; // need !important prevent override from flex
          }

          &.active {
            display: inherit;
          }
        }

        // as list grows, make the size smaller
        $elements: 10;
        @for $i from 0 to $elements {
          ul:nth-child(#{$i + 1}) {
            button {
              height: calc(45px - #{$i}* 3px);
              white-space: nowrap;
              // overflow: hidden;
              text-overflow: ellipsis;
              text-shadow: 2px 2px 2px black;
              p {
                font-size: calc(36px - #{$i} * 5px);
              }
            }
          }
        }

        .group {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          margin: 1%;
          width: 22.5%;
          // hide all ul under, aka level 1 and up will be hidden

          .toplevel-item {
            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                font-family: 'Oswald', sans-serif;
              }
          }

          div:nth-child(n + 2) {
            display: none !important; // THIS MEANS NO CHILDREN WILL APPEAR. THIS IS THE ONLY THING THAT MAKES IT SO THE TOP LEVEL ITEMS ARE THE ONLY ONES RENDERED.
            height: 225px;
          }
        }
      }
      
      .portfolio-big-nav-container,
      .portfolio-big-nav-prev-container {
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 1;
        scrollbar-width: none;
        -webkit-scrollbar {
          width: 0px;
        }

        // EVERYTHING INSIDE .GROUP IS FOR TOP-LEVEL ELEMENTS. ALL OUTSIDE .GROUP IS FOR SUB-LEVEL AND TERMINUS-LEVEL ELEMENTS
        .group {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          margin: 1%;
          width: 22.85%;
          // hide all ul under, aka level 1 and up will be hidden

          div:nth-child(n + 2) {
            display: none !important; // THIS MEANS NO CHILDREN WILL APPEAR. THIS IS THE ONLY THING THAT MAKES IT SO THE TOP LEVEL ITEMS ARE THE ONLY ONES RENDERED.
            height: 225px;
          }

          .big-nav {
            overflow: hidden;
            position: relative;
            height: 100%;
            width: 100%;
            border-radius: 12px;
          }

          .portfolio-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            overflow: hidden;
            height: 100%;
            cursor: pointer;
            position: relative;
            object-fit: contain;
            border-radius: 12px;

            button {

              p {
                font-size: 1.4em;
              }
            }
    
            .portfolio-single-image {
              flex-shrink: 0;
              width: 100%;
              height: 100%;
              object-fit: fill;
            }

            .iframe-container {
              position: relative;
              width: 100%;
              height: 100%;
    
              .website-link {
                height: 20px;
              }
    
              iframe {
                display: block;
                background: transparent;
                border: none;
                width: 100%;
                height: 100%;
                scrollbar-width: none;
                -webkit-scrollbar {
                  width: 0px;
                }
              }
            }

            .website-content {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
    
              p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                font-family: 'Oswald', sans-serif;
                font-size: 4em;
                letter-spacing: 0.1em;
                font-weight: 600;
                color: white;
                text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
              }
            }
    
            // hide video control in nav
            video::-webkit-media-controls-enclosure {
              display: none !important;
            }
          }
        }
  
        // END .GROUP, ALL ELEMENTS HERE ARE FOR SUB-LEVEL AND TERMINUS-LEVEL ELEMENTS
        .big-nav {
          overflow: hidden;
          position: relative;
          width: 22.5%;
          border-radius: 5px;
        }
  
        .big-nav-extra {
          overflow: hidden;
          height: 200px;
          width: 22.5%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          margin: 1%;
          border: 1px solid black;
          border-radius: 5px;
  
          .logo-extra {
  
            img {
              // padding: 5px;
              // background-color: #FFFFFF80;
              // border-radius: 2px;
            }
          }
  
          .logo-extra-thumb {
  
          }
        }
  
        .big-nav-thumb {
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          cursor: pointer;
  
          .logo-thumb {
            
          }
        }
  
        .portfolio-content {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          height: 100%;
          cursor: pointer;
          position: relative;
          object-fit: contain;

          button {
            padding-right: 10px;
            padding-left: 10px;

            p {
              font-size: 1em;
            }
          }

          .mux-player {
            
            div {
              
              video {
                border-radius: 5px;
              }
            }
          }
  
          .portfolio-single-image {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
  
          .iframe-container {
            position: relative;
            width: 100%;
            height: 100%;
  
            .website-link {
              height: 20px;
            }
  
            iframe {
              display: block;
              background: transparent;
              border: none;
              width: 100%;
              height: 100%;
              scrollbar-width: none;
              -webkit-scrollbar {
                width: 0px;
              }
            }
          }
  
          .website-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
  
            p {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-family: 'Oswald', sans-serif;
              font-size: 4em;
              letter-spacing: 0.1em;
              font-weight: 600;
              color: white;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
          }
  
          // hide video control in nav
          video::-webkit-media-controls-enclosure {
            display: none !important;
          }
        }
        button {
          margin: 0;
        }
  
        ::before {
          content: '';
          width: 0;
          // padding-bottom: 100%;
          grid-row: 1 / 1;
          grid-column: 1 / 1;
        }
      }
  
      .portfolio-big-nav-prev-container {
        position: absolute;
        width: 40%;
        height: 80%;
        left: 30%;
        top: 10%;
        z-index: 2;
        background-color: white;
        border-radius: 100px;
      }
  
      .portfolio-big-nav-prev-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1;
      }
  
      .portfolio-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
  
        .portfolio-content-wrapper {
          width: 100%;
          height: 100%;
        }
      }
  
      .portfolio-content-menu-container {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        .portfolio-content-menu {
          display: flex;
          justify-content: center;
          overflow: visible;
          position: absolute;
          z-index: 3;
          background-color: red;
          // padding: 0;
          // margin: 24px;
  
          // remove button background
          button {
            background-color: transparent;
            transition: all 0.15s ease;
  
            // increase font size
            p {
              font-size: 24px;
            }
          }
  
          button:hover {
            transform: scale(1.1, 1.1);
          }
        }
      }
    }
  
    .portfolio-action-menu {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      z-index: 5;
      height: 94px;
  
      .action-menu-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
  
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90px !important;
          width: 90px !important;
          position: unset !important;
          img{
            height: 100% !important;
            width: 100% !important;
          }
        }
      }
  
      .action-menu-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 700px;
  
        .button-popup-left {
          height: 94px;
          display: flex;
          justify-content: center;
          align-items: center;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in;
          div {
            transition: left 0.3s ease-in 0s;
            //transition: all 0.3s ease-in;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            background-color: #00000080;
            opacity: inherit;
            //visibility: inherit;
            .containDiv{
              position: unset;
              transform: unset;
              //visibility: inherit;
              //opacity: inherit;
            }
          }
        }
  
        .button-popup-right {
          height: 94px;
          display: flex;
          justify-content: center;
          align-items: center;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in;
          
          div {
            transition: left 0.3s ease-in 0s;
            //transition: transform 0.3s ease-in 0s;
            // transition: opacity 0s step-end 0s;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            background-color: #00000080;
            opacity: inherit;
            .containDiv{
              position: unset;
              transform: unset;
              //opacity: inherit;
            }
          }
        }
      }
  
      &:hover {
        .action-menu-container {
          .button-popup-left {
            visibility: visible;
            opacity: 1;
            div:first-child {
              left: 33%;
            }
            div:nth-child(2) {
              left: 16.5%;
            }
            div:nth-child(3) {
              left: 0;
            }
          }
  
          .button-popup-right {
            visibility: visible;
            opacity: 1;
            div:first-child {
              left: 66.5%;
            }
            div:nth-child(2) {
              left: 83.5%;
            }
            div:nth-child(3) {
              left: 100%;
            }
          }
        }
      }
    }
  
    .portfolio-briefcase-container {
      position: fixed;
      grid-template-rows: 1fr 9fr 50px;
      display: grid;
      right: 0;
      width: 19.8vw;
      top: 5%;
      height: 90%;
      // border: 2px solid black;
      transition: all 0.3s;
      // overflow: hidden;
      transform: translateX(100%);
      background-color: transparent;
      gap: 10px;
  
      &.active {
        transform: translateX(-8%);
      }
  
      &.hidden {
        transform: translateX(100%);
      }
  
      .portfolio-briefcase-label{
        grid-row: 1 / span 1;
        display: grid;
        grid-template-rows: 0.6fr 1.2fr;
        z-index: 1;
        background-color: #000000BF;
        border-radius: 5px;
        padding-top: 5px;
  
        .close-container {
          grid-row: 1 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        .intro-box {
          grid-row: 2 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Oswald', sans-serif;
          letter-spacing: 0.12em;
          font-weight: 700;
        }
      }
  
      .portfolio-briefcase {
        margin-bottom: 48px;
        z-index: 2;
        margin-left: 12px;
        margin-right: 12px;
        width: 94%;
        grid-row: 2 / span 1;            
      }
  
      .briefcase-action-button-container {
        grid-row: 3 / span 1;
        // background-color: #000000BF;
  
        .briefcase-send-button {
          border-radius: 20px;
          padding: 5%;
          width: 30%;
          font-family: 'Oswald', sans-serif;
          transition: all 0.3s ease;
          border-width: 2px;
          border-style: solid;
          font-size: 12px;
        }
  
        .briefcase-send-button:hover {
          transform: scaleY(1.25);
        }
      }
  
        .briefcase-content-list {
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          align-items: center;
          scrollbar-width: none;            
          width: 94%;
          padding: 0;
          background-color: #00000080;
          border-radius: 5px;
          
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 70px;
            margin: 20px 0;
            background-color: rgb(163, 163, 163);
            color: white;
          }
          
        .briefcase-content-list-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 45px;
          margin-top: 2px;
          margin-bottom: 13px;
          background-color: white;
          border-radius: 5px;
        }
        
      }
      .briefcase-content-list::-webkit-scrollbar{
        width: 0px;
      }
  
      .briefcase-send-button {
        grid-row: 4/5;
      }
    }

    .portfolio-notepad-container {
      position: fixed;
      display: grid;
      grid-template-rows: 1fr 9fr 50px;
      right: 0;
      width: 19.8vw;
      top: 5%;
      height: 90%;
      border-radius: 10px;
      transition: all 0.3s;
      transform: translateX(100%);
      background-color: transparent;
      // gap: 10px;
  
      &.active {
        transform: translateX(-8%);
      }
  
      &.hidden {
        transform: translateX(100%);
      }
  
      // contains md close icon
      .notepad-close {
        grid-row: 1 / span 1;
        display: grid;
        grid-template-rows: 0.6fr 1.2fr;
        z-index: 1;
        // background-color: #000000BF;
        width: 100%;
        border-radius: 5px;
        padding-top: 30px;
  
        .close-container {
          grid-row: 1 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
  
          .expansion {
            transition: all 0.2s ease;
          }
  
          .expansion:hover {
            transform: scale(1.15, 1.15);
          }
        }
  
        .intro-box {
          grid-row: 2 / span 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Oswald', sans-serif;
          letter-spacing: 0.12em;
          font-weight: 700;
        }
      }
  
      .textarea-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-row: 2 / span 1;     
  
        h1 {
          width: 90%;
          background-color: white;
          color: black;
          margin: 10px 0 0 0;
        }
  
        textarea {
          width: 90%;
          height: 100%;
          resize: none;
          padding: 0;
        }
        
      }
  
      .notepad-button-container {
        display: flex;
        justify-content: space-evenly;
        grid-row: 3 / span 1;
        // position: absolute;
        // width: 100%;
  
        button {
          border-radius: 25px;
          padding: 5%;
          width: 30%;
          font-family: 'Oswald', sans-serif;
          transition: all 0.3s ease;
          border-width: 2px;
          border-style: solid;
          font-size: 12px;
        }
  
        button:hover {
          transform: scaleY(1.25);
        }
      }
    }
  
    .back-container {
      display: none;
    }
  
    /* Safari 10.1+ */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        // Seems like safari does not work well with ::before to grow the grid contents
        // Override button in nav for safari to positon button text to center
        .portfolio-big-nav-container,
        .portfolio-big-nav-prev-container {
          button {
            position: relative;
            :first-child {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
  