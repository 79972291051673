html{
  text-align: left;
  background-color: #cccccc;
  font-size: 16px;
  color: #000;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  -webkit-overflow-scrolling: auto;

}
body {
  text-align: left;
  background-color: #cccccc;
  font-size: 16px;
  color: #000;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: auto;
}

.App {
  width: 100%;
  height: 100vh;
  position: fixed;
  //min-width: 900px;
  -webkit-overflow-scrolling: auto;
}
