.portfolio-template.theme4 {
    // override background-color in menu-template
    background-color: rgb(59, 59, 59);

    @media (max-width: 1367px) and (min-width: 1023px) {
      flex-direction: column;
    }

    @media (max-width: 1367px) and (min-width: 1023px) and (min-height: 1025px) {
      justify-content: space-between;
    }

    @media (max-width: 1367px) and (min-height: 1025px) {
      flex-direction: column;
      justify-content: space-between;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    @media (max-width: 768px) and (max-height: 1024px) {
      flex-direction: column;
      justify-content: space-between;
    }

    @media (max-width: 450px) and (orientation: portrait) {
      overflow: hidden;
    }

    .MuiSlider-markLabel {
      left: 50px !important;
      text-shadow: 0px 1px 2px #FFFFFF,
                   0px 2px 1px #DEDEDE;
      color:black !important;
      font-weight: 1000 !important;
      font-size: 20px !important;
      font-family: 'Open Sans', serif !important;
      letter-spacing: 1px !important;
    }

    .toggle-mobile-toolbar {
      position: fixed;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-bottom-left-radius: 8px;
      z-index: 6
    }

    .portfolio-topright-toolbar {
      position: absolute;
      z-index: 7;
      right: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-color: #3b3b3b80;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s all ease;

      &.active {
        transform: translateY(0px);
      }

      &.hidden {
        transform: translateY(100%);
      }

      .icon-flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: min-content !important;

        &.guest {
          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            display: none;
          }
  
          @media (max-width: 450px) and (orientation: portrait) {
            display: none;
          }
        }
  
        .icon-and-text-parent {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0px;
  
          .test-absolute-text {
            width: 250px;
            height: 30px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            
            p {
              color: white;
              font-size: 20px;
              font-weight: 600;
              text-shadow: 1px 1px 1px #3b3b3b;
              cursor: pointer;
              font-family: 'Roboto Slab', serif;
            }
          }
        }
  
        .menu-icon-custom {
  
          .containDiv {
            height: 60px !important;
            width: 60px !important;
          }
        }
      }
    }

    .overlay-play-button {
      position: absolute;
      z-index: 1;
      height: 50% !important;
      width: 50%;
      top: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .send-email-popup {
      .send-email-popup-box {
        @media (max-width: 450px) and (orientation: portrait) {
          width: 250px;
        }

        .send-email-popup-text {
          input {
            @media (max-width: 450px) and (orientation: portrait) {
              width: 230px !important;
            }
          }
        }
      }
    }

    .modal {
      @media (max-width: 1367px) {
        z-index: 6;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        z-index: 8;
      }

      .modal-container {
        @media (max-width: 1367px) {
          top: 40%;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          width: 250px;
        }

        button {
          @media (max-width: 450px) and (orientation: portrait) {
            width: 230px;
            height: 40px;
          }
        }

        .customer-info-form {

          input {
            @media (max-width: 450px) and (orientation: portrait) {
              width: 230px;
            }
          }
        }
      }
    }

    .control-dots {
      @media (max-width: 450px) and (orientation: portrait) {
        margin: 35px 0;
      }
    }

    .portfolio-scriptnote-container {
      position: fixed;
      right: 0%;
      width: 350px;
      height: 700px;
      top: calc(50% - 350px);
      background-color: white;
      border-radius: 12px;
      box-shadow: 0px 3px 12px 2px #3b3b3b;
      transition: all 0.5s;
      transform: translateX(100%);
      z-index: 5;
  
      @media (max-width: 1367px) {
        height: 550px;
        top: calc(50% - 300px);
        width: 250px;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        height: 426px;
        width: 250px;
        top: calc(50% - 213px);
        left: calc(50% - 125px);
        box-shadow: none;
        transition: all 0s ease;
        z-index: 7 !important;
      }
  
      &.active {
        transform: translateX(-2%);
        @media (max-width: 450px) and (orientation: portrait) {
          transform: translateX(0%);
          visibility: visible;
        }
      }
  
      &.hide {
        transform: translateX(120%);

        @media (max-width: 450px) and (orientation: portrait) {
          transform: translateX(0%);
          visibility: hidden;
        }
      }
  
      // contains md close icon
      .scriptnote-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 2;
  
        .scriptnote-close-button {
          height: 70px !important;
          width: 70px !important;
          margin: 10px 10px 0px !important;
  
          @media (max-width: 1367px) {
            height: 60px !important;
            width: 60px !important;
            margin: 8px 8px 0px !important;
          }
        }
      }
  
      .scriptnote-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        width: 100%;
        height: calc(100% - 110px);
        margin-top: 60px;
      }
  
      // content of script note
      .text-container {
        position: relative;
        width: 90%;
        height: 100%;
        margin-top: 10px;
        background-color: white;
        list-style: inside !important;
        overflow: auto;
  
        h1 {
          margin: 5px;
          // font-family: 'Newsreader', serif;
          font-size: 28px;
          font-weight: 600;
        }
  
        ul{
          list-style: inside !important;
        }
  
        p {
          margin: 6px;
        }
  
      }
    }

    .menu-icon-custom {
      transition: 0.2s all ease;

      .containDiv {
        height: 70px;
        width: 70px;

        @media (max-width: 1367px) and (min-width: 1025px) {
          height: 40px !important;
          width: 40px !important;
        }

        @media (max-width: 1024px) and (max-height: 1024px) {
          height: 35px !important;
          width: 35px !important;
        }

        @media (max-width: 1367px) and (min-height: 1025px) {
          height: 80px !important;
          width: 80px !important;
        }

        @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
          height: 70px !important;
          width: 70px !important;
        }

        @media (max-width: 768px) and (max-height: 1024px) {
          height: 60px !important;
          width: 60px !important;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }
      }

      img {
        @media (max-width: 1367px) and (min-width: 1025px) {
          height: 40px !important;
          width: 40px !important;
        }

        @media (max-width: 1024px) and (max-height: 1024px) {
          height: 35px !important;
          width: 35px !important;
        }

        @media (max-width: 1367px) and (min-height: 1025px) {
          height: 80px !important;
          width: 80px !important;
        }

        @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
          height: 70px !important;
          width: 70px !important;
        }

        @media (max-width: 768px) and (max-height: 1024px) {
          height: 60px !important;
          width: 60px !important;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          height: 40px !important;
          width: 40px !important;
        }
      }
      
    }

    .menu-icon-custom:hover {
      transform: scale(1.15, 1.15);
    }

    .portfolio-action-menu {

      .action-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 330px;
  
        .exit-button {
          margin-top: auto;
        }
  
        .row-first {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
  
        .row-second {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
      }
    }

    .portfolio-menu-container {

        display: grid;
        grid-template-rows: 1fr 8fr;

        @media (min-width: 1024px) and (orientation: portrait) {
          position: absolute; 
          bottom: 150px;
          z-index: 5;
        }

        @media (max-width: 1367px) and (min-width: 1023px) {
          min-width: 200px;
          grid-template-rows: none;
          max-width: none;
        }

        @media (max-width: 1367px) {
          max-width: none;
          position: relative;
          top: 0px;
        }
        @media (max-width: 1367px) and (orientation: portrait) {
          height: 128px;
          @media (min-height: 1024px) and (min-width:1024px) {
            top: 192px;
            
          }
        }

        @media (max-width: 450px) and (orientation: portrait) {
          position: fixed; 
          bottom: 0px;
          z-index: 5;
          top: unset;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          position: fixed; 
          bottom: 0px;
          z-index: 5;
          top: unset;
        }


        .logo {
          object-fit: contain;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          grid-row: 1/2;
          // position: unset;
          grid-column: 1 / span 1;
          @media (max-width: 1367px) {
            display: none !important;
            @media (min-height:1280px) {
              display: block !important;
              
            }
          }

          @media (max-width: 450px) and (orientation: portrait) {
            display: none;
            position: fixed;
            height: 40px;
            top: 5px;
          }

          @media (min-width: 1024px) and (orientation: portrait) {
            display: block;
            position: fixed;
            height: 60px;
            max-height: 60px;
            top: 70px;
            // width: auto;
          }

          @media (max-width: 380px) and (orientation: portrait) {
            display: none;
          }

          .top-gradient {

          }

          
        }

        .menus-container {
          height: 100%;
          grid-row: 2/2;

          @media (min-width: 1024px) and (orientation: portrait) {
            width: 100vw;
          }

          @media (max-width: 1367px) and (min-width: 1025px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
          }

          @media (max-width: 1024px) and (max-height: 1024px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;
          }

          @media (max-width: 1367px) and (min-height: 1025px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 128px;
          }

          .back-container {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            padding-top: 40px;
            padding-bottom: 55px;
            
            height: 60vh;
            max-height: 90vh;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -webkit-scrollbar {
              width: 0px;
            }

            @media (max-width: 1367px) {
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              mask-image: none;
              -webkit-mask-image: none;
              flex-direction: row;
              justify-content: center;
              padding-bottom: 35px;
              padding-top: 50px;
              // padding: 0px;
              height: auto;
              overflow-y: hidden;
              overflow-x: auto;
              //touch-action: pan-x;
              width: 100vw;
              max-width: 100vw;
              justify-content: flex-start;
            }
            @media (max-width: 1367px) and (orientation:'portrait') {
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              mask-image: none;
              -webkit-mask-image: none;
              flex-direction: row;
              justify-content: center;
              // padding-bottom: 35px;
              // padding-top: 50px;
              padding: 0px;
              // height: auto;
              height: 128px;
              overflow-y: hidden;
              overflow-x: auto;
              //touch-action: pan-x;
              width: 100vw;
              max-width: 100vw;
              justify-content: flex-start;
              position:fixed;
              top: 0px;
              @media (min-height: 1024px) and (min-width:1024px) {
                top: 192px;
                
              }
            }

            @media (max-width: 1024px) and (min-width: 1366px) {
              padding-top: 20px;
            }
            @media (max-width: 1024px) and (min-width: 1366px) and (orientation: portrait ) {
              padding: 0px;
            }

            @media (max-width: 1024px) and (max-height: 1024px) {
              padding-top: 5px;
              margin-top: 40px;
              padding-bottom: 5px;
            }
            @media (max-width: 1024px) and (max-height: 1024px) and (orientation: portrait ) {
              // padding-top: 5px;
              // margin-top: 40px;
              // padding-bottom: 5px;
              padding: 0px;
              margin: 0px;
            }

            @media (max-width: 768px) and (max-height: 1024px) {
              padding-top: 25px;
              padding-bottom: 25px;
              margin-top: 145px;
            }
            
            @media (max-width: 768px) and (max-height: 1024px) and (orientation: portrait ) {
              // padding-top: 25px;
              // padding-bottom: 25px;
              // margin-top: 145px;
              padding: 0px;
              margin: 0px;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              padding-left: 50px;
              margin-top: 0px;
            }

            @media (max-width: 380px) and (orientation: portrait) {
              padding-left: 0px;
              margin-top: 0px;
            }
            
          .back-container:hover{
            filter: brightness(115%);
              transform: scale(1.05, 1.05);
          }
      
            img {
              height: 100px;
              width: 100px;
              transition: all 0.4s;
              cursor: pointer;
            }

            .portfolio-content-menu-container {

              @media (max-width: 1367px) {
                display: flex;
                align-items: center;
              }

              button {
                margin-bottom: 30px;

                @media (max-width: 1367px) and (min-width: 1025px) {
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                  height: 93px;
                  width: 200px;
                }

                @media (max-width: 1024px) and (max-height: 1024px) {
                  width: 130px;
                  height: 70px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 1024px) and (min-height: 1025px) {
                  width: 180px;
                  height: 120px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 1024px) and (min-width: 769px) {
                  margin-right: 10px;
                }

                @media (max-width: 768px) and (max-height: 1024px) {
                  width: 120px;
                  height: 130px;
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-radius: 8px;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 75px;
                }

                div {
                  overflow: hidden;

                  p {
                    @media (max-width: 1024px) and (max-height: 1024px) {
                      font-size: 15px;
                    }

                    @media (max-width: 1024px) and (min-height: 1025px) {
                      font-size: 22px;
                    }
                  }
                }
              }

              .portfolio-content-menu {

                @media (max-width: 1367px) {
                  display: flex;
                }

                button {
                  transition: 0.5s all ease;
                  margin-bottom: 0px;

                  @media (max-width: 1367px) and (min-width: 1025px) {
                    width: 160px;
                    margin-right: 0px;
                  }

                  @media (max-width: 1024px) and (max-height: 1024px) {
                    width: 110px;
                    margin-right: 0px;
                  }

                  @media (max-width: 1024px) and (min-height: 1025px) {
                    width: 130px;
                    margin-right: 0px;
                  }

                  div {
                    overflow: hidden;

                    @media (max-width: 1367px) {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    }

                    p {
                      @media (max-width: 1367px) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: horizontal;
                        -webkit-line-clamp: 2;
                      }

                      @media (max-width: 1024px) and (max-height: 1024px) {
                        font-size: 14px !important;
                      }

                      @media (max-width: 1024px) and (min-height: 1025px) {
                        font-size: 20px !important;
                      }
                    }
                  }

                }

                button:hover {
                  filter: brightness(120%);
                }
              }
            }
      
            // img:hover {
            //   filter: brightness(115%);
            //   transform: scale(1.05, 1.05);
            // }
          }

          .portfolio-menu {
            // change hidden and active to be button specific
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            height: 80vh;
            max-height: 90vh;
            padding-top: 50px;
            scrollbar-width: none;
            -webkit-scrollbar {
              width: 0px;
            }
            &.isAtEnd{              
              opacity: 0;
            }            
            right: 5.5%;

            @media (max-width: 1367px) and (min-width: 1025px) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              height: auto;
              // height: 128px;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // padding-right: 80px;
              // margin-top: 30px !important;
              padding-left: 30px;
              padding-top: 10px !important;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              width: 100vw;
              max-width: 100vw;
            }
            @media (max-width: 1367px) and (min-width: 1025px) and (orientation:portrait) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              // height: auto;
              height: 128px;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // padding-right: 80px;
              // margin-top: 30px !important;
              padding-left: 30px;
              padding-top: 10px !important;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              width: 100vw;
              max-width: 100vw;
            }

            @media (max-width: 1024px) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              height: auto;
              // height: 128px;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              padding-right: 80px;
              padding-left: 0px;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              width: 100vw;
              max-width: 100vw;
              padding-bottom: 25px;
              padding-top: 25px;
            }
            @media (max-width: 1024px) and (orientation:portrait) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              // height: auto;
              height: 128px;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              padding-right: 80px;
              padding-left: 0px;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              width: 100vw;
              max-width: 100vw;
              padding-bottom: 0px;
              padding-top: 0px;
            }

            @media (max-width: 1367px) and (min-height: 1025px) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              height: auto;
              // height: 128px;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              padding-right: 80px;
              padding-left: 0px;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              width: 100vw;
              max-width: 100vw;
              padding-bottom: 25px;
              padding-top: 25px;
            }
            @media (max-width: 1367px) and (min-height: 1025px) and (orientation:portrait) {
              display: flex;
              justify-content: center;
              align-items: center;
              right: 0%;
              // height: auto;
              height: 128px;
              mask-image: none;
              -webkit-mask-image: none;
              // border-left: 2px solid #80808080;
              // border-right: 2px solid #80808080;
              // box-shadow: -1px -1px 21px -8px #3b3b3b;
              padding-right: 80px;
              padding-left: 0px;
              overflow-y: hidden;
              overflow-x: auto;
              touch-action: pan-x;
              scrollbar-width: none;
              -webkit-scrollbar {
                height: 0px;
              }
              width: 100vw;
              max-width: 100vw;
              padding-bottom: 0px;
              padding-top: 0px;
            }

            @media (max-width: 1024px) and (max-height: 1024px) {
              // padding-top: 5px;
              // padding-bottom: 5px;
              // margin-top: 40px !important;
              border-left: 0px solid #80808080;
              border-right: 0px solid #80808080;
              padding-left: 30px;
            }

            @media (max-width: 768px) and (max-height: 1024px) {
              width: 100vw;
              // padding-top: 25px;
              // margin-top: 145px !important;
              // border-left: 0px solid #80808080;
              // border-right: 0px solid #80808080;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              margin-top: 0px !important;
              // padding-bottom: 25px;
              padding-left: 5px;
            }

            .row-container {

              @media (max-width: 1367px) and (min-width: 1023px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }
              
              @media (max-width: 1367px) and (min-height: 1025px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }

              @media (max-width: 1024px) and (min-width: 769px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                display: flex;
                align-items: center;
                flex-direction: row;
              }
            }

            .menu-filler {

              @media (max-width: 1367px) and (min-width: 1023px) {
                display: none;
              }

              @media (max-width: 1024px) {
                display: none;
              }
            }

            .group {
              @media (max-width: 1367px) {
                flex-direction: row;
              }
            }

            .active {
              display: flex;
              visibility: visible;
              opacity: 1;
              animation: appear-from-left 0.5s forwards;
            }
      
            .hidden {
              display: none;
              animation: hide-to-right 0.5s forwards;
            }
                // all button under menu


            button {
              // add small animation
              transition: all 0.5s;
              &:hover {
                margin-right: 10px;
                filter: brightness(120%);
              }

              @media (max-width: 1367px) {
                &:hover {
                  margin-right: 0px;
                  filter: none;
                  // box-shadow: 3px 3px 5px -3px #3b3b3b;
                }
              }

              .title-container {
                overflow: hidden;

                @media (max-width: 1367px) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                }

                p {
                  @media (max-width: 1367px) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: horizontal;
                    -webkit-line-clamp: 2;
                  }

                  @media (max-width: 1024px) and (max-height: 1024px) {
                    font-size: 13px !important;
                  }

                  @media (max-width: 1024px) and (min-height: 1025px) {
                    font-size: 23px !important;
                  }

                  @media (max-width: 768px) and (max-height: 1024px) {
                    font-size: 16px !important;
                  }
                }

              }
            }

            button.level-0 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 150px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 120px;
                border-radius: 8px;
                height: 70px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 200px;
                border-radius: 8px;
                height: 120px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 120px;
                border-radius: 8px;
                height: 130px;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                height: 75px;
              }
            }

            button.level-1 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 130px;
                height: 85px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 110px;
                height: 60px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 150px;
                border-radius: 8px;
                height: 110px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 100px;
                border-radius: 8px;
                height: 110px;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                height: 70px;
              }
            }

            button.level-2 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 110px;
                height: 78px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 90px;
                height: 52px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 135px;
                border-radius: 8px;
                height: 100px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 90px;
                border-radius: 8px;
                height: 100px;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                height: 65px;
              }
            }

            button.level-3 {
              @media (max-width: 1367px) and (min-width: 1025px) {
                width: 90px;
                height: 70px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (max-height: 1024px) {
                width: 70px;
                height: 45px;
                border-radius: 8px;
              }

              @media (max-width: 1024px) and (min-height: 1025px) {
                width: 120px;
                border-radius: 8px;
                height: 90px;
              }

              @media (max-width: 768px) and (max-height: 1024px) {
                width: 80px;
                border-radius: 8px;
                height: 90px;
              }


              @media (max-width: 450px) and (orientation: portrait) {
                height: 60px;
              }
            }
          
            

        }
        }

    
    
        // change all button color under menu red
        button {
            margin-top: 8px;

            @media (max-width: 1367px) and (min-width: 1023px) {
              margin-top: 0px;
              margin-left: 8px;
            }

            @media (max-width: 1024px) and (min-width: 769px) {
              margin-left: 8px;
            }

            @media (max-width: 768px) and (max-height: 1024px) {
              margin-top: 0px;
              margin-left: 8px;
            }
        }

        
    }
  
    .portfolio-content-parent {
      // change all button color under portfolio-content-parent yellow
      @media (max-width: 1367px) and (max-width: 1024px) {
      // margin-bottom: 45px;

      position:fixed;
      top: 128px;
      bottom: 51px;
      margin: 0px;
      }
      @media (max-width: 1367px) and (min-width: 1025px) {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
        position: fixed;
        bottom: 50px;
        top: 128px;
      }
      @media (min-width:1024px) and (orrientation:portrait) and (min-height: 1024px) {

          top: 320px;
        
      }

      @media (max-width: 768px) and (max-height: 1024px) {
        margin-top: 0px;
      }

      @media (max-width: 1024px) and (min-width: 769px) and (min-height: 769px) {
        // margin-bottom: 200px;
        margin-top: 0px;
        //height: calc(100% * ((100% - 206px)/1040)); //MENU BUTTONS ENLARGE SPONTAINOUSLY ON DISMISSAL OF ADDRESS BAR, INSPECTION REQUIRED.
        // margin-bottom: 51px;
        margin-bottom: 0px;
        position: fixed;
        bottom: 51px;
        top: 128px;
      }

      @media (max-width: 1367px) and (min-width: 1023px) and (min-height: 1025px) {
        margin-bottom: 300px;
        margin-top: 0px;
      }
      
      @media (min-width: 1025px) and (orientation: portrait) {
        margin-bottom: 170px;
        margin-top: -30px;
      }

      @media (min-width: 1024px) and (orientation: portrait) {
        position: fixed !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        top: 128px;
        bottom: 51px;
        @media (min-height: 1024px) and (min-width:1024px){
          top: 320px;
        }
      }

      @media (max-width: 450px) and (orientation: portrait) {
        position: absolute;
        top: 110px;
        margin-bottom: 0px;
      }

      @media (max-width: 380px) and (orientation: portrait) {
        position: absolute;
        top: 60px;
        margin-bottom: 0px;
      }

      button {
        background-color: rgb(55, 128, 52);
        margin-top: 15px;
      }

      .containDiv {
        @media (max-width: 1367px) and (min-width: 1023px) {
          display: none;
        }
      }

      .portfolio-content-container {
        @media (min-width: 1025px) and (orientation: portrait) {
          height: 100% !important;
          min-height: 800px;
        }

        @media (max-width: 1367px) and (min-width: 1025px) {
          // height: 71.3vh;
          height: 100%
        }

        @media (max-width: 1024px) and (max-height: 1024px) {
          // height: 71.3vh;
          height:100%
        }

        @media (max-width: 1024px) and (min-width: 769px) {
          // height: 71.3vh;
          height: 100%;
        }

        @media (max-width: 1367px) and (min-height: 1025px) {
          // height: 600px;
          height: 100%;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          height: 400px;
        }

        @media (max-width: 380px) and (orientation: portrait) {
          height: 300px;
        }
        

        .portfolio-content {

          .pdf-banner {
            .menu-icon-custom {
              .containDiv {
                @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                  height: 35px !important;
                  width: 35px !important;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 40px !important;
                  width: 40px !important;
                }

                @media (max-width: 380px) and (orientation: portrait) {
                  height: 35px !important;
                  width: 35px !important;
                }
              }
            }
          }

          .portfolio-gallery-view {
            position: relative;

            @media (max-width: 1367px) {
              position: relative;
            }

            .hovering-chevron {
              display: block;
              position: absolute;
              z-index: 4;
              border-radius: 50%;
              background-color: #3b3b3b80;
              top: 45%;

              @media (max-width: 1367px) {
                display: block;
                position: absolute;
                z-index: 4;
                border-radius: 50%;
                background-color: #3b3b3b80;
                top: 45%;
              }

              .menu-icon-custom {

                .containDiv {
                  @media (max-width: 1367px) {
                    display: block;
                  }
                }

                img {
                  @media (max-width: 450px) and (orientation: portrait) {
                    height: 40px;
                    width: 40px;
                  }
                }
              }
            }

            .carousel-root {
              height: 90% !important;
              flex-shrink: 0;
              width: 100%;

              @media (max-width: 1367px) {
                height: 100% !important;
              }

              .containDiv {

                @media (max-width: 1367px) and (min-width: 1023px) {
                  display: block;
                  height: 35px !important;
                  width: 35px !important;
                }

                @media (max-width: 768px) and (max-height: 1024px) {
                  height: 35px !important;
                  width: 35px !important;
                }

                @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                  height: 30px !important;
                  width: 30px !important;
                }
              }
            }

            .thumb-container {
              display: none !important;

              @media (max-width: 1367px) {
                display: none !important;
              }
            }

            .page-count-container {
              @media (max-width: 1367px) {
                display: none !important;
              }
            }

          }
        }
      }

    }

    .portfolio-notepad-container {
      transition: all 0s;
      border-left-width: 4px !important;
      box-shadow: 0px 3px 12px 2px #3b3b3b;
      width: 400px;
      height: 700px;
      top: calc(50% - 350px);
      right: calc(50% - 200px);
      border-radius: 12px;
      z-index: 5;

      @media (max-width: 1367px) {
        transition: all 0s;
        border-left-width: 4px !important;
        box-shadow: 0px 3px 12px 2px #3b3b3b;
        width: 400px;
        height: 550px;
        top: calc(50% - 300px);
        right: calc(50% - 200px);
        border-radius: 12px;
        z-index: 5;
      }

      @media (max-width: 450px) and (orientation: portrait) {
        width: 250px;
        height: 426px;
        top: calc(50% - 213px);
        right: calc(50% - 125px);
        z-index: 7 !important;
      }

      &.active {
        visibility: visible;

        @media (max-width: 1367px) {
          visibility: visible;
        }
      }

      &.hidden {
        transform: translateX(0%);
        visibility: hidden;

        @media (max-width: 1367px) {
          transform: translateX(0%);
          visibility: hidden;
        }
      }

      &.hide {
        transform: translateX(0%);
        visibility: hidden;

        @media (max-width: 1367px) {
          transform: translateX(0%);
          visibility: hidden;
        }
      }

      .notepad-close-button {
        height: 70px !important;
        width: 70px !important;
        margin: 10px 10px 0px !important;

        @media (max-width: 1367px) {
          height: 60px !important;
          width: 60px !important;
          margin: 8px 8px 0px !important;
        }
      }

      .textarea-container {
        margin-top: 0px !important;
        font-family: 'Newsreader', serif;

        @media (max-width: 1367px) {
          margin-top: 0px !important;
          font-family: 'Newsreader', serif;
        }

        .notes-title {
          margin-bottom: 15px !important;

          @media (max-width: 1367px) {
            margin-bottom: 15px !important;
          }
        }

        .saved-notification {
          top: 53% !important;
          box-shadow: 1px 1px 2px 1px #808080;
          font-size: 17px !important;
          height: 34px !important;
          width: 115px !important;
          left: calc(50% - 69px) !important;
          letter-spacing: 0.05em;

          @media (max-width: 1367px) {
            top: 53% !important;
            box-shadow: 1px 1px 2px 1px #808080;
            font-size: 17px !important;
            height: 34px !important;
            width: 115px !important;
            left: calc(50% - 69px) !important;
            letter-spacing: 0.05em;
          }
        }

        .copied-notification {
          top: 53% !important;
          box-shadow: 1px 1px 2px 1px #808080;
          font-size: 17px !important;
          height: 34px !important;
          width: 140px !important;
          left: calc(50% - 77px) !important;
          letter-spacing: 0.05em;

          @media (max-width: 1367px) {
            top: 53% !important;
            box-shadow: 1px 1px 2px 1px #808080;
            font-size: 17px !important;
            height: 34px !important;
            width: 140px !important;
            left: calc(50% - 77px) !important;
            letter-spacing: 0.05em;
          }
        }
      }

      .notepad-button-container {
        margin-bottom: 20px;

        @media (max-width: 1367px) {
          margin-bottom: 20px;
        }

        button {
          border-radius: 8px;

          @media (max-width: 1367px) {
            border-radius: 8px;
          }

          p {
            font-weight: 600;

            @media (max-width: 1367px) {
              font-weight: 600;
            }
          }
        }
      }

    }

    .portfolio-briefcase-container {
        position: fixed;
        grid-template-rows: 1fr 9fr 1.25fr 50px;
        display: grid;
        right: 0;
        width: 400px;
        height: 700px;
        background-color: rgb(68, 68, 68);
        border-width: 4px;
        border-left-width: 4px;
        border-color: #b0b0b0;//PortSett
        border-style: solid;
        transition: all 0s;
        overflow: hidden;
        // transform: translateX(100%);
        box-shadow: 0px 3px 12px 2px #3b3b3b;
        border-radius: 12px;
        top: calc(50% - 350px);
        right: calc(50% - 200px);

        @media (max-width: 1367px) {
          transition: all 0s;
          border-left-width: 4px;
          box-shadow: 0px 3px 12px 2px #3b3b3b;
          width: 400px;
          height: 550px;
          top: calc(50% - 300px);
          right: calc(50% - 200px);
          border-radius: 12px;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          width: 250px;
          height: 426px;
          top: calc(50% - 213px);
          right: calc(50% - 125px);
          min-width: 0;
          z-index: 7 !important;
        }
    
        &.active {
          // transform: translateX(0);
          visibility: visible;

          @media (max-width: 1367px) {
            visibility: visible;
          }
        }
    
        &.hidden {
          // transform: translateX(100%);
          transform: translateX(0%);
          visibility: hidden;

          @media (max-width: 1367px) {
            transform: translateX(0%);
            visibility: hidden;
          }
        }

        .page-saved-holder {
          @media (max-width: 450px) and (orientation: portrait) {
            display: none !important;
          }
        }

        .briefcase-action-button-container {

          button {

            p {
              font-weight: 600;
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 15px;

              @media (max-width: 1367px) {
                font-weight: 600;
                margin-block-start: 0px;
                margin-block-end: 0px;
                font-size: 15px;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                font-size: 13px;
              }
            }
          }
        }

        .portfolio-briefcase-label{
            grid-row: 1/2;
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            flex-basis: 12vw;
            z-index: 1;

            div {
              height: 70px !important;
              width: 70px !important;
              margin: 10px 10px 0px !important;

              @media (max-width: 1367px) {
                height: 60px !important;
                width: 60px !important;
                margin: 8px 8px 0px !important;
              }
            }

            p {
              margin: 0px 0px 6px 23px !important;

              @media (max-width: 1367px) {
                margin: 0px 0px 6px 23px !important;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                font-size: 22px;
              }
            }
        }

        .portfolio-briefcase {
          margin-bottom: 48px;
          z-index: 2;
          margin-left: 12px;
          margin-right: 12px;
          grid-column: 1/2;
          width: 94%;
          grid-row: 2/3;            
    

          }

          .briefcase-content-list {
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;          
            width: 94%;
            padding: 0;

            @media (max-width: 1367px) {
              -webkit-mask-image: none !important;
              mask-image: none;
              scrollbar-width: auto;
            }

            .filler-container {
              margin-bottom: 18px !important;

              @media (max-width: 1367px) {
                margin-bottom: 12px !important;
              }
            }
            
            li {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 70px;
              margin: 20px 0;
              background-color: rgb(163, 163, 163);
              color: white;
            }

          .briefcase-content-list-item {
              width:calc(100% - 24px);
              display: flex;
              justify-content: space-between;
              align-items:center;
              height:45px;
              margin-top:1px;
              margin-bottom: 1px;

              @media (max-width: 450px) and (orientation: portrait) {
                height: 20px;
              }
          }
            
          
    
          
        }
        .briefcase-content-list::-webkit-scrollbar{
            width: 0px;
        }
        .briefcase-send-button {
            grid-row: 4/5;

            p {
              @media (max-width: 1367px) {
                font-weight: 600;
                margin-block-start: 0px;
                margin-block-end: 0px;
              }
            }
          }
      }
      
      @keyframes appear-from-left {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
    
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      @keyframes hide-to-right {
        0% {
          opacity: 1;
          transform: translateX(0%);
        }
        100% {
          position: absolute; // setting postion absolute to remove the empty sapce
          left: -100%; // since setting position absolute messes up the transform: translateX, set -100% here too
          opacity: 0;
          transform: translateX(-100%);
        }
      }

      .whole-pdf {
        .pdf-banner {
          .menu-icon-custom {
            .containDiv {
              display: flex;
            }
          }
        }
      }
    }