.portfolio-template.theme11 {
    // override background-color in menu-template
    // background-color: rgb(59, 59, 59);

  .app-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiSlider-track {
      width: 100% !important;
      background-color: transparent !important;
      border-radius: 12px !important;
    }
    
    .MuiSlider-rail {
      width: 100% !important;
      background-color: black !important;
      border-radius: 12px !important;
      border: 2px solid black!important;
      box-shadow: 2px 2px 3px 0px white !important;
      padding-bottom: 8px !important;
    }

    .MuiSlider-thumb {
      margin-left: 4.5px !important;
      box-shadow: 0px 0px 2px 1px #FFFFFF !important;
      height: 14px !important;
      width: 14px !important;
      margin-top: 0px;
    }
    
    .MuiSlider-mark {
      // margin-left: 0px !important;
      margin-top: 4px !important;
      height: 6px !important;
      width: 6px !important;
      border-radius: 50% !important;
      background-color: white !important;
      box-shadow: 1px 1px 1px 1px #3b3b3b;
    }

    .MuiSlider-markLabel {
      left: auto;
      text-shadow: 0px 1px 2px #FFFFFF,
                   0px 2px 1px #DEDEDE;
      color:black !important;
      font-weight: 1000 !important;
      font-family: 'Open Sans', serif !important;
      letter-spacing: 1px !important;
      font-size: 14px !important;
      margin-left: 8px;
  
      @media (max-width: 1366px) and (orientation: landscape) {
        font-size: 10px !important;
      }
    
      @media (max-width: 1024px) and (orientation: portrait) {
        font-size: 10px !important;
      }
    }

    .portfolio-background-image {
      z-index: 0;
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      filter: saturate(30%) opacity(70%);
    }

    .portfolio-header {
      position: fixed;
      top: 0;
      left: 0;
      height: 70px;
      width: 100vw;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 40px;
      }

      .back-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        top: 5px;
        left: 30px;
        cursor: pointer;

        @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          left: 5px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 40px;
          width: 40px;
          top: 0px;
        }

        svg {
          font-size: 36px;
          color: #3b3b3bBF;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            font-size: 28px;
          }
        }
      }

      .home-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        top: 5px;
        left: 100px;
        cursor: pointer;

        @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          left: 70px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 40px;
          width: 40px;
          top: 0px;
        }

        svg {
          font-size: 36px;
          color: #3b3b3bBF;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            font-size: 28px;
          }
        }
      }

      .portfolio-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        height: 60px;
        width: 60px;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 100%;
        }

        img {
          object-fit: contain;
          height: auto;
          width: 100%;
          max-height: 100%;
          max-width: 100%;
        }
      }

      .show-current-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 300px;
        max-width: 300px;
        overflow: hidden;
        position: absolute;
        top: 5px;
        right: 30px;

        @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
          width: 250px;
        }

        @media (max-width: 450px) and (orientation: portrait) {
          display: none;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
          height: 100%;
          width: 220px;
          top: 0;
          right: 10px;
        }

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          font-size: 16px;
          font-family: 'Space Mono', monospace;
          text-align: right;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            font-size: 14px;
          }
        }
      }
    }

    .portfolio-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 70px;
      width: 100vw;
      z-index: 1;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 40px;
      }

      .portfolio-action-menu {
        position: fixed;
        left: 50%;
        bottom: 0;
        z-index: 5;
        height: 94px;
      }

      .portfolio-toolbar {
        position: fixed;
        height: 70px;
        width: 70px;
        z-index: 1;
        bottom: 4px;
        right: 2px;
        background-color: #E2DFDD;
        border: 1px solid #3b3b3b40;
        transition: all 0.2s ease;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: calc(100vh - 8px);

        &.expanded-toolbar {
          transform: translateY(0px);
          width: 400px;
          border-radius: 2px;
          box-shadow: -1px 0px 6px 0px #3b3b3b40;

          @media (max-width: 1366px) and (orientation: landscape) {
            padding-bottom: 25px;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            width: calc(100% - 6px);
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            bottom: 3px;
            padding-bottom: 0px;
          }
        }

        &.retracted-toolbar {
          transform: translateY(calc(100vh - 74px)) translateX(2px);
          border: none;
          border-left: 1px solid #3b3b3b40;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            transform: translateY(calc(100vh - 44px)) translateX(25px);
          }
        }

        .toolbar-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          position: absolute;
          z-index: 1;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            
          }
          
          svg {
            color: #3b3b3bBF;
            font-size: 36px;
            cursor: pointer;
          }

          &.expanded-toolbar {
            left: 5px;
            top: 5px;

            @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
              top: auto;
              bottom: 5px;
            }

            @media (max-width: 1366px) and (orientation: landscape) {
              top: 110px;
            }

            @media (max-width: 1024px) and (orientation: portrait) {
              top: 140px;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              top: 105px;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              top: 5px;
              left: 5px;
            }
          }

          &.retracted-toolbar {
            left: 5px;
            top: 5px;

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              height: 40px;
              width: 40px;
              left: 4px;
              top: 1px;

              svg {
                font-size: 24px;
              }
            }
          }
        }

        .toolbar-title {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          position: absolute;
          z-index: 1;

          p {
            color: #3b3b3bBF;
            font-family: 'Space Mono', monospace;
            font-size: 20px;
            font-size: 600;
          }

          &.expanded-toolbar {
            top: 5px; 
            left: 85px;

            @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
              top: auto;
              bottom: 5px;
            }

            @media (max-width: 1366px) and (orientation: landscape) {
              top: 110px;
            }

            @media (max-width: 1024px) and (orientation: portrait) {
              top: 140px;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              top: 105px;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              top: 5px;
              left: 85px;
            }
          }

          &.retracted-toolbar {
            display: none;
          }
        }

        .icon-flex-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 70px;
          width: 100%;
          height: calc(100% - 70px);
          padding-bottom: 35px;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 100px;
            width: 100%;
            top: calc(50% - 50px);
            padding-bottom: 0px;
            flex-direction: row;
          }

          .icon-and-text-parent {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 4% 0 4% 0;

            @media (max-width: 1366px) and (orientation: landscape) {
              margin: 2% 0%;
            }

            @media (max-width: 1024px) and (orientation: portrait) {
              margin: 2% 0%;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              margin: 0% 2%;
            }

            .test-absolute-text {
              width: 100%;
              height: 30px;
              background-color: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              @media (max-width: 1366px) and (orientation: landscape) {
                height: min-content;
              }

              @media (max-width: 450px) and (orientation: portrait) {
                height: min-content;
              }
              
              p {
                color: #3b3b3bBF;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                font-family: 'Space Mono', monospace;
                letter-spacing: 0.5px;

                @media (max-width: 1366px) and (orientation: landscape) {
                  font-size: 10px;
                  margin-block: 0px;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  font-size: 10px;
                  margin-block: 0px;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  font-size: 9px;
                }
              }
            }

            .menu-icon-custom {

              .containDiv {
                height: 50px !important;
                width: 50px !important;
                background-color: #3b3b3bBF;

                @media (max-width: 1366px) and (orientation: landscape) {
                  height: 42px !important;
                  width: 42px !important;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                  height: 42px !important;
                  width: 42px !important;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 35px !important;
                  width: 35px !important;
                }
              }

              img {
                height: 50px !important;
                width: 50px !important;
                // background-color: #3b3b3bBF;
                color: #3b3b3bBF;
                padding: 0% !important;

                @media (max-width: 1366px) and (orientation: landscape) {
                  height: 42px !important;
                  width: 42px !important;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                  height: 42px !important;
                  width: 42px !important;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  height: 35px !important;
                  width: 35px !important;
                }
              }
            }
          }
        }
      }

      .portfolio-mastermenu-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .portfolio-menu-container {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow-x: auto;
          scrollbar-width: none;
          min-width: 0px;
          max-width: none;
          position: relative;
          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
            background: transparent;
          }

          .portfolio-menu {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: calc(100% - 70px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;
            scrollbar-width: none;
            margin-top: 0px;
            max-height: none;
            padding-left: 70px;
            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
              display: none;
              background: transparent;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              padding-left: 0px;
            }

            .group {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 150px;
              margin-left: 20px;
              margin-right: 20px;
              transition: 0.2s ease all;
              flex-shrink: 0;

              &.hovered {
                background-color: #E2DFDD;
              }

              li {
                height: 100%;
                width: 100%;
              }

              button {
                width: 100% !important;
                height: 100% !important;
                background-color: transparent;
                overflow: hidden;
                margin-top: 0px;
    
                p {
                  text-align: center;
                  transition: all 0.5s ease;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  text-shadow: none !important;
                  font-family: 'Space Mono', monospace !important;
                  font-size: 16px;
                  letter-spacing: 0.1px;
                  // color: #3b3b3b;

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 12px;
                  }
                }
              }

              ul:nth-child(n + 2) {
                display: none !important;
              }
            }
          }
        }
      }

      .portfolio-notepad-container {
        position: fixed;
        display: grid;
        grid-template-rows: 80px auto;
        bottom: 4px;
        right: 2px;
        width: 400px;
        height: calc(100% - 8px);
        transition: all 0.3s;
        transform: translateX(calc(100% + 4px));
        background-color: #E2DFDD;
        box-shadow: -1px 0px 6px 0px #3b3b3b40;
        border: 1px solid #3b3b3b40;

        @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
          display: flex;
        }
    
        &.active {
          transform: translateX(0%);

          @media (max-width: 450px) and (orientation: portrait) {
            width: calc(100% - 6px);
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            bottom: 3px;
            padding-bottom: 0px;
          }
        }
    
        &.hidden {
          transform: translateX(calc(100% + 4px));
        }
    
        // contains md close icon
        .notepad-close-container {
          grid-row: 1 / span 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-right: 10px;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80px;
          }

          .svg-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;

            svg {
              color: #3b3b3bBF;
              font-size: 36px;
              cursor: pointer;
            }
          }

          .text-box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 2%;

            p {
              color: #3b3b3bBF;
              font-family: 'Space Mono', monospace;
              font-size: 20px;
              font-weight: 600;
            }
          }
    
          svg {

          }
        }
    
        .textarea-container {
          grid-row: 2 / span 1;  
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 0px;
          position: relative;
          width: 88%;
          left: 6%;
          height: auto;
          padding-top: 10%;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            position: absolute;
            height: 1200px;
            top: calc(50% - 600px);
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            padding-top: 0%;
          }
    
          .saved-notification {
            @media (min-height: 1025px) and (orientation: portrait) {
              top: 47% !important;
            }
          }
    
          .copied-notification {
            @media (min-height: 1025px) and (orientation: portrait) {
              top: 47% !important;
            }
          }
    
          .quill-container {
            background-color: white;
            height: 60%;
            display: inline-flex;
            width: 100%;
          }
    
          .scriptnote-text-container {
            background-color: white;
            height: 60%;
            display: inline-flex;
            width: 100%;
            overflow-y: auto;
    
            p {
              margin-block-start: 0px;
              margin-block-end: 0px;
              padding: 2%;
              font-family: 'Roboto Slab', serif;
              font-size: 14px;
            }
          }
    
          .page-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 0%;
            width: 100%;
            margin-bottom: 10px;
    
            p {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              font-weight: 400;
              font-family: 'Space Mono', sans-serif;
              color: #3b3b3b;
            }
          }
    
          .notepad-button-container-8 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-top: 6%;

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              margin-top: 8px;
              height: min-content;
            }
      
            button {
              width: 45%;
              font-family: 'Merriweather Sans', sans-serif;
              transition: all 0.3s ease;
              font-size: 12px;
              border-radius: 4px;
              margin: 0px 2px 0px 2px;
              background-color: #3b3b3b40;
              border: 1px solid #00000013;
              box-shadow: 1px 1px 3px #3b3b3b40;

              @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                height: 30px !important;
              }

              div {
                .title {
                  font-size: 18px !important;
                  font-family: 'Merriweather Sans', sans-serif !important;
                  color: #3b3b3b !important;
                }
              }
            }
    
            &:hover {
    
            }
          }
        }
      }

      .modal {
        position: fixed;
        display: grid;
        grid-template-rows: 80px auto;
        bottom: 4px;
        left: auto;
        right: 2px;
        width: 400px;
        height: calc(100% - 8px);
        transition: all 0.3s;
        transform: translateX(calc(100% + 4px));
        background-color: #E2DFDD;
        box-shadow: -1px 0px 6px 0px #3b3b3b40;
        border: 1px solid #3b3b3b40;

        @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
          display: flex;
        }
    
        &.active {
          transform: translateX(0%);

          @media (max-width: 450px) and (orientation: portrait) {
            width: calc(100% - 6px);
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            bottom: 3px;
            padding-bottom: 0px;
          }
        }
    
        &.hidden {
          transform: translateX(calc(100% + 4px));
        }
    
        .modal-close {
          grid-row: 1 / span 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-right: 10px;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            height: 80px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
          }
    
          .svg-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;

            svg {
              color: #3b3b3bBF;
              font-size: 36px;
              cursor: pointer;
            }
          }

          .text-box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 2%;

            p {
              color: #3b3b3bBF;
              font-family: 'Space Mono', monospace;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
    
        .modal-body {
          grid-row: 2 / span 1;  
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding-top: 10%;
          position: relative;
          width: 100%;
          left: 0;
          height: auto;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            height: 500px;
            position: absolute;
            top: calc(50% - 250px);
            padding-top: 0%;
          }

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            padding-top: 0px;
          }
    
          .modal-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
            top: auto;
            left: auto;
            transform: translate(0%, 0%);
            min-height: 0px;
            background-color: transparent;
            border-radius: 0px;
            padding: 0px;

            .customer-info-form-container {
              position: initial;
              top: auto;
              left: auto;
              min-height: 0;
              transform: none;
              width: 90%;
              height: min-content;
              border-radius: 4px;
              padding: 0px;
              padding-bottom: 4%;
              background-color: white;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;

              @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                padding-bottom: 7px;
              }

              .customer-info-form {
                justify-content: space-around;
                height: 100%;
                width: 85%;
      
                @media (min-height: 1025px) and (orientation: portrait) {
                  justify-content: flex-start;
                }
      
                .add-cc-email-text { 
                  color: #3b3b3bBF;
                  text-decoration: underline;
                  margin-top: 10px;
                  font-family: 'Merriweather Sans', sans-serif;
                  font-weight: 600;
      
                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    display: none;
                  }
                }
      
                .input-container {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  height: min-content;
                  width: 100%;
                  // margin-bottom: 10px;
  
                  .title-box {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 25px;
                    width: 100%;

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      display: none;
                    }
  
                    p {
                      font-family: 'Merriweather Sans', sans-serif;
                      color: #3b3b3b80;
                      font-size: 12px;
                      margin-block: 0px;
                      letter-spacing: 0.5px;
                    }
                  }
      
                  input {
                    height: 50px;
                    width: 100%;
                    font-family: 'Merriweather Sans', sans-serif;
                    margin-bottom: 0px;

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      height: 30px;
                    }
                  }
      
                  p {
                    width: 100%;
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    font-weight: 600;
                    font-size: 14px;
                    font-family: 'Merriweather Sans', sans-serif;
      
                    @media (min-height: 1025px) and (orientation: portrait) {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
    
            button {
              position: static !important;
              width: 90%;
              height: 50px;
              bottom: auto !important;
              // background-color: #3b3b3b40 !important;
              border: 1px solid #00000013;
              box-shadow: 1px 1px 3px #3b3b3b40;
              // color: #3b3b3b !important;
              margin-top: 10%;

              @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                margin-top: 2%;
                height: 40px;
              }

              div {
                color: #3b3b3b !important;
                font-family: 'Merriweather Sans', sans-serif;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .portfolio-content-parent {
      position: fixed;
      top: 70px;
      left: 0;
      margin-top: 0px;
      margin-bottom: 0px;
      height: calc(100vh - 140px);
      width: 100vw;
      z-index: 1;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: calc(100vh - 80px);
        top: 40px;
      }

      .portfolio-opacity-layer {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #3b3b3b;
        opacity: 0.6;
      }

      .portfolio-main-content {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 2px);
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #a6a6a6;
        border-top: 1px solid #a6a6a6;

        .back-button {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          bottom: 5px;
          left: 30px;
          cursor: pointer;
          display: none;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            display: flex;
            z-index: 1;
          }
  
          svg {
            font-size: 36px;
            color: #FFFFFFBF;
          }
        }
  
        .home-button {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          bottom: 5px;
          left: 100px;
          cursor: pointer;
          display: none;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            display: flex;
            z-index: 1;
          }
  
          svg {
            font-size: 36px;
            color: #FFFFFFBF;
          }
        }

        .portfolio-big-nav-prev-container { //SUB-LEVEL CONTENT
          position: absolute;
          width: 100vw;
          height: 150px;
          left: 0;
          top: calc(50% - 75px);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow-x: auto;
          overflow-y: hidden;

          @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
            top: 40px;
            height: calc(90% - 80px);
            width: 80%;
            left: 10%;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            padding-top: 15%;
          }

          @media (max-width: 450px) and (orientation: portrait) {
            top: 40px;
            height: calc(100% - 80px);
            width: 80%;
            left: 10%;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
          }

          &.small {
            justify-content: center;

            @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
              justify-content: flex-start;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              justify-content: flex-start;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              justify-content: flex-start;
            }
          }

          &.large {
            justify-content: flex-start;
          }

          .big-nav {
            height: 100%;
            width: 300px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 8px 0px 8px !important;
            flex-shrink: 0;

            @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
              height: 200px;
            }

            @media (max-height: 1024px) and (orientation: portrait) {
              width: 220px !important;
            }

            @media (max-width: 450px) and (orientation: portrait) {
              width: 100% !important;
              margin: 8px 0px !important;
              height: 80px;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              width: 200px !important;
              margin: 0px 8px 0px 8px;
            }
          }
        }

        .portfolio-terminus-nav-container { //TERMINUS-LEVEL-CONTAINER
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow-x: auto;
          overflow-y: hidden;
          padding-left: 1.5%;
          position: relative;

          @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            padding-bottom: 45px;
          }

          .portfolio-content-container-background {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 2;
            background-color: #3b3b3b80;
            left: 0;
            top: 0;
            transition: 0.3s ease all;

            &.submenu {
              display: none;
              visibility: hidden;
              opacity: 0;
            }

            &.terminus {
              display: flex;
              visibility: visible;
              opacity: 1;
            }
          }

          .portfolio-content-container {
            position: fixed;
            top: 4vh;
            left: 7vw;
            width: 86vw;
            height: 92vh;
            z-index: 3;
            transition: 0.3s ease all;
            background-color: white;
            border-radius: 8px;
            box-shadow: 2px 2px 8px 1px #3b3b3b;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media (max-width: 1366px) and (orientation: landscape) {
              top: 13vh;
              height: 74vh;
            }

            @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
              height: 40vh;
              top: 30vh;
            }

            @media (max-width: 1024px) and (orientation: portrait) {
              top: 20vh;
              height: 60vh;
              left: 3vw;
              width: 94vw;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              top: 6vh;
              left: 10vw;
              width: 80vw;
              height: 79vh;
              justify-content: flex-start;
            }

            &.submenu {
              display: none;
              visibility: hidden;
              opacity: 0;
            }

            &.terminus {
              display: flex;
              visibility: visible;
              opacity: 1;
            }

            .portfolio-title-wrapper {
              height: 70px;
              width: 100%;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;

              @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                height: 30px;
                margin: 3px 0px;
              }

              .title-box {
                max-width: 60%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  height: 100%;
                }
                
                p {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  width: 100%;
                  font-size: 20px;
                  font-family: 'Space Mono', monospace;
                  text-align: center;
                }
              }

              .close-button {
                position: absolute;
                height: 60px;
                width: 60px;
                top: calc(50% - 30px);
                right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  height: 100%;
                  width: 30px;
                  top: auto;
                  right: 5px;
                }

                svg {
                  font-size: 44px;
                  color: #3b3b3b80;
                  cursor: pointer;

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 28px;
                  }
                }
              }
            }

            .portfolio-content-wrapper {
              height: calc(100% - 70px);
              width: 100%;
              background-color: #3b3b3b13;
              display: flex;
              justify-content: center;
              align-items: flex-start;

              .portfolio-content {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 90% !important;
                margin-top: 10px;
                flex-direction: column;

                .iframe-container {
                  height: 100%;
                  width: 100%;
                  position: relative;
                }

                .portfolio-gallery-view {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;

                  .carousel-root {
                    height: 100%;
                    width: 100%;                    
                  }

                  .thumb-container {
                    position: absolute;
                    z-index: 1;
                    bottom: -63px;
                    width: 100%;
                    // background-color: #3b3b3b40;
                    height: 60px;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      height: 50px;
                      bottom: -43px;
                    }

                    @media (max-width: 1024px) and (orientation: portrait) {
                      height: 50px;
                      bottom: -43px;
                    }

                    @media (max-width: 900px) and (orientation: portrait) {
                      bottom: -45px;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      bottom: -80px;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      bottom: -62px;
                    }

                    .menu-icon-custom {

                      .containDiv {
                        background-color: #3b3b3b80 !important;

                        @media (max-width: 1366px) and (orientation: landscape) {
                          height: 55px !important;
                          width: 55px !important;
                        }
      
                        @media (max-width: 1024px) and (orientation: portrait) {
                          height: 55px !important;
                          width: 55px !important;
                        }
                      }
                    }

                    .Gallery-Container {
                      max-width: 55px !important;
                      min-width: 55px !important;
                      height: 55px !important;

                      @media (max-width: 1366px) and (orientation: landscape) {
                        max-width: 35px !important;
                        min-width: 35px !important;
                        height: 35px !important;
                      }

                      @media (max-width: 1024px) and (orientation: portrait) {
                        max-width: 40px !important;
                        min-width: 40px !important;
                        height: 40px !important;
                      }

                      .portfolio-single-image {
                        height: 100%;
                      }
                    }
                  }

                  .page-count-container {
                    display: none !important;
                  }
                }
              }
            }
          }

          .terminus-level-thumb {
            height: 425px;
            width: 475px;
            margin: 0% 1.5%;
            box-shadow: 3px 3px 8px 1px #3b3b3bBF;
            cursor: pointer;
            border-radius: 2px;
            flex-shrink: 0;

            @media (max-width: 1366px) and (orientation: landscape) {
              height: 350px;
              width: 385px;
            }

            @media (max-width: 1150px) and (orientation: landscape) {
              height: 300px;
              width: 325px;
            }

            @media (max-width: 1024px) and (orientation: portrait) {
              height: 350px;
              width: 385px;
            }

            @media (max-width: 768px) and (orientation: portrait) {
              height: 300px;
              width: 325px;
            }

            @media (max-width: 450px) and (orientation: portrait) {

            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
              height: 225px;
              width: 250px;
            }

            &:hover {
              filter: drop-shadow(0 0 0.9rem #0769AF);
            }

            .big-nav {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              border-radius: 2px;

              .title-overlay {
                position: absolute;
                width: 100%;
                height: 17%;
                left: 0%;
                bottom: 2%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                overflow: hidden;
                // background-color: #3b3b3b40;

                p {
                  color: white;
                  font-size: 34px;
                  width: 80%;
                  font-family: 'Source Serif 4', sans-serif;
                  margin-block-start: 0px;
                  margin-block-end: 0px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  text-align: left;
                  letter-spacing: 1px;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    font-size: 24px;
                  }

                  @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 24px;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 18px;
                  }
                }

                .sub {
                  font-size: 22px;
                  font-weight: 400;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    font-size: 18px;
                  }

                  @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 18px;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 15px;
                  }
                }
              }

              .icon-overlay {
                position: absolute;
                height: 40%;
                width: 100%;
                bottom: 30%;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  font-size: 64px;
                  color: black;
                }
              }

              .portfolio-content {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: visible !important;
                height: 100%;
                width: 100%;
                cursor: pointer;
                position: relative;
                object-fit: contain;

                img {
                  object-fit: cover;
                }
              }
            }
          }
        }
        
        .portfolio-big-nav-container { //TOP-LEVEL CONTENT
          display: flex;
          justify-content: flex-start;
          align-content: center;
          flex-direction: row;
          overflow-y: hidden;
          overflow-x: scroll;
          width: 100%;
          height: 100%;
          padding: 0%;
          z-index: 1;
          // scrollbar-width: none;
          position: relative;
          // &::-webkit-scrollbar {
          //   width: 0px;
          //   height: 0px;
          //   display: none;
          //   background: transparent;
          // }

          &.two {
            
          }

          &::-webkit-scrollbar {
            transition: 0.3 ease all;
            height: 10px;
            display: block !important;
          }
        
          &::-webkit-scrollbar-track {
            background: #FFFFFFBF;
          }
        
          &::-webkit-scrollbar-thumb {
            background: #3b3b3b80;
            transition: 0.3 ease all;
          }
        
          &::-webkit-scrollbar-thumb:hover {
            background: #3b3b3b40;
          }
    
          // EVERYTHING INSIDE .GROUP IS FOR TOP-LEVEL ELEMENTS
          .group {
            display: flex;
            flex-direction: column;
            align-items: center !important;
            justify-content: center;
            height: 100%;
            width: min-content;
            // width: 22.85%;
            // hide all ul under, aka level 1 and up will be hidden
    
            .menu-section {
              height: 100%;
              width: 31vw;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              position: relative;
              overflow: visible;
              cursor: pointer;

              &.one {
                width: 100vw;
              }

              &.two {
                width: 50vw;

                @media (max-width: 1024px) and (orientation: portrait) {
                  width: 60vw;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  width: 80vw;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  width: 50vw;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  width: 80vw;
                }
              }

              &.three {
                width: 33.33vw;

                @media (orientation: landscape) and (max-width: 1366px) {
                  width: 45vw;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                  width: 60vw;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  width: 80vw;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  width: 80vw;
                }
              }

              &.four-plus {
                width: 31vw;

                @media (orientation: landscape) and (max-width: 1366px) {
                  width: 45vw;
                }

                @media (max-width: 1024px) and (min-width: 451px) and (orientation: portrait) {
                  width: 70vw;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  width: 80vw;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  width: 80vw;
                }
              }

              .inner-menu-section {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                position: relative;
                overflow: visible;
                height: 100%;
                width: 88%;
                

                &.bottom-image {

                }

                &.top-image {

                }
              }
            }

            .top-level-overlay {
              background-color: #3b3b3b66;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              position: absolute;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              overflow: hidden;
              
              p {
                color: white;
                margin-block-start: 0px;
                margin-block-end: 0px;
                text-align: center;
                width: 80%;
              }

              &.one {
                p {
                  width: 60%;
                }
              }

              .overlay-title {
                font-size: 48px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-family: 'Libre Baskerville', serif;
                letter-spacing: 0.5px;

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  -webkit-line-clamp: 2;
                  font-size: 26px;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  font-size: 30px;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  font-size: 72px;
                }
              }
        
              .overlay-subtitle {
                font-size: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                font-family: 'Space Mono', monospace;
                letter-spacing: 0.5px;

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  -webkit-line-clamp: 1;
                  font-size: 17px;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  font-size: 18px;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  font-size: 36px;
                }
              }

              .overlay-description {
                margin-top: 20px;
                font-size: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                font-family: 'Libre Baskerville', serif;
                // text-align: left;

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  -webkit-line-clamp: 3;
                  margin-top: 5px;
                  font-size: 12px;
                }

                @media (max-width: 450px) and (orientation: portrait) {
                  font-size: 14px;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  font-size: 28px;
                }
              }

              &.bottom-image {
                background-color: transparent;
                height: 45%;
                width: 100%;
                top: 5%;

                @media (max-width: 1366px) and (orientation: landscape) {
                  height: 35%;
                }
    
                @media (max-width: 1024px) and (orientation: portrait) {
                  height: 35%;
                }
                

                &.two {
                  height: 35%;
                  top: 0%;
                  

                  @media (max-width: 1366px) and (orientation: landscape) {
                    height: 25% !important;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    height: 25% !important;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    height: 35% !important;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    top: 5%;
                  }

                  @media (max-width: 450px) and (orientation: portrait) {
                    top: 2%;
                  }
                }

                &.one {
                  height: 100%;
                  background-color: #3b3b3b66;
                }

                p {
                  color: #3b3b3b !important;
                  width: 100%;
                }

                &.one {
                  top: 0%;

                  p {
                    width: 60%;
                    color: white !important;
                  }
                }

                .overlay-title {
                  font-size: 48px;
                  font-style: normal;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    font-size: 30px;
                    -webkit-line-clamp: 1;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 30px;
                    -webkit-line-clamp: 1;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 24px;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    font-size: 72px;
                    -webkit-line-clamp: 2;
                  }
                  
                  &.two {
                    text-align: left;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      font-size: 26px;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      font-size: 26px;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      -webkit-line-clamp: 2;
                    }

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      font-size: 72px;
                      -webkit-line-clamp: 2;
                    }
                  }
                }

                .overlay-subtitle {
                  font-size: 20px;

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 14px;
                    -webkit-line-clamp: 1;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    font-size: 38px;
                    -webkit-line-clamp: 2;
                  }

                  &.two {
                    font-size: 28px;
                    text-align: left;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      font-size: 22px;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      font-size: 22px;
                    }

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      font-size: 38px;
                      -webkit-line-clamp: 2;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      -webkit-line-clamp: 2;
                      font-size: 18px;
                    }
                  }
                }

                .overlay-description {

                  @media (max-width: 1366px) and (orientation: landscape) {
                    font-size: 14px;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 14px;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 12px;
                    -webkit-line-clamp: 3;
                    margin-top: 5px;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    font-size: 24px;
                  }

                  &.two {
                    text-align: left;
                    font-size: 18px;

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      font-size: 24px;
                    }

                    @media (max-width: 1366px) and (orientation: landscape) {
                      font-size: 12px;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      font-size: 12px;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      -webkit-line-clamp: 3;
                      margin-top: 5px;
                    }
                  }
                }
              }

              &.top-image {
                background-color: transparent;
                height: 25%;
                width: 100%;
                bottom: 0%;
                top: auto;

                @media (max-width: 1366px) and (orientation: landscape) {
                  bottom: 15%;
                }
    
                @media (max-width: 1024px) and (orientation: portrait) {
                  bottom: 15%;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                  bottom: 15%;
                }

                @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                  height: 30%;
                }

                &.two {
                  height: 35%;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    bottom: 22%;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    bottom: 22%;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    bottom: 20%;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    height: 30%;
                  }
                }

                &.one {
                  height: 100%;
                  background-color: #3b3b3b66;

                  p {
                    width: 60%;
                    color: white !important;
                  }
                }

                p {
                  width: 100%;
                }

                .overlay-title {
                  font-size: 30px;
                  font-style: normal;
                  text-align: left;
                  -webkit-line-clamp: 1;

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 20px;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    font-size: 60px;
                    -webkit-line-clamp: 2;
                  }

                  &.two {
                    font-size: 48px;

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      font-size: 60px;
                      -webkit-line-clamp: 2;
                    }

                    @media (max-width: 1366px) and (orientation: landscape) {
                      font-size: 30px;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      font-size: 30px;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      font-size: 24px;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      -webkit-line-clamp: 2;
                      font-size: 22px;
                    }
                  }

                  &.one {
                    font-size: 48px;
                    text-align: center;
                  }
                }

                .overlay-subtitle {
                  font-size: 16px;
                  text-align: left;
                  -webkit-line-clamp: 1;

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 14px;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    font-size: 34px;
                  }

                  &.two {
                    font-size: 24px;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      font-size: 22px;
                    }

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      font-size: 34px;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      font-size: 22px;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      font-size: 14px;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      -webkit-line-clamp: 2;
                      font-size: 18px;
                    }
                  }

                  &.one {
                    font-size: 24px;
                    text-align: center;
                  }
                }

                .overlay-description {
                  margin-top: 5px;
                  font-size: 16px;
                  -webkit-line-clamp: 2;
                  text-align: left;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    font-size: 14px;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 14px;
                  }

                  @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    font-size: 12px;
                    margin-top: 0px;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    font-size: 24px;
                  }

                  &.two {
                    font-size: 18px;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      font-size: 14px;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      font-size: 14px;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      font-size: 12px;
                    }

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      font-size: 24px;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      -webkit-line-clamp: 3;
                      font-size: 12px;
                    }
                  }

                  &.one {
                    font-size: 18px;
                    text-align: center;
                    -webkit-line-clamp: 4;
                  }
                }

                p {
                  color: #3b3b3b !important;
                }
              }
            }
    
            div:nth-child(n + 2) {
              display: none; // THIS MEANS NO CHILDREN WILL APPEAR. THIS IS THE ONLY THING THAT MAKES IT SO THE TOP LEVEL ITEMS ARE THE ONLY ONES RENDERED.
              height: 225px;
            }
    
            .big-nav {
              overflow: hidden;
              position: relative;
              height: 100%;
              width: 100%;
              // border-radius: 12px;
    
              @media (max-width: 450px) and (orientation: portrait) {
                overflow: visible !important;
              }
            }
    
            .portfolio-content {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              overflow: visible !important;
              height: 100%;
              width: 100%;
              cursor: pointer;
              position: relative;
              object-fit: cover;
    
              @media (max-width: 450px) and (orientation: portrait) {
                // overflow: visible !important;
              }
    
              button {
    
                p {
                  font-size: 1.4em;
                }
              }

      
              .portfolio-single-image, .mux-player {
                flex-shrink: 0;
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
                // switch back to fill if disliked

                &.bottom-image {
                  position: absolute;
                  height: 45% !important;
                  bottom: 5%;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    height: 35% !important;
                    bottom: 20%;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    height: 35% !important;
                    bottom: 20%;
                  }

                  &.two {
                    height: 60% !important;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      height: 42% !important;
                      bottom: 30%;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      height: 42% !important;
                      bottom: 30%;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                      bottom: 20% !important;
                      height: 35% !important;
                    }

                    @media (max-width: 450px) and (orientation: portrait) {
                      bottom: 25% !important;
                    }
                  }

                  &.one {
                    height: 100% !important;
                    bottom: 0%;
                  }
                }

                &.top-image {
                  position: absolute;
                  height: 80% !important;
                  top: 0%;

                  @media (max-width: 1366px) and (orientation: landscape) {
                    height: 60% !important;
                  }
      
                  @media (max-width: 1024px) and (orientation: portrait) {
                    height: 60% !important;
                  }

                  @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                    height: 70% !important;
                  }

                  &.two {
                    top: 5%;
                    height: 60% !important;

                    @media (max-width: 1366px) and (orientation: landscape) {
                      height: 42% !important;
                    }
        
                    @media (max-width: 1024px) and (orientation: portrait) {
                      height: 42% !important;
                    }

                    @media (min-width: 1080px) and (min-height: 1920px) and (orientation: portrait) {
                      height: 70% !important;
                    }
                  }

                  &.one {
                    height: 100% !important;
                    top: 0%;
                  }
                }
              }
    
              .iframe-container {
                position: relative;
                width: 100%;
                height: 100%;
      
                .website-link {
                  height: 20px;
                }
      
                iframe {
                  display: block;
                  background: transparent;
                  border: none;
                  width: 100%;
                  height: 100%;
                  scrollbar-width: none;
                  -webkit-scrollbar {
                    width: 0px;
                  }
                }
              }
    
              .website-content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
      
                p {
                  margin-block-start: 0px;
                  margin-block-end: 0px;
                  font-family: 'Oswald', sans-serif;
                  font-size: 4em;
                  letter-spacing: 0.1em;
                  font-weight: 600;
                  color: white;
                  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
                }
              }
      
              // hide video control in nav
              video::-webkit-media-controls-enclosure {
                // display: none !important;
              }
            }
          }
        }
      }
    }
  }
}