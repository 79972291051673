.portfolio-template.theme5 {
    // override background-color in menu-template
    background-color: rgb(59, 59, 59);

    .portfolio-action-menu {

      .action-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 330px;
  
        .exit-button {
          margin-top: auto;
        }
  
        .row-first {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
  
        .row-second {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
        }
      }
    }

    .portfolio-menu-container {

        display: grid;
        grid-template-rows: 1fr 7fr;


        .logo {

          object-fit: contain;
          // height: 150px;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          grid-row: 1/2;
          position: unset;
          .top-gradient {

          }
        }

        .menus-container {

          grid-row: 2/2;

          .back-container {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            padding-top: 40px;
            padding-bottom: 55px;
            height: 60vh;
            max-height: 90vh;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -webkit-scrollbar {
              width: 0px;
            }
            .back-container:hover{
              filter: brightness(115%);
                transform: scale(1.05, 1.05);
            }
      
            img {
              height: 100px;
              width: 100px;
              transition: all 0.4s;
              cursor: pointer;
            }
      
            // img:hover {
            //   filter: brightness(115%);
            //   transform: scale(1.05, 1.05);
            // }
          }

          .portfolio-menu {
            // change hidden and active to be button specific
            overflow-y: scroll;
            mask-image: url("../../assets/images/MenuMask.png");
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            -webkit-mask-image: url("../../assets/images/MenuMask.png");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            height: 80vh;
            max-height: 90vh;
            padding-top: 50px;
            scrollbar-width: none;
            -webkit-scrollbar {
              width: 0px;
            }
            &.isAtEnd{              
              opacity: 0;
            }            
            right: 5.5%;

            .active {
              display: flex;
              visibility: visible;
              opacity: 1;
              animation: appear-from-left 0.5s forwards;
            }
      
            .hidden {
              display: none;
              animation: hide-to-right 0.5s forwards;
            }
                // all button under menu


    button {
      // add small animation
      transition: all 0.5s;
      &:hover {
        margin-right: 10px;
      }
       }
          
            

        }
        }

    
    
        // change all button color under menu red
        button {
            margin-top: 8px;
        }

        
    }
  
    .portfolio-content-parent {
      // change all button color under portfolio-content-parent yellow
      button {
        background-color: rgb(55, 128, 52);
        margin-top: 15px;
      }

    }

    .portfolio-briefcase-container {
        position: fixed;
        grid-template-rows: 1fr 9fr 1.25fr 50px;
        display: grid;
        right: 0;
        width: 19.8vw;
        height: 100%;
        background-color: rgb(68, 68, 68);
        border-width: 4px;
        border-left-width: 8px;
        border-color: #b0b0b0;//PortSett
        border-style: solid;
        transition: all 1s;
        overflow: hidden;
        transform: translateX(100%);
    
        &.active {
          transform: translateX(0);
        }
    
        &.hidden {
          transform: translateX(100%);
        }
        .portfolio-briefcase-label{
            grid-row: 1/2;
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            flex-basis: 12vw;
            z-index: 1;
        }
        .portfolio-briefcase {
          margin-bottom: 48px;
          z-index: 2;
          margin-left: 12px;
          margin-right: 12px;
          grid-column: 1/2;
          width: 94%;
          grid-row: 2/3;            
    

          }
          .briefcase-content-list {
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;
            scrollbar-width: none;            
            width: 94%;
            padding: 0;
            
            li {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 70px;
              margin: 20px 0;
              background-color: rgb(163, 163, 163);
              color: white;
            }
          .briefcase-content-list-item {
            width:calc(100% - 24px);
            display: flex;
            justify-content: space-between;
            align-items:center;
            height:45px;
            margin-top:1px;
            margin-bottom:1px
            }
            
          
    
          
        }
        .briefcase-content-list::-webkit-scrollbar{
            width: 0px;
        }
        .briefcase-send-button {
            grid-row: 4/5;
          }
      }
      
      @keyframes appear-from-left {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
    
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      @keyframes hide-to-right {
        0% {
          opacity: 1;
          transform: translateX(0%);
        }
        100% {
          position: absolute; // setting postion absolute to remove the empty sapce
          left: -100%; // since setting position absolute messes up the transform: translateX, set -100% here too
          opacity: 0;
          transform: translateX(-100%);
        }
      }
    }