$Brightness: 0%;
#Test{
    // hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.Gallery-Container img{
  height: 100%;
  object-fit: contain;
}
.GuestBox {
  div {
    text-align: center;
  }
}

.MuiSlider-track {
  width: 20px !important;
  background-color: transparent !important;
  border-radius: 12px !important;
}

.MuiSlider-rail {
  width: 20px !important;
  background-color: black !important;
  border-radius: 12px !important;
  border: 2px solid black!important;
  box-shadow: 2px 2px 3px 0px white !important;
  padding-bottom: 8px !important;
}

.MuiSlider-thumb {
  margin-left: 4.5px !important;
  box-shadow: 0px 0px 2px 1px #FFFFFF !important;
  height: 15px !important;
  width: 15px !important;
}

.MuiSlider-mark {
  margin-left: 9px !important;
  height: 6px !important;
  width: 6px !important;
  border-radius: 50% !important;
  background-color: white !important;
  box-shadow: 1px 1px 1px 1px #3b3b3b;
}

.MuiSlider-markLabel {
  left: 50px;
  text-shadow: 0px 1px 2px #FFFFFF,
               0px 2px 1px #DEDEDE;
  color:black !important;
  font-weight: 1000 !important;
  font-size: 20px !important;
  font-family: 'Open Sans', serif !important;
  letter-spacing: 1px !important;

  @media (max-width: 1024px) and (min-height: 1025px) {
    font-size: 16px !important;
  }

  @media (max-width: 1024px) and (max-height: 1024px) {
    font-size: 15px !important;
  }

  @media (max-width: 768px) and (max-height: 1024px) {
    font-size: 11px !important;
    text-shadow: 0px 1px 1px #FFFFFF;
  }
}

.dashboard-revamp-page {
  display: grid;
  grid-template-rows: calc(100vh / 8.5 * 1.5) calc(100vh / 8.5 * 7);
  height: 100vh;
}


.dashboard-revamp-body {
  grid-row: 2 / span 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 150px auto 150px;
  overflow-y: hidden;
  // scrollbar-width: none;
  // -ms-overflow-style: none;
  // &::-webkit-scrollbar {
  //   width: 0px;
  //   background: transparent;
  // }

  @media (max-width: 1380px) {
    overflow-y: auto;
  }

  .dashboard-body-left {
    max-height: calc(100% / 8.5 * 7);
  }

  .dashboard-body-main {
    //max-height: calc(100% / 8.5 * 7);
  }

  .dashboard-body-right {
    max-height: calc(100% / 8.5 * 7);
  }
}
.is-momentumScrollable{
  -webkit-overflow-scrolling: touch;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: transparent;
}
.portfolio-template .portfolio-content-parent .portfolio-content-container .portfolio-content .portfolio-gallery-view .portfolio-single-image{
  height: 100px;
  object-fit: contain;
}
.portfolio-template {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .portfolio-menu-container {
    position: relative;
    min-width: 360px;
    max-width: 360px;

    .logo {
      position: absolute;
      top: 0;
      width: 100%;
      height: 120px;
.logo-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    
    
        
      

    .portfolio-menu {
      position: relative;
      max-height: calc(100% - (320px + 128px)); // 320 from action 128 from logo
      overflow: auto;
      margin-top: 128px;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      // target isAtEnd specifically at .portfolio-page-menu
      &.isAtEnd {
        display: none;
      }

      .hidden {
        display: none; // need !important prevent override from flex;
        visibility: collapse;
      }

      .active {
        display: block;
        visibility: visible;
      }

      // group for each level
      .group {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &.level-0 {
        }

        &.level-1 {
        }

        &.level-2 {
          // Change all button that are under group level-2
          button {
            width: 220px;
            filter: brightness(calc(100% + #{$Brightness * 2}));
          }
        }

        &.level-3 {
          button {
            width: 195px;
            filter: brightness(calc(100% + #{$Brightness * 2.1}));
            height: 80px;
          }
        }

        &.level-4 {
          button {
            width: 170px;
            filter: brightness(calc(100% + #{$Brightness * 2.1}));
            height: 80px;
          }
        }
      }

      // button for each level
      button {
        // change buttons in level-0
        &.level-0 {
          width: 300px;
        }

        &.level-1 {
          width: 250px;
          filter: brightness(calc(100% + #{$Brightness * 1}));
        }
      }
    }

    // hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .HideScrollbar{
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .portfolio-content-parent {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .portfolio-content-menu-container {
      position: relative;
      width: 330px;

      .portfolio-content-menu {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 80%;
        max-height: 80%;
        overflow: auto;
        padding: 128px 0 64px 24px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        // hide scrollbar
        ::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        button {
          margin-top: 8px;
        }
      }
    }

    .portfolio-content-container {
      width: 100%;
      height: 100%;

      .portfolio-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        //padding: 5% 5% 5% 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        .html-block-content {
          width: 100%;
          height: 100%;
          overflow: auto;

          > div:nth-of-type(1) {
            width: 100%;
            height: calc(
              100% - 30px
            ); // need to give bottom space since block content creates <p>
          }

          // make image in html Image link to take full width
          img {
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }

        .portfolio-gallery-view {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;

          .hovering-chevron {
            display: none;
          }

          .slider-wrapper {
            height: 100%;
          }
          .carousel-root {
            height: calc(100% - 160px);
          }
          .carousel.carousel-slider {
            //width: 80% !important;
            overflow: visible;
            max-height: calc(100vh - 130px); // save 130px for thumbnail space
            height: 100%;
          }
          .slider-wrapper.axis-horizontal * {
            height: 100%;
            object-fit: contain;
          } 

          ul.thumbs {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          li.thumb {
            width: 100px !important;
            overflow: hidden;
            height: 70px;
          }

          // force carousel slide first div to have set height
          // li.slide > div:nth-of-type(1) {
          //   width: 100%;
          //   height: calc(100vh - 130px); // height subtract thumbnail space;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          // }

          .portfolio-single-image {
            width: 100%;
          }
        }

        .iframe-container {
          position: relative;
          width: 100%;
          height: 100%;

          .website-link {
            height: 20px;
          }

          iframe {
            display: block; /* iframes are inline by default */
            background: transparent;
            border: none; /* Reset default border */
            width: 100%;
            height: calc(100%);
          }
          embed{
            display: block; /* iframes are inline by default */
            background: transparent;
            border: none; /* Reset default border */
            width: 100%;
            height: calc(100%);
          }
        }

        > .portfolio-single-image {
          // width: 100%;
          // height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .portfolio-action-menu {
    .action-menu-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 330px;

      .exit-button {
        margin-top: auto;
      }

      .row-first {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
      }

      .row-second {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
      }

      .row-third {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
      }
    }
  }

  .portfolio-briefcase-container {
    position: fixed;
    right: 0;
    min-width: 392.15px;
    width: 300px;
    height: 100%;
    background-color: rgb(68, 68, 68);
    transition: all 1s;
    transform: translateX(calc(100% + 28px));
    z-index: 98;

    &.active {
      transform: translateX(0);
    }

    &.hidden {
      transform: translateX(calc(100% + 28px));
    }

    // contains md close icon
    .briefcase-close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      z-index: 2;
    }

    // content of briefcase
    .portfolio-briefcase {
      position: relative;
      width: 100%;
      height: 100%;

      .briefcase-content-list {
        position: absolute;
        top: 60px;
        width: 90%;
        max-height: 75%;
        overflow-y: auto;
        padding: 10px;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 70px;
          margin: 20px 0;

          .briefcase-close-item {
            // close icon for each item
            .menu-icon-md {
              width: 40px;
              height: 40px;
            }
          }

          p {
            margin-left: 10px;
          }
        }
      }

      // has send and clear all button
      .briefcase-action-button-container {
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        bottom: 0px;
        width: 100%;

        button {
          width: 40%;
        }
      }

      // hide scrollbar
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .briefcase-action-button-container {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      bottom: 00px;
      width: 100%;

      button {
        width: 40%;
      }
    }
  }

  .portfolio-notepad-container {
    position: fixed;
    right: 0;
    width: 400px;
    height: 100%;
    background-color: rgb(68, 68, 68);
    transition: all 1s;
    transform: translateX(calc(100% + 28px));
    z-index: 99;

    &.active {
      transform: translateX(0);
    }

    &.hidden {
      transform: translateX(calc(100% + 28px));
    }

    // contains md close icon
    .notepad-close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      z-index: 2;
    }

    .textarea-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: calc(100% - 160px);
      margin-top: 90px;

      h1 {
        width: 90%;
        background-color: white;
        color: black;
        margin: 10px 0 0 0;
      }
      textarea {
        width: 90%;
        height: 100%;
        resize: none;
        padding: 0;
      }
    }

    .notepad-button-container {
      display: flex;
      justify-content: space-evenly;
      position: absolute;
      bottom: 0px;
      width: 100%;
      button {
        width: 40%;
      }
    }
  }

  .portfolio-scriptnote-container {
    position: fixed;
    right: 0%;
    width: 350px;
    height: 700px;
    top: calc(50% - 350px);
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 3px 12px 2px #3b3b3b;
    transition: all 0.5s;
    transform: translateX(calc(100% + 28px));
    z-index: 5;

    @media (max-width: 1367px) {
      height: 550px;
      top: calc(50% - 300px);
      width: 250px;
    }

    &.active {
      transform: translateX(-2%);
    }

    &.hidden {
      transform: translateX(calc(100% + 28px));
    }

    // contains md close icon
    .scriptnote-close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 2;

      .scriptnote-close-button {
        height: 70px !important;
        width: 70px !important;
        margin: 10px 10px 0px !important;

        @media (max-width: 1367px) {
          height: 60px !important;
          width: 60px !important;
          margin: 8px 8px 0px !important;
        }
      }
    }

    .scriptnote-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: black;
      width: 100%;
      height: calc(100% - 110px);
      margin-top: 60px;
    }

    // content of script note
    .text-container {
      position: relative;
      width: 90%;
      height: 100%;
      margin-top: 10px;
      background-color: white;
      list-style: inside !important;
      overflow: auto;

      h1 {
        margin: 5px;
        // font-family: 'Newsreader', serif;
        font-size: 28px;
        font-weight: 600;
      }

      ul{
        list-style: inside !important;
      }

      p {
        margin: 6px;
      }

    }
  }

  // menu custom icons
  .menu-icon-custom {
    position: relative;

    .notification {
      position: absolute;
      top: 0%;
      right: -20%;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transition: all 0.15s ease;
      box-shadow: 1px 1px 2px 1px #3b3b3b;
      cursor: pointer;

      @media (max-width: 1367px) {
        height: 25px;
        width: 25px;
        right: -25%;
      }
  
      p {
          text-align: center;
          margin-block-start: 0px;
          margin-block-end: 0px;
          font-size: 0.9em;
          font-weight: 600;

          @media (max-width: 1367px) {
            font-size: 0.7em !important;
          }
      }
    }

    img {
      grid-row: span 2;
      width: 70px;
      height: 70px;
      padding: 10px;
      cursor: pointer;
      &:hover {
        // NOTE filter doesn't work to make ligter color for pure black
        filter: brightness(calc(100% + #{$Brightness}));
      }
    }
  }

  // md icons
  .menu-icon-md {
    grid-row: span 2;
    width: 70px;
    height: 70px;
    padding: 10px;
    color: black; //default color if accent_color doesn't exist
    cursor: pointer;
    &:hover {
      // NOTE filter doesn't work to make ligter color for pure black
      filter: brightness(calc(100% + #{$Brightness}));
    }
  }

  // modal
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 6;

    .modal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 600px;
      min-height: 300px;
      padding: 25px;
      position: relative;
      background-color: white;
      border-radius: 5px;

      .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-close-button {
          height: 70px !important;
          width: 70px !important;
          margin: 10px 10px 0px !important;
  
          @media (max-width: 1367px) {
            height: 60px !important;
            width: 60px !important;
            margin: 8px 8px 0px !important;
          }
        }

        .menu-icon-md {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .screen-saver-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 10;

    .screen-saver-asset {
      width: 100%;
      &.active {
        display: block;
      }
      &.hide {
        display: none;
      }

      .screensaver-single-image {
        width: 100%;
        height: auto;
      }
    }
  }

  .send-email-popup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;

    .send-email-popup-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 400px;
      min-height: 200px;
      padding: calc(25px * 1.43333);
      text-align: center;
      background-color: white;

      .row-button-container {
        margin-top: calc(5% * 1.4333);

        .button {
          cursor: pointer;
          height: 43px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 3%;
          margin-right: 3%;
          border-radius: 8px;
          cursor: pointer;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-weight: 600;
            font-size: 20px;
          }
        }
      }

      .send-email-popup-text {
        margin-top: 5px;
        color: black;
        white-space: pre-wrap;
        display: flex;
        position: relative;

        .email-notification {
          position: absolute;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          height: 20px;
          width: 150px;
          left: calc(50% - 75px);
          top: calc(50% - 11px);
          box-shadow: 1px 1px 3px 0px #808080;

          p {
            margin-block-start: 0px;
            margin-block-end: 0px;
            font-weight: 600;
            font-size: 12px;
            font-style: italic;
          }
        }
      }

      .send-email-popup-close {
        margin-top: auto;
      }
    }
  }

  button {
    margin-top: 8px;

    &:hover {
      filter: brightness(calc(100% + #{$Brightness})); // ligten color on hover
    }
    &:active {
      filter: brightness(calc(100% + #{$Brightness})); // ligten color on active
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  //--------------------------
  // animations
  //--------------------------
}
.Show-Today-text {
  color: blue;
  text-align: end;
  cursor: pointer;

  :hover {
    text-decoration: 'underline';
  }
}
// customer form global style
.customer-info-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .add-cc-email-text {
    color: blue;
    cursor: pointer;

    :hover {
      text-decoration: 'underline';
    }

    @media (max-width: 450px) and (orientation: portrait) {
      display: none;
    }
  }

  .cc-email {
    display: flex;
    flex-direction: column;
    max-height: 128px;
    overflow-y: auto;

    @media (max-width: 450px) and (orientation: portrait) {
      max-height: 86px;
      display: none;
    }
  }

  .customer-form-name {
    margin-bottom: 20px;
  }

  .customer-form-error {
    color: red;
    margin-bottom: 20px;
  }

  input {
    margin-bottom: 10px;
  }
  div{
    p{
      font-size: inherit;
    }
  }
  p {
    align-self: flex-end;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  hr {
    color: grey;
    width: 100%;
  }
}

/* Safari 10.1+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    // Safari does not work well with display: none
    // Override .hidden for safari since safari does not like display: none
    .hidden {
      height: 0px;
      visibility: hidden;
      width: 0;
    }
  }
}
// @media (max-width: 450px) and (orientation: portrait) {
// *::-webkit-media-controls-start-playback-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// } 
div.ql-editor{
  -webkit-user-select: text !important;
user-select: text !important;
-webkit-user-modify:read-write !important;
 p {
  -webkit-user-select: text !important;
  user-select: text !important;
  -webkit-user-modify:read-write !important;
 }
 >*{
  -webkit-user-select: text !important;
  user-select: text !important;
  -webkit-user-modify:read-write !important;
 }
}